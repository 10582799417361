import styled from 'styled-components';
import { ValidatorForm } from 'react-material-ui-form-validator';

const ContentArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: rgb(243, 242, 241);
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .MuiButtonBase-root {
    background-color: #abab25;
  }

  button {
    white-space: nowrap;

    &:hover {
      color: white;
      a {
        color: white;
      }
      background-color: #415463;
    }
  }
`;

const PaperlessContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  @media screen and (min-width: 960px) {
    
  }
`;

const Container = styled(ValidatorForm)`
  width: 100%;
  height: auto;
  display: flex;
  padding: 50px 50px 13px 50px;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 960px) {
    display: none;
  }
  div.MuiTextField-root {
    width: 25%;
  }

  fieldset {
    background-color: inherit;
  }

  span.MuiButtonBase-root {
    background-color: inherit;
  }

  span.MuiFormControlLabel-label {
    font-size: 12px;
  }
`;

const PaperlessContent = styled.div`
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  width: 100%;
  height: 100%;
  ${Container} {
    display: flex;
  }

  @media screen and (min-width: 960px) {
    
  }

  .fa-check-circle {
    color: green;
    margin-right: 5px;
  }

  .fa-times-circle {
    color: red;
  }
`;

const PrivacyLink = styled.span`
  text-decoration: underline;
`;

export {
    ContentArea,
    PaperlessContainer,
    Container,
    PaperlessContent,
    PrivacyLink
}