import React, { useEffect } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import AuthService from '../../lib/AuthService';
import { connect } from 'react-redux';

const PrivateRoute = ({
  component: Component,
  history,
  clearData,
  componentProps,
  ...rest
}) => {
  const auth = new AuthService();
  useEffect(() => {
    const expireID = setInterval(() => {
      if (auth.isTokenExpired(auth.getToken())) {
        alert('Your session has expired, please log back in to continue.');
        auth.logout();
        clearData();
        history.push('/');
      }
    }, 1000);
    return () => {
      clearInterval(expireID);
    };
  }, [history, clearData, auth]);
  if (auth.isLoggedIn()) {
    return (
      <Route
        {...rest}
        render={props => {
          return <Component {...props} {...componentProps} />;
        }}
      />
    );
  } else if (auth.getToken() && auth.isTokenExpired(auth.getToken())) {
    alert('Your session has expired, please log back in to continue.');
    return <Redirect to="/" />;
  } else {
    return <Redirect to="/" />;
  }
};
const PrivateRouteWithRouter = withRouter(PrivateRoute);
// export { PrivateRouteWithRouter as PrivateRoute };

const mapDispatchToProps = dispatch => ({
  clearData: () => dispatch({ type: 'CLEAR_DATA' })
});

export default connect(
  null,
  mapDispatchToProps
)(PrivateRouteWithRouter);
