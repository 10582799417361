import AuthService from '../../lib/AuthService';
import React, { useEffect } from 'react';
import { API_BASE } from '../../utilities';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  LoadingDiv,
  LoginPanel,
  CertContainer,
  CertGroup,
  PoweredBy,
  GottliebGroup,
  GottliebLogo
} from './InquiryStyles';

import Axios from 'axios';
import { Link } from '@material-ui/core';
const bg = require('../../assets/img/background.jpeg');

const Inquiry = ({ match, ...props }) => {
  const { guid } = match.params;
  useEffect(() => {
    async function startInquiry() {
      try {
        const { data } = await Axios({
          method: 'get',
          url: `${API_BASE}/DoctorBill/Authenticate/${guid}`,
          headers: {
            Pragma: 'no-cache'
          }
        });
        // console.log(data);
        const { id, birthDateFormatted, empID, inquiryCommandID } = data.value;
        // console.log(id, birthDateFormatted, inquiryCommandID);
        const DOBRegex = /(\d+)\/(\d+)\/(\d+)/;
        const [fullDate, month, day, year] = birthDateFormatted.match(DOBRegex);
        // console.log(id, birthDateFormatted);
        console.log(fullDate);

        const { data: dataUser, headers } = await Axios({
          method: 'get',
          url: `${API_BASE}/DoctorBill/Authenticate/${id}/${month}-${day}-${year}/${empID}/${inquiryCommandID}`,
          headers: {
            Pragma: 'no-cache'
          }
        });
        // console.log(dataUser);
        props.setUser(dataUser);
        const auth = new AuthService();
        auth.setToken(headers.user_token);
        props.history.push('/Account');
      } catch (err) {
        console.log(err);
        props.history.push('/');
      }
    }
    startInquiry();
  }, [guid, props]);
  return (
    <LoadingDiv src={bg}>
      <LoginPanel>
        <img
          src={require('../../assets/img/physician-bill-pay.png')}
          style={{ width: '300px', height: '50px' }}
          alt="Physician Bill Pay"
        />

        <h1>Loading...</h1>
        <h2>One Moment Please</h2>
        <CertContainer>
          <CertGroup>
            <img
              id="trustwaveSealImage"
              src="https://sealserver.trustwave.com/seal_image.php?customerId=w6oj3BufDtNVfsRHKIMpiViTHPZVM8&amp;size=105x54&amp;style=invert"
              alt="This site is protected by Trustwave's Trusted Commerce program"
              border="0"
              style={{ cursor: 'pointer', width: '60px', height: '40px' }}
              onClick={() => {
                window.open(
                  'https://sealserver.trustwave.com/cert.php?customerId=w6oj3BufDtNVfsRHKIMpiViTHPZVM8&amp;size=105x54&amp;style=invert',
                  'c_TW',
                  'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'
                );
                return false;
              }}
              onContextMenu={() => {
                alert(
                  'Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'
                );
                return false;
              }}
              title="This site is protected by Trustwave's Trusted Commerce program"
            />
          </CertGroup>
          <GottliebGroup>
            <PoweredBy>Powered By</PoweredBy>
            <Link href='https://ventrahealth.com/' target='__blank' referrerPolicy='noopener noreferrer'>
              <GottliebLogo
                src={require('../../assets/img/Ventra-logo.png')}
                alt=""
                />
              </Link>
          </GottliebGroup>
        </CertContainer>
      </LoginPanel>
    </LoadingDiv>
  );
};

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch({ type: 'SET_USER', user })
});
// export default App;

const InquiryWithRouter = withRouter(Inquiry);
export default connect(
  null,
  mapDispatchToProps
)(InquiryWithRouter);
