import React from 'react';
import { Button } from '@material-ui/core';

export const PayButton = ({ children, ...props }) => {
  return (
    <Button variant="contained" color="primary" style={{
      marginTop: '2px'
    }} {...props}>
      {children}
    </Button>
  );
};
