import React, { useEffect, useState } from 'react';
import SideNav from '../../SideNav/SideNav';
import Header from '../../Header/Header';
import PBPTable from 'components/common/PBPTable/PBPTable';
import { MoneyCell, useLanguage } from '../../common';
import { connect } from 'react-redux';
import {
  Button,
  SwipeableDrawer,
  Typography as Label,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from '@material-ui/core';
import {
  setTabTitle,
  formatMoney,
  isNonProductionEnv
} from '../../../utilities';
import jwtDecode from 'jwt-decode';

import {
  AccountDetailsContent,
  Row,
  ContentArea,
  AccountDetailContainer,
  UserInformationRows
  // MobileContainer,
  // DataCard,
  // CardListContainer,
  // HistoryContent,
  // DTContainer
} from './AccountStyles';
import { PatientDetailsCard } from './PatientDetailsCard/PatientDetailsCard';
import { InsuranceCard } from './InsuranceCard/InsuranceCard';
import { FilterInvoicesSelectMenu } from './FilterInvoicesSelectMenu/FilterInvoicesSelectMenu';
import { AccountCard } from './AccountCard/AccountCard';
import { withRouter } from 'react-router-dom';

import styled from 'styled-components';
import { Formik, Form } from 'formik';
import Auth from 'lib/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { theme } from 'lib/theme';
const UserInformationWrapper = styled.div`
  display: flex;
  height: 58px;
  max-height: 58px;
  padding: 28px 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${theme.palette.divider};
  .MuiButtonBase-root {
    background-color: #abab25;
  }
  a {
    text-decoration: none;
  }
  p {
    font-weight: 500;
  }
  button {
    margin-left: 10px;
    white-space: nowrap;
    color: black;
  }
  @media screen and (min-width: 890px) {
    padding: 10px 15px;
    button {
      white-space: nowrap;
      color: black;
      &:hover {
        color: white;
        background-color: #415463;
      }
    }
  }
`;

const DesktopWrapper = styled.div`
  display: none;
  @media screen and (min-width: 901px) {
    display: flex;
  }
`;
const MobileExpansionPanel = styled(ExpansionPanel)`
  position: sticky;
  top: 0;
  z-index: 10;
  @media screen and (min-width: 901px) {
    display: none;
  }
`;
const MobileFooter = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.4em;
  background: #363636;
  z-index: 100;
  @media screen and (min-width: 901px) {
    display: none;
  }
`;
const TableFooterRow = styled.tr`
  & td {
    border-top: 1px solid black;
  }
`;
const Account = ({ User, globalDispatch, PayInvoices, history, ...props }) => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isInsuranceDrawerOpen, setInsuranceDrawerOpen] = useState(false);
  const [primaryInsuranceData, setPrimaryInsuranceData] = useState({});
  const [secondaryInsuranceData, setSecondaryInsuranceData] = useState({});
  const [insuranceInvoiceID, setInsuranceInvoiceID] = useState('');
  const [divisions, setDivisions] = useState([]);
  const [filterInvoiceBy, setFilterInvoiceBy] = useState('SELF PAY');
  const [isInvoiceFilterShown, setIsInvoiceFilterShown] = useState(true);
  const auth = new Auth();
  const decodedJWT = jwtDecode(auth.getToken());
  const { getLanguageText, language } = useLanguage();
  useEffect(() => {
    setTabTitle('Account Details');
  }, []);

  useEffect(() => {
    if (User && User.divisions) {
      //Filter out invoices that are not outstanding in divisions
      if (filterInvoiceBy === 'SELF PAY') {
        const filteredDivisions = User.divisions
          .sort((divisionA, divisionB) => {
            const clientA = divisionA.client.toUpperCase(); // ignore upper and lowercase
            const clientB = divisionB.client.toUpperCase();
            if (clientA < clientB) {
              return -1;
            }
            if (clientA > clientB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
          .map(division => {
            return {
              ...division,
              //Ensuring that the original invoices object in User is not modified
              invoices: [
                ...division.invoices.filter(invoice => {
                  return (
                    (invoice.isSelfPay === true &&
                      invoice.balanceOwedTotal > 0) ||
                    invoice.responsibleParty === 'Payment Pending'
                  );
                })
              ]
            };
          })
          .filter(division => {
            return division.invoices.length > 0 || division.isExpired;
          });
        if (filteredDivisions.length > 0) {
          setDivisions(filteredDivisions);
        } else {
          setFilterInvoiceBy('all');
          setIsInvoiceFilterShown(false);
        }
      }
      //Display all invoices in each division
      else if (filterInvoiceBy === 'all') {
        setDivisions(User.divisions);
      }
    }
  }, [filterInvoiceBy, User]);

  return (
    <ContentArea>
      <Header
        toggleMobileNav={() => setShowMobileNav(prevState => !prevState)}
      />
      <AccountDetailContainer>
        <SideNav showMobileNav={showMobileNav} />
        <AccountDetailsContent showMobileNav={showMobileNav}>
          <UserInformationWrapper>
            <Label id="user-account-info">
              {getLanguageText('Account Number')}:{' '}
              {
                <span
                  style={
                    isNonProductionEnv() ? { color: 'red' } : { color: 'black' }
                  }
                  id="user-display-account-number"
                >{` ${User ? User.displayAccountNumber : null}`}</span>
              }
            </Label>
          </UserInformationWrapper>
          <UserInformationRows>
            {isInsuranceDrawerOpen && (
              <SwipeableDrawer
                id="insurance-drawer"
                open={isInsuranceDrawerOpen}
                anchor="right"
                onClose={() => {
                  setInsuranceDrawerOpen(false);
                }}
                onOpen={() => {
                  setInsuranceDrawerOpen(true);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <span
                    style={{
                      fontSize: '20px',
                      background: theme.palette.primary.dark,
                      color: 'white',
                      fontWeight: 700,
                      padding: '12px 24px 12px 24px'
                    }}
                  >
                    {getLanguageText(`Invoice`)}
                    <span id="insurance-invoice-id">
                      {' '}
                      #{insuranceInvoiceID}
                    </span>
                  </span>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      marginLeft: 'auto',
                      marginRight: '10px',
                      alignSelf: 'center'
                    }}
                    onClick={() => {
                      setInsuranceDrawerOpen(false);
                    }}
                  >
                    <span style={{ marginRight: '6px' }}>X</span>
                    {`${getLanguageText('Close')}`}
                  </Button>
                </div>
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    padding: '20px',
                    paddingTop: '44px'
                  }}
                >
                  <InsuranceCard
                    legend="Primary Insurance"
                    ordinalNumberID={'primary'}
                    data={primaryInsuranceData ? primaryInsuranceData : {}}
                    payloadName={'primaryInsurance'}
                    reqAction={4}
                    User={User}
                  />
                  <InsuranceCard
                    legend="Secondary Insurance"
                    ordinalNumberID={'secondary'}
                    data={secondaryInsuranceData ? secondaryInsuranceData : {}}
                    payloadName={'secondaryInsurance'}
                    reqAction={5}
                    User={User}
                  />
                </Row>
              </SwipeableDrawer>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {
                <PatientDetailsCard
                  User={User}
                  style={{
                    margin: '32px 0 32px 0',
                    width: '100%'
                  }}
                />
              }
              <AccountCard style={{ marginBottom: '60px' }}>
                <h2 style={{ fontSize: '20px' }}>
                  {getLanguageText('Invoices')}
                </h2>
                <div style={{ display: 'flex', marginBottom: '12px' }}>
                  {isInvoiceFilterShown && (
                    <FilterInvoicesSelectMenu
                      onChange={e => {
                        setFilterInvoiceBy(e.target.value);
                      }}
                      value={filterInvoiceBy}
                      style={{ padding: 4 }}
                    />
                  )}
                  <p
                    style={{
                      fontSize: '16px',
                      margin: isInvoiceFilterShown ? '0 0 0 24px' : '0 0 0 0',
                      alignSelf: 'flex-end'
                    }}
                  >
                    {language === 'en'
                      ? 'Please enter the amount you would like to pay in the Pay Amount column, then click the Pay button to continue.'
                      : 'Ingrese el monto que desea pagar en la columna Monto de pago, luego haga clic en el botón Pagar para continuar.'}
                  </p>
                </div>
                {React.useMemo(() => {
                  return divisions.length > 0 ? (
                    divisions.map(division => {
                      if (division.isExpired) {
                        return (
                          <fieldset style={{padding: '10px 8px'}}>
                            <legend style={{textTransform: 'uppercase', fontSize: '16px', fontWeight: '700'}}>{division.client}</legend>
                            <p style={{fontSize: '.875rem'}}>{division?.expirationMessage ?? DEFAULT_EXP_MESSAGE}</p>
                          </fieldset>
                        );
                      }

                      if (division.invoices.length === 0 && !division.isExpired) {
                        return null;
                      }

                      return (
                        <Formik
                          enableReinitialize={true}
                          onSubmit={e => {
                            e.preventDefault();
                          }}
                          render={() => {
                            return (
                              <Form id="invoices-widget-form">
                                <div
                                  key={division.clientID}
                                  style={{
                                    width: '100%',
                                    margin: '36px 0 24px 0'
                                  }}
                                >
                                  <PBPTable
                                    style={{
                                      marginTop: '8px'
                                    }}
                                    mobileProps={{
                                      style: {
                                        marginTop: '8px'
                                      }
                                    }}
                                    setInsuranceDrawerOpen={
                                      setInsuranceDrawerOpen
                                    }
                                    setPrimaryInsuranceData={
                                      setPrimaryInsuranceData
                                    }
                                    setSecondaryInsuranceData={
                                      setSecondaryInsuranceData
                                    }
                                    setInsuranceInvoiceID={
                                      setInsuranceInvoiceID
                                    }
                                    clientID={division.clientID}
                                    decodedJWT={decodedJWT}
                                    history={history}
                                    mobileViewMaxWidth={900}
                                    mobileRender={MobileTable => {
                                      return (
                                        <MobileExpansionPanel
                                          id={`${division.client}-accordion`}
                                        >
                                          <ExpansionPanelSummary
                                            expandIcon={
                                              <FontAwesomeIcon icon="chevron-down" />
                                            }
                                            style={{
                                              position: 'sticky',
                                              top: 0,
                                              zIndex: 1000
                                            }}
                                          >
                                            <div>
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontWeight: 700
                                                }}
                                              >
                                                {division.client}
                                              </p>
                                              <p
                                                style={{
                                                  margin: 0
                                                }}
                                              >
                                                {getLanguageText(
                                                  'Total Balance'
                                                )}{' '}
                                                - $
                                                {formatMoney(
                                                  filterInvoiceBy === 'all'
                                                    ? division.invoiceBalance
                                                    : division.patientSelfPayBalance
                                                )}
                                              </p>
                                            </div>
                                          </ExpansionPanelSummary>
                                          <ExpansionPanelDetails
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              alignItems: 'center'
                                            }}
                                          >
                                            {React.cloneElement(
                                              MobileTable,
                                              {}
                                            )}
                                          </ExpansionPanelDetails>
                                        </MobileExpansionPanel>
                                      );
                                    }}
                                    tableRender={Table => {
                                      return (
                                        <DesktopWrapper
                                          style={{
                                            margin: '6px 0 6px 0'
                                          }}
                                        >
                                          <AccountCard legend={division.client}>
                                            {React.cloneElement(Table, {
                                              style: {
                                                margin: '24px 0 24px 0'
                                              }
                                            })}
                                          </AccountCard>
                                        </DesktopWrapper>
                                      );
                                    }}
                                    emptyDataMessage={getLanguageText(
                                      'No Invoices'
                                    )}
                                    data={division.invoices}
                                  >
                                    {division.invoiceBalance !== null && (
                                      <TableFooterRow
                                        style={{
                                          background: '#E2E2E2'
                                        }}
                                      >
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />

                                        <td
                                          style={{
                                            textAlign: 'right',
                                            fontWeight: 700,
                                            alignItem: 'center',
                                            background: 'grey',
                                            color: 'white'
                                          }}
                                        >
                                          Total:
                                          <MoneyCell id="division-invoice-balance">
                                            {filterInvoiceBy === 'all'
                                              ? division.invoiceBalance
                                              : division.patientSelfPayBalance}
                                          </MoneyCell>
                                        </td>
                                        <td />
                                      </TableFooterRow>
                                    )}
                                  </PBPTable>
                                </div>
                              </Form>
                            );
                          }}
                        />
                      );
                    })
                  ) : (
                    <h2>{getLanguageText('No Invoices to Display')}</h2>
                  );
                }, [
                  divisions,
                  getLanguageText,
                  decodedJWT,
                  filterInvoiceBy,
                  history
                ])}
              </AccountCard>
            </div>
          </UserInformationRows>
        </AccountDetailsContent>
      </AccountDetailContainer>
    </ContentArea>
  );
};

const mapStateToProps = state => {
  return {
    User: state.User,
    PayInvoices: state.PayInvoices
  };
};
const mapDispatchToProps = dispatch => {
  return {
    globalDispatch: dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Account));

const DEFAULT_EXP_MESSAGE =
  'We are no longer the billing company for this client, please contact the physicianâ€™s office directly.';
