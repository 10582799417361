import React from 'react';
import { FormHelperText, Select, MenuItem } from '@material-ui/core';
import { useLanguage } from '..';
export const FormSelect = ({ field, form, data, label, style, ...props }) => {
  const { getLanguageText } = useLanguage();

  return (
    <div style={style}>
      <h4>{label}</h4>
      <Select
        {...field}
        error={Boolean(form.touched[field.name] && form.errors[field.name])}
        {...props}
      >
        {data.map(item => {
          return (
            <MenuItem key={item.code} id={item.code} value={item.code}>
              {getLanguageText(item.name)}
            </MenuItem>
          );
        })}
      </Select>
      {Boolean(form.touched[field.name] && form.errors[field.name]) && (
        <FormHelperText
          error={Boolean(form.touched[field.name] && form.errors[field.name])}
        >
          {form.touched[field.name] && form.errors[field.name]}
        </FormHelperText>
      )}
    </div>
  );
};
