import { useState, useEffect, useCallback } from 'react';
import { es } from './es';
import createPersistedState from 'use-persisted-state';

const useLanguagePreference = createPersistedState('language-preference');

export const useLanguage = () => {
  const [languagePreference] = useLanguagePreference('en');
  const [language, setLanguage] = useState(languagePreference);

  const getLanguageText = useCallback(
    text => {
      if (language === 'en') {
        return text;
      } else if (language === 'es') {
        return es[text] !== undefined ? es[text] : text;
      }
    },
    [language]
  );

  useEffect(() => {
    setLanguage(languagePreference);
  }, [languagePreference]);
  return { getLanguageText, language };
};
