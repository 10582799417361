import React from 'react';
import { Typography as Label } from '@material-ui/core';

export const CardLabel = ({ labelID, labelSubject, children, ...props }) => {
  return (
    <div {...props}>
      <h4>{labelSubject}</h4>
      <Label id={labelID}>{children}</Label>
    </div>
  );
};
