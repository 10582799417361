import axios from 'axios';
import React from 'react';
import Moment from 'moment';
import Calendar from 'rc-calendar';
import { Component } from 'react';
import { Header, SideNav } from '../..';
import { connect } from 'react-redux';
import { extendMoment } from 'moment-range';

import { API_BASE, formatMoney, setTabTitle } from '../../../utilities';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  Typography as Label,
  Button,
  CircularProgress
} from '@material-ui/core';
import { UserInformation, LanguageTranslator } from '../../common';
import AuthService from '../../../lib/AuthService';
import {
  ContentArea,
  HistoryContainer,
  DesktopContainer,
  MobileContainer,
  HistoryContent,
  FilterContainer,
  DataCard,
  CardListContainer,
  OpacityBlock,
  CalendarContainer,
  CalendarInputContainer,
  CalenderInputMobileContainer
} from './HistoryStyles';
import uuidv4 from 'uuid/v4';
const Axios = axios.create({
  timeout: 30000,
  baseURL: API_BASE
});

const moment = extendMoment(Moment);

const CalendarInput = ({
  label,
  onClick,
  onSelect,
  value,
  show,
  calendarInputContainerProps
}) => {
  return (
    <CalendarContainer>
      <Label className="label">{label}</Label>
      <CalendarInputContainer
        onClick={onClick}
        {...calendarInputContainerProps}
      >
        <Label>{moment(value).format('MM/DD/YYYY')}</Label>
        <Icon icon={faCalendar} size="2x" />
      </CalendarInputContainer>
      {show ? (
        <Calendar
          onSelect={onSelect}
          format={'YYYY-MM-DD'}
          selectedValue={value}
          showDateInput={true}
        />
      ) : null}
    </CalendarContainer>
  );
};

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSorting: false,
      isLoading: false,
      historyInfo: [],
      lastSortField: '',
      showMobileNav: false,
      toFilterDate: moment(),
      displaytoFilterDate: false,
      displayfromFilterDate: false,
      fromFilterDate: moment().add(-1, 'years')
    };
    this.loadState = this.loadState.bind(this);
  }

  filterDataByDate = () => {
    // debugger;
    const { AccountHistory } = this.props;
    const { toFilterDate, fromFilterDate } = this.state;
    const range = moment.range(fromFilterDate, toFilterDate);
    this.setState({
      historyInfo: AccountHistory.filter(h => range.contains(moment(h.hisDate)))
    });
  };

  resetFilters = () => {
    this.setState(
      {
        displayfromFilterDate: false,
        displaytoFilterDate: false,
        fromFilterDate: moment().add(-1, 'years'),
        toFilterDate: moment(),
        historyInfo: this.props.AccountHistory
      },
      () => {
        this.filterDataByDate();
      }
    );
  };

  onSelect = (field, date) => {
    const display = this.state[`display${field}`];
    this.setState(
      { [field]: moment(date), [`display${field}`]: !display },
      () => {
        this.filterDataByDate();
      }
    );
  };

  toggleMobileNav = () => {
    this.setState({ showMobileNav: !this.state.showMobileNav });
  };

  sortOn(field) {
    const { lastSortField } = this.state;
    const [...history] = this.state.historyInfo;

    if (field === lastSortField) {
      history.reverse();
    } else {
      history.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        }

        if (a[field] > b[field]) {
          return 1;
        }

        return 0;
      });
    }

    this.setState({ lastSortField: field, historyInfo: history });
  }
  loadState(isLoading) {
    this.setState({
      isLoading
    });
  }
  componentDidUpdate() {
    // debugger;
    const { AccountHistory } = this.props;
    const { historyInfo, isSorting } = this.state;

    if (
      historyInfo.length === 0 &&
      historyInfo.length !== AccountHistory.length &&
      !isSorting
    ) {
      this.setState(
        { historyInfo: this.props.AccountHistory, isSorting: true },
        () => {
          this.filterDataByDate();
        }
      );
    }
  }

  componentDidMount() {
    setTabTitle('Account History');
    const auth = new AuthService();
    const user_token = auth.getToken();
    if (user_token) {
      const decodedJWT = auth.getProfile();
      const { ID } = decodedJWT;
      this.loadState(true);
      Axios.get(`/DoctorBill/AccountHistoryByID/${ID}`, {
        headers: {
          user_token,
          Pragma: 'no-cache'
        }
      })
        .then(response => {
          const { data, status } = response;
          // console.log(data);
          if (status === 200) {
            const uuids = {};

            const generateUUID = () => {
              let uuid = uuidv4();
              if (uuids[uuid] === undefined) {
                uuids[uuid] = true;
                return uuid;
              } else {
                return generateUUID();
              }
            };

            this.props.setAccountHistory(
              data.map(historyObj => {
                return {
                  ...historyObj,
                  id: generateUUID()
                };
              })
            );
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadState(false);
          this.resetFilters();
        });
    }
  }

  render() {
    const { User } = this.props;
    const {
      showMobileNav,
      historyInfo,
      fromFilterDate,
      toFilterDate,
      displayfromFilterDate,
      displaytoFilterDate,
      isLoading
    } = this.state;
    // console.log(historyInfo);
    return (
      <LanguageTranslator>
        {({ getLanguageText }) => (
          <ContentArea>
            <Header toggleMobileNav={this.toggleMobileNav} />
            <HistoryContainer>
              <SideNav showMobileNav={showMobileNav} />
              <HistoryContent showMobileNav={showMobileNav}>
                <UserInformation User={User} />
                <DesktopContainer>
                  {isLoading ? (
                    <OpacityBlock>
                      <Label id="start-loading-acct-history">
                        {getLanguageText('Please wait while we load data...')}
                      </Label>
                      <CircularProgress />
                    </OpacityBlock>
                  ) : (
                    <>
                      <h3 className="block">
                        {getLanguageText('Filter History by date')}
                      </h3>
                      <FilterContainer>
                        <CalendarInput
                          label={getLanguageText('From Date')}
                          value={fromFilterDate}
                          show={displayfromFilterDate}
                          onSelect={date =>
                            this.onSelect('fromFilterDate', date)
                          }
                          onClick={() =>
                            this.setState({
                              displayfromFilterDate: !displayfromFilterDate
                            })
                          }
                          calendarInputContainerProps={{
                            id: 'history-from-date-calendar'
                          }}
                        />

                        <CalendarInput
                          label={getLanguageText('To Date')}
                          value={toFilterDate}
                          show={displaytoFilterDate}
                          onSelect={date => this.onSelect('toFilterDate', date)}
                          onClick={() =>
                            this.setState({
                              displaytoFilterDate: !displaytoFilterDate
                            })
                          }
                          calendarInputContainerProps={{
                            id: 'history-to-date-calendar'
                          }}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          id="history-btn-clear-desktop"
                          onClick={this.resetFilters}
                        >
                          {getLanguageText('Reset')}
                        </Button>
                      </FilterContainer>
                      <table cellSpacing="0" cellPadding="5">
                        <thead>
                          <tr>
                            <th
                              onClick={() => this.sortOn('hisDate')}
                              width="200"
                            >
                              {getLanguageText('Date of Transaction')}
                            </th>
                            <th
                              onClick={() => this.sortOn('hisAmt')}
                              width="100"
                            >
                              {getLanguageText('Amount')}
                            </th>
                            <th onClick={() => this.sortOn('actDesc')}>
                              {getLanguageText('Description')}
                            </th>
                            <th onClick={() => this.sortOn('iPlan')}>Plan</th>
                          </tr>
                        </thead>

                        <tbody>
                          {historyInfo &&
                            historyInfo.map(hist => (
                              <tr key={hist.id} id={`tr-${hist.hisDate}`}>
                                <td id={hist.formattedDate}>
                                  {hist.formattedDate}
                                </td>
                                <td align="right" id={hist.hisAmt}>
                                  ${formatMoney(hist.hisAmt)}
                                </td>
                                <td>{getLanguageText(hist.actDesc)}</td>
                                <td id={hist.iPlan}>{hist.iPlan}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </DesktopContainer>

                <MobileContainer>
                  {isLoading ? (
                    <OpacityBlock>
                      <Label id="start-loading-acct-history-mobile">
                        {getLanguageText('Please wait while we load data...')}
                      </Label>
                      <CircularProgress />
                    </OpacityBlock>
                  ) : (
                    <>
                      <FilterContainer>
                        <h3 className="">
                          {getLanguageText('Filter history by date')}
                        </h3>
                        <Button
                          variant="contained"
                          color="primary"
                          id="history-btn-clear-desktop"
                          onClick={this.resetFilters}
                        >
                          Reset
                        </Button>
                        <CalenderInputMobileContainer>
                          <CalendarInput
                            label="From Date"
                            value={fromFilterDate}
                            show={displayfromFilterDate}
                            onSelect={date =>
                              this.onSelect('fromFilterDate', date)
                            }
                            onClick={() =>
                              this.setState({
                                displayfromFilterDate: !displayfromFilterDate
                              })
                            }
                            calendarInputProps={{
                              id: 'mobile-history-from-date-calendar'
                            }}
                          />

                          <CalendarInput
                            label="To Date"
                            value={toFilterDate}
                            show={displaytoFilterDate}
                            onSelect={date =>
                              this.onSelect('toFilterDate', date)
                            }
                            onClick={() =>
                              this.setState({
                                displaytoFilterDate: !displaytoFilterDate
                              })
                            }
                            calendarInputProps={{
                              id: 'mobile-history-to-date-calendar'
                            }}
                          />
                        </CalenderInputMobileContainer>
                      </FilterContainer>
                      <CardListContainer>
                        {historyInfo &&
                          historyInfo.map(hist => (
                            <DataCard
                              key={hist.id}
                              id={`data-card-${hist.hisDate}`}
                            >
                              <div>
                                <Label>Plan</Label>
                                <span id={hist.iPlan}>{hist.iPlan}</span>
                              </div>
                              <div>
                                <Label>{getLanguageText('Amount')}</Label>
                                <span id={hist.hisAmt}>${hist.hisAmt}</span>
                              </div>

                              <div>
                                <Label>
                                  {getLanguageText('Date of Transaction')}
                                </Label>
                                <span id={hist.formattedDate}>
                                  {hist.formattedDate}
                                </span>
                              </div>

                              <div>
                                <Label id={hist.actDesc}>
                                  {getLanguageText('Description')}
                                </Label>
                                <span>{hist.actDesc}</span>
                              </div>
                            </DataCard>
                          ))}
                      </CardListContainer>
                    </>
                  )}
                </MobileContainer>
              </HistoryContent>
            </HistoryContainer>
          </ContentArea>
        )}
      </LanguageTranslator>
    );
  }
}

const mapStateToProps = state => ({
  AccountHistory: state.AccountHistory,
  User: state.User
});

const mapDispatchToProps = dispatch => ({
  setAccountHistory: update => dispatch({ type: 'SET_ACCOUNT_HISTORY', update })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History);
