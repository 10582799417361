import { User } from './User';
import { combineReducers } from 'redux';
import { AccountInfo, AccountHistory } from './Account';
import { Transaction } from './Transaction';
import { Language } from './Language';
import { PayInvoices } from './PayInvoices';
export default combineReducers({
  User,
  AccountInfo,
  AccountHistory,
  Transaction,
  Language,
  PayInvoices
});
