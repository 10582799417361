import React from 'react';
import styled from 'styled-components';
const PlaceholderWrapper = styled.tr`
  height: 200px;
`;
const MessageWrappper = styled.td`
  background: lightgray;
  text-align: center;
  font-size: 24px;
`;
export const EmptyTablePlaceholder = ({
  ComponentWhenDataEmpty,
  colSpan,
  ...props
}) => {
  return (
    <PlaceholderWrapper {...props}>
      <MessageWrappper colSpan={colSpan}>
        {React.cloneElement(ComponentWhenDataEmpty, {})}
      </MessageWrappper>
    </PlaceholderWrapper>
  );
};
