import InputMask from 'react-input-mask';
import * as React from 'react';
import { useLanguage } from 'components/common';

/**
 *
 * @param {string} value
 * @param {string} letter
 * @param {number} length
 * @returns string
 */
function replaceMaskWithLetter(value, letter, length) {
  if (!value) value = '';

  value = value.replace(/[^0-9]/g, '');

  return value + letter.repeat(length - value.length);
}

/**
 *
 * @param {string} value
 * @returns string
 */
function fillInMaskWithLetters(value) {
  if (!value) return '';

  const [month, day, year] = value.split('/');

  return [
    replaceMaskWithLetter(month, 'm', 2),
    replaceMaskWithLetter(day, 'd', 2),
    replaceMaskWithLetter(year, 'y', 4)
  ].join('/');
}

/**
 *
 * @param {string} value
 * @returns number
 */
function findFirstPlaceholderIndex(value) {
  const placeholderPositions = [
    value.indexOf('m'),
    value.indexOf('d'),
    value.indexOf('y')
  ].filter(position => position >= 0);

  if (placeholderPositions.length === 0) return null;

  return Math.min(...placeholderPositions);
}

/**
 *
 * @param {MaskedValue} newState
 * @returns MaskedValue
 * @example 
 * type MaskedValue = {
        value: string,
        selection: {
            start: number,
            end: number,
            length: number
        } | null
 }
 */
function beforeMaskedValueChange(newState) {
  const value = fillInMaskWithLetters(newState.value);
  let selection = newState.selection;

  if (selection && selection.length === 1) {
    const index =
      findFirstPlaceholderIndex(value) || Math.max(0, value.length - 1);

    selection = {
      start: index,
      end: index,
      length: 1
    };
  }

  return { value, selection };
}

const placeholder = 'mm/dd/yyyy';

const formatChars = {
  n: '[0-1]',
  m: '[0-9]',
  e: '[0-3]',
  d: '[0-9]',
  z: '[1-2]',
  y: '[0-9]'
};

export default function MyComponent(props) {
  const { getLanguageText } = useLanguage();

  return (
    <InputMask
      {...props}
      alwaysShowMask={false}
      beforeMaskedValueChange={beforeMaskedValueChange}
      formatChars={formatChars}
      mask="nm/ed/zyyy"
      maskChar={null}
      value={props.value}
      placeholder={getLanguageText(placeholder)}
    />
  );
}
