import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill';

import 'rc-calendar/assets/index.css';

import App from './App';
import React from 'react';
import store from './store';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { theme } from './lib/theme.js';
import {
  faArrowUp,
  faArrowDown,
  faSave,
  faPencilAlt,
  faTimes,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from '@material-ui/styles';

library.add(
  faArrowUp,
  faArrowDown,
  faSave,
  faPencilAlt,
  faTimes,
  faChevronDown
);

function ToRender() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

ReactDOM.render(<ToRender />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
