import React from 'react';
import { formatMoney } from '../../../utilities';
export const MoneyCell = ({ children, style, id, ...props }) => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'flex-end', ...style }}
      {...props}
    >
      $<span id={id + '-decimal-format'}>{formatMoney(children)}</span>
    </div>
  );
};
