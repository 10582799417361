import styled from 'styled-components';

const ContentArea = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: rgb(243, 242, 241);
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .MuiButtonBase-root {
    background-color: #abab25;
  }

  button {
    white-space: nowrap;

    &:hover {
      a {
        color: #fff;
      }
      background-color: #415463;
    }
  }
`;

const FAQContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  @media screen and (min-width: 960px) {
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 960px;
  padding: 10px 15px;
  div.MuiButtonBase-root {
    background-color: transparent;
  }
`;

const FAQContent = styled.div`
  width: 100%;
  height: 100%;
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  z-index: ${props => (props.showMobileNav === true ? '0' : '0')};

  .fa-check-circle {
    color: green;
    margin-right: 5px;
  }

  .fa-times-circle {
    color: red;
  }

  h2 {
    margin-left: 15px;
  }
`;

export { ContentArea, FAQContainer, DesktopContainer, FAQContent };
