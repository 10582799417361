import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../../../common';

const AccountCardWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  background: rgb(243, 242, 241);
  fieldset {
    height: 100%;
  }
  & h4 {
    margin: 0;
  }
  legend {
    line-height: 0;
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;

export const AccountCard = ({
  children,
  legend = '',
  buttonsProps,
  isEditing,
  showFieldButtons = true,
  ...props
}) => {
  const { getLanguageText } = useLanguage();
  return (
    <AccountCardWrapper {...props} className="account-card">
      <fieldset>
        {legend && (
          <legend style={{ fontSize: '16px', fontWeight: 700 }}>
            {getLanguageText(legend)}
          </legend>
        )}
        <div>{children}</div>
      </fieldset>
    </AccountCardWrapper>
  );
};

const AccountCardRow = styled.div`
  display: flex;
  width: 100%;
  & > *:first-child {
    padding-left: 0;
  }
  & > * {
    width: 100%;
    padding-left: 12px;
  }
  margin-bottom: 24px;
`;
const AccountCardRow2 = styled.div`
  display: flex;
  width: 100%;
  & > *:first-child {
    padding-left: 0;
  }
  & > * {
    width: 100%;
    padding-left: 12px;
  }
  margin-bottom: 24px;
`;
AccountCard.Row2 = ({ children }) => {
  return <AccountCardRow2>{children}</AccountCardRow2>;
};
AccountCard.Row = ({ children }) => {
  return <AccountCardRow>{children}</AccountCardRow>;
};
