import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
export const SubmitButton = ({ loading, children, ...props }) => {
  return (
    <Button type="submit" variant="contained" {...props}>
      {children}
      {loading ? <CircularProgress size={20} /> : null}
    </Button>
  );
};
