import React from 'react';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';
import { IncDialogBody, IncDialogQContainer } from './PayBillStyles';
import Box from '@material-ui/core/Box';

const IncDialog = props => {
  const { User, setShowInquiryDialog, values, setValues } = props;
  function convertRegion(input, to) {
    var states = [
      ['Alabama', 'AL'],
      ['Alaska', 'AK'],
      ['American Samoa', 'AS'],
      ['Arizona', 'AZ'],
      ['Arkansas', 'AR'],
      ['Armed Forces Americas', 'AA'],
      ['Armed Forces Europe', 'AE'],
      ['Armed Forces Pacific', 'AP'],
      ['California', 'CA'],
      ['Colorado', 'CO'],
      ['Connecticut', 'CT'],
      ['Delaware', 'DE'],
      ['District Of Columbia', 'DC'],
      ['Florida', 'FL'],
      ['Georgia', 'GA'],
      ['Guam', 'GU'],
      ['Hawaii', 'HI'],
      ['Idaho', 'ID'],
      ['Illinois', 'IL'],
      ['Indiana', 'IN'],
      ['Iowa', 'IA'],
      ['Kansas', 'KS'],
      ['Kentucky', 'KY'],
      ['Louisiana', 'LA'],
      ['Maine', 'ME'],
      ['Marshall Islands', 'MH'],
      ['Maryland', 'MD'],
      ['Massachusetts', 'MA'],
      ['Michigan', 'MI'],
      ['Minnesota', 'MN'],
      ['Mississippi', 'MS'],
      ['Missouri', 'MO'],
      ['Montana', 'MT'],
      ['Nebraska', 'NE'],
      ['Nevada', 'NV'],
      ['New Hampshire', 'NH'],
      ['New Jersey', 'NJ'],
      ['New Mexico', 'NM'],
      ['New York', 'NY'],
      ['North Carolina', 'NC'],
      ['North Dakota', 'ND'],
      ['Northern Mariana Islands', 'NP'],
      ['Ohio', 'OH'],
      ['Oklahoma', 'OK'],
      ['Oregon', 'OR'],
      ['Pennsylvania', 'PA'],
      ['Puerto Rico', 'PR'],
      ['Rhode Island', 'RI'],
      ['South Carolina', 'SC'],
      ['South Dakota', 'SD'],
      ['Tennessee', 'TN'],
      ['Texas', 'TX'],
      ['US Virgin Islands', 'VI'],
      ['Utah', 'UT'],
      ['Vermont', 'VT'],
      ['Virginia', 'VA'],
      ['Washington', 'WA'],
      ['West Virginia', 'WV'],
      ['Wisconsin', 'WI'],
      ['Wyoming', 'WY']
    ];

    // So happy that Canada and the US have distinct abbreviations
    var provinces = [
      ['Alberta', 'AB'],
      ['British Columbia', 'BC'],
      ['Manitoba', 'MB'],
      ['New Brunswick', 'NB'],
      ['Newfoundland', 'NF'],
      ['Northwest Territory', 'NT'],
      ['Nova Scotia', 'NS'],
      ['Nunavut', 'NU'],
      ['Ontario', 'ON'],
      ['Prince Edward Island', 'PE'],
      ['Quebec', 'QC'],
      ['Saskatchewan', 'SK'],
      ['Yukon', 'YT']
    ];

    var regions = states.concat(provinces);

    var i; // Reusable loop variable
    if (to === 1) {
      input = input.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      for (i = 0; i < regions.length; i++) {
        if (regions[i][0] === input) {
          return regions[i][1];
        }
      }
    } else if (to === 2) {
      input = input.toUpperCase();
      for (i = 0; i < regions.length; i++) {
        if (regions[i][1] === input) {
          return regions[i][0];
        }
      }
    }
  }
  return (
    <IncDialogBody>
      <hr />
      <Box display="flex" justifyContent="space-between">
        <Button
          id="auto-pop-btn-all"
          onClick={() => {
            setShowInquiryDialog(false);
            console.log(User);
            setValues({
              ...values,
              name_on_card: `${User.firstName} ${User.lastName}`,
              cardholder_billing_address_line_1: `${User.address}`,
              city: `${User.city}`,
              zip: `${User.zipCode}`,
              state: `${convertRegion(User.state, 2)}`,
              country: `United States`
            });
          }}
        >
          All
        </Button>
        <IncDialogQContainer>
          <p id="user-all-info">{`${User.firstName} ${User.lastName} : ${User.address} ${User.city} ${User.zipCode} ${User.state} United States`}</p>
        </IncDialogQContainer>
      </Box>
      <hr />
      <Box display="flex" justifyContent="space-between">
        <Button
          id="auto-pop-btn-name"
          onClick={() => {
            setShowInquiryDialog(false);
            setValues({
              ...values,
              name_on_card: `${User.firstName} ${User.lastName}`
            });
          }}
        >
          Just the Patient Name
        </Button>
        <IncDialogQContainer>
          <p id="patient-name-display-box">{`${User.firstName} ${User.lastName}`}</p>
        </IncDialogQContainer>
      </Box>
      <hr />
      <Box display="flex" justifyContent="space-between">
        <Button
          id="auto-pop-btn-address"
          onClick={() => {
            setShowInquiryDialog(false);
            setValues({
              ...values,
              cardholder_billing_address_line_1: `${User.address}`,
              city: `${User.city}`,
              zip: `${User.zipCode}`,
              state: `${convertRegion(User.state, 2)}`,
              country: `United States`
            });
          }}
        >
          Just the Patient Address
        </Button>
        <IncDialogQContainer>
          <p id="user-address-info">{`${User.address} ${User.city} ${User.zipCode} ${User.state} United States`}</p>
        </IncDialogQContainer>
      </Box>
      <hr />
      <Button
        id="auto-pop-btn-none"
        style={{ width: '100%' }}
        onClick={() => setShowInquiryDialog(false)}
      >
        None
      </Button>
    </IncDialogBody>
  );
};

const mapStateToProps = state => ({
  User: state.User
});
const mapDispatchToProps = dispatch => ({
  setTransaction: transaction =>
    dispatch({ type: 'SET_TRANSACTION', transaction })
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncDialog);
