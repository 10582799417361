import toastr from 'toastr';
import React from 'react';
import { useState, useEffect } from 'react';
import { Header, SideNav } from '..';
import { IOSSwitch, UserInformation, useLanguage } from '../common';
import { connect } from 'react-redux';
import { API_BASE, setTabTitle } from '../../utilities';
import { TextValidator } from 'react-material-ui-form-validator';
import {
  Typography as Label,
  Checkbox,
  FormControlLabel,
  makeStyles
} from '@material-ui/core';
import Axios from 'axios';
import { useMaterialSubmitButton } from '../common';
import AuthService from '../../lib/AuthService';
import {
  ContentArea,
  PaperlessContainer,
  Container,
  PaperlessContent,
  PrivacyLink
} from './PaperlessStyles';

const useStyles = makeStyles({
  email: {
    width: '100%',
    minWidth: '300px'
  },
  optInSlider: {
    margin: '24px 0 0 0'
  },
  optInLabel: {
    fontSize: 24
  },
  heading: {
    fontSize: '36px',
    fontWeight: 400
  },
  textVerbiage: {
    fontSize: '12px',
    marginTop: '12px',
    lineHeight: 1.5
  }
});

function Paperless(props) {
  const classes = useStyles();
  const [email, setEmail] = useState(props.User.email || '');
  const [checked, setChecked] = useState(false);
  const [optedIn, setOptedIn] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [submitState, submitActions, SubmitBtn] = useMaterialSubmitButton();
  const { getLanguageText, language } = useLanguage();
  useEffect(() => {
    setTabTitle('Email Reminder');
  }, []);
  useEffect(() => {
    // console.log(props.User.useEmail)
    setOptedIn(props.User.useEmail);
  }, [props.User.useEmail]);

  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  const { User } = props;

  function verifySave() {
    const { id } = User;
    const auth = new AuthService();
    const user_token = auth.getToken();
    if (optedIn && !checked) {
      toastr.error(
        getLanguageText(
          'Please accept that this change may take one billing cycle.'
        )
      );
      return;
    }
    // console.log(id, email, useEmail)
    submitActions.startLoading();
    Axios.post(
      `${API_BASE}/DoctorBill/SetPaperless/`,
      {
        EncounterID: id,
        EmailAddress: email,
        Paperless: optedIn ? 1 : 0
      },
      {
        headers: {
          user_token,
          Pragma: 'no-cache'
        }
      }
    )
      .then(response => {
        if (response.status === 200) {
          const { User } = props;
          props.setUser({ ...User, useEmail: optedIn, email });
          if (optedIn === true) {
            toastr.success(getLanguageText(`Saved successfully.`));
          } else {
            toastr.success(
              getLanguageText(
                'Saved successfully. It may take up to one billing cycle for changes to be reflected.'
              )
            );
          }
        }
      })
      .catch(error => {
        toastr.error('Something went wrong! Check the console for details');
      })
      .finally(() => {
        submitActions.endLoading();
      });
  }

  return (
    <ContentArea>
      <Header toggleMobileNav={toggleMobileNav} />
      <PaperlessContainer>
        <SideNav
          showMobileNav={showMobileNav}
          style={{ height: '100% !important' }}
        />
        <PaperlessContent showMobileNav={showMobileNav}>
          <UserInformation User={props.User} />
          <Container
            onSubmit={e => {
              e.preventDefault();
              verifySave();
            }}
            onError={errors => console.log(errors)}
          >
            <Label variant="h2" className={classes.heading}>
              {getLanguageText('Email Reminder')}
            </Label>
            <FormControlLabel
              value="start"
              className={classes.optInSlider}
              labelPlacement="start"
              id="paperless-sw-opt-in"
              label={
                <Label variant="body1" className={classes.optInLabel}>
                  {getLanguageText('Opt-in')}
                </Label>
              }
              onChange={({ target }) => setOptedIn(target.checked)}
              control={
                <IOSSwitch
                  color="primary"
                  checked={optedIn}
                  id="opt-in-switch"
                />
              }
            />
            {optedIn && (
              <>
                <TextValidator
                  type="email"
                  label={getLanguageText('Email')}
                  value={email}
                  margin="normal"
                  variant="outlined"
                  id="paperless-txt-email"
                  className={classes.email}
                  styles={{ width: '100%' }}
                  onChange={({ target }) => setEmail(target.value)}
                  validators={['required', 'isEmail']}
                  errorMessages={
                    language === 'en'
                      ? [
                          'Required field',
                          'Please enter a valid email address.'
                        ]
                      : [
                          'Campo Requerido',
                          'Por favor, introduce una dirección de correo electrónico válida.'
                        ]
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={checked}
                      color="primary"
                      className="clear"
                      checked={checked}
                      id="paperless-cb-billing-cycle"
                      onChange={() => setChecked(!checked)}
                    />
                  }
                  label={getLanguageText(
                    'I understand changes may take up to one billing cycle.'
                  )}
                />
              </>
            )}
            <SubmitBtn
              loading={submitState.loading}
              disabled={submitState.disabled}
              style={{ marginTop: optedIn ? 0 : '36px' }}
              id="paperless-save-btn"
              type="submit"
            >
              {getLanguageText('Save')}
            </SubmitBtn>
            {optedIn && (
              <Label variant="h4" className={classes.textVerbiage}>
                {getLanguageText('By clicking Save you agree to our')}&nbsp;
                <PrivacyLink>
                  <a
                    href="https://www.gottlieb.com/Gottlieb-Privacy-Policy.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                    id="email-reminder-privacy-policy"
                  >
                    {getLanguageText('privacy policy')}
                  </a>
                </PrivacyLink>
                &nbsp;
                {getLanguageText(
                  'and to receive all future correspondence and statements via email.'
                )}
              </Label>
            )}
          </Container>
        </PaperlessContent>
      </PaperlessContainer>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch({ type: 'SET_USER', user })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Paperless);
