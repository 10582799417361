import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DOBField from '../DOBField';
import {
  requiredValidator,
  minLengthValidator,
  composeValidators,
  honeypotValidator,
  regexValidator
} from 'sd-formik-validators';
import {
  accountNumberValidator,
  SubmitButton,
  useLanguage,
  LanguageDropdown,
  DOBRegex
} from '../common';
import { API_BASE, setTabTitle } from '../../utilities';
import { withRouter } from 'react-router-dom';
import { Typography as Label, TextField, Link } from '@material-ui/core';
import AuthService from '../../lib/AuthService';

import {
  LoginContainer,
  LoginPanel,
  CertContainer,
  CertGroup,
  PoweredBy,
  VentraGroup,
  VentraLogo,
  CertImage
} from './LoginStyles';
import { Formik, Form, Field } from 'formik';
const bg = require('../../assets/img/background.jpeg');

function Login({ setTimer, clearData, match, ...props }) {
  const { getLanguageText } = useLanguage();
  const [loginErrorMsg, setLoginErrorMsg] = useState('');

  useEffect(() => {
    window.sessionStorage.clear();
  }, []);
  useEffect(() => {
    const auth = new AuthService();
    auth.removeToken();
    clearData();
    setTimer('hasLogoutTimerStart', false);
    setTabTitle('Login');
  }, [setTimer, clearData]);

  function handleSubmit(values, actions) {
    const [fullDate, month, day, year] = values.DOB.match(DOBRegex);
    setLoginErrorMsg('');

    Axios.get(
      `${API_BASE}/DoctorBill/Authenticate/${values.accountNumber}/${month}-${day}-${year}/${values.lastName}`
    )
      .then(response => {
        const { status, data, headers } = response;
        if (status === 200) {
          const { setUser } = props;
          if (setUser) {
            const auth = new AuthService();
            auth.setToken(headers.user_token);
            setTimer('hasLogoutTimerStart', true);
            setUser({ ...data });
            props.history.push('/Account');
          }
        }
      })
      .catch(err => {
        setLoginErrorMsg('Invalid Login.');
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }
  return (
    <LoginContainer src={bg} id="login-container">
      <LoginPanel id="login-panel">
        <img
          src={require('../../assets/img/physician-bill-pay.png')}
          style={{ width: '300px', height: '50px' }}
          alt="Physician Bill Pay"
          id="pbp-logo"
        />

        <Label
          style={{ textAlign: 'center' }}
          id="account-number-and-patient-dob"
        >
          {getLanguageText('Acct Number/Patient-Last Name/DOB')}
        </Label>
        <Formik
          id="login-formik"
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{
            fakeUsername: '',
            accountNumber: match.params.accountNumber
              ? match.params.accountNumber
              : '',
            lastName: '',
            DOB: ''
          }}
          render={({ isValidating, isSubmitting }) => {
            return (
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
                id="login-form"
              >
                <Field name="fakeUsername" validate={honeypotValidator}>
                  {({ field }) => (
                    <input
                      {...field}
                      type="text"
                      id="username-txt"
                      style={{ display: 'none' }}
                    />
                  )}
                </Field>
                <Field
                  name="accountNumber"
                  id="login-form-account-number-field"
                  validate={composeValidators(
                    requiredValidator,
                    accountNumberValidator,
                    minLengthValidator({
                      minLength: 6,
                      customMessage:
                        'Account number requires 6 or more characters.'
                    })
                  )}
                >
                  {({ field, form }) => {
                    const { touched, errors } = form;
                    return (
                      <TextField
                        {...field}
                        value={field.value}
                        onChange={e => {
                          let { value } = e.target;
                          value = value.trim();
                          const isValid = value.match(/^([a-zA-Z]{0,1})\d*$/);
                          isValid &&
                            form.setFieldValue('accountNumber', value, true);
                        }}
                        margin="normal"
                        id="login-txt-name"
                        variant="outlined"
                        placeholder="P12345678"
                        label={getLanguageText('Account Number')}
                        autoComplete="off"
                        error={Boolean(
                          touched.accountNumber && errors.accountNumber
                        )}
                        helperText={
                          touched.accountNumber && errors.accountNumber
                            ? getLanguageText(errors.accountNumber)
                            : ''
                        }
                        autoFocus={true}
                      />
                    );
                  }}
                </Field>
                <Field name="lastName" validate={requiredValidator}>
                  {({ field, form }) => {
                    const { touched, errors } = form;
                    return (
                      <TextField
                        {...field}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value.trim());
                        }}
                        type="text"
                        margin="normal"
                        id="login-txt-last-name"
                        variant="outlined"
                        label={getLanguageText('Last Name')}
                        autoComplete="off"
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={
                          touched.lastName && errors.lastName
                            ? getLanguageText(errors.lastName)
                            : ''
                        }
                      />
                    );
                  }}
                </Field>
                <Field
                  name="DOB"
                  id="login-form-dob-field"
                  validate={composeValidators(
                    requiredValidator,
                    regexValidator({
                      regex: DOBRegex,
                      message: 'Invalid DOB'
                    })
                  )}
                >
                  {({ field, form: { touched, errors } }) => (
                    <DOBField id="login-mask-dob" {...field}>
                      {inputProps => {
                        return (
                          <TextField
                            {...inputProps}
                            type="text"
                            margin="normal"
                            id="login-txt-dob"
                            variant="outlined"
                            label={getLanguageText('Patient DOB')}
                            error={Boolean(touched.DOB && errors.DOB)}
                            helperText={
                              touched.DOB && errors.DOB
                                ? getLanguageText(errors.DOB)
                                : ''
                            }
                          />
                        );
                      }}
                    </DOBField>
                  )}
                </Field>

                <LanguageDropdown
                  style={{ margin: '16px 0' }}
                  id="login-language-dropdown"
                />
                <SubmitButton
                  id="login-btn-login"
                  color="primary"
                  loading={isValidating || isSubmitting}
                  disabled={isValidating || isSubmitting}
                >
                  <span id="login-btn-text">{getLanguageText('Login')}</span>
                </SubmitButton>
                {loginErrorMsg && (
                  <span
                    style={{
                      margin: '8px 0 0 0',
                      color: 'red'
                    }}
                    id="login-btn-cancel"
                  >
                    {loginErrorMsg}
                  </span>
                )}
              </Form>
            );
          }}
        />
        <CertContainer id="certificate-container">
          <CertGroup id="certificate-group">
            <CertImage
              id="trustwaveSealImage"
              src="https://sealserver.trustwave.com/seal_image.php?customerId=w6oj3BufDtNVfsRHKIMpiViTHPZVM8&amp;size=105x54&amp;style=invert"
              alt="This site is protected by Trustwave's Trusted Commerce program"
              border="0"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  'https://sealserver.trustwave.com/cert.php?customerId=w6oj3BufDtNVfsRHKIMpiViTHPZVM8&amp;size=105x54&amp;style=invert',
                  'c_TW',
                  'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'
                );
                return false;
              }}
              onContextMenu={() => {
                alert(
                  'Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'
                );
                return false;
              }}
              title="This site is protected by Trustwave's Trusted Commerce program"
            />
          </CertGroup>
          <VentraGroup id="ventra-group">
            <PoweredBy id="powered-by-text">Powered By</PoweredBy>
            <Link
              href="https://ventrahealth.com/"
              target="__blank"
              referrerPolicy="noopener noreferrer"
            >
              <VentraLogo
                id="ventra-logo"
                src={require('../../assets/img/Ventra-logo.png')}
                alt=""
              />
            </Link>
          </VentraGroup>
        </CertContainer>
        <span id="build-version" style={{ color: 'lightgrey' }}>
          3.0.0
        </span>
      </LoginPanel>
    </LoginContainer>
  );
}

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch({ type: 'SET_USER', user }),
  clearData: () => dispatch({ type: 'CLEAR_DATA' })
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(Login));
