export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  const negativeSign = amount < 0 ? '-' : '';

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();
  let j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  );
};

export const formatZip = valueStr => {
  const zip = valueStr.match(/\d/g);
  if (zip === null) {
    return '';
  } else if (zip.length <= 5) {
    const fiveDigitsOrLess = valueStr.match(/^\d{0,5}/g)[0];
    return fiveDigitsOrLess;
  } else if (zip.length >= 6) {
    const groups = valueStr.match(/(\d{5})-?(\d{1,4})/);
    if (groups !== null) {
      return `${groups[1]}-${groups[2]}`;
    }
  }
};

export const isEmail = emailText => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(emailText);
};

export const isNonProductionEnv = () => {
  const URL = document.location.href;

  if (URL.includes('dev')) {
    return true;
  } else if (URL.includes('qa')) {
    return true;
  } else if (URL.includes('localhost')) {
    return true;
  } else if (URL.includes(':3000')) {
    return true;
  } else if (URL.includes('inquiry')) {
    return true;
  } else {
    return false;
  }
};

// TODO : when going to production will need to add live API_BASE
const selectAPI_BASE = () => {
  const URL = document.location.href;
  if (URL.includes('dev')) {
    return 'https://devapi.corp.physicianbillpay.com';
  } else if (URL.includes('qa')) {
    return 'https://qaapi.corp.physicianbillpay.com';
  } else if (URL.includes('localhost')) {
    return 'https://devapi.corp.physicianbillpay.com';
  } else if (URL.includes(':3000')) {
    return 'https://devapi.corp.physicianbillpay.com';
  } else if (
    URL.includes('mga-prd-physicianbillpay-web.azurewebsites.net') ||
    URL.includes('physicianbillpay.com')
  ) {
    return 'https://api.physicianbillpay.com';
  } else {
    return 'https://api.physicianbillpay.com';
  }
};
export const TITLE_BASE = 'PhysicianBillPay';
export const setTabTitle = appendStr => {
  document.title = TITLE_BASE + ' - ' + appendStr;
};
// export const API_BASE = "https://devapi.thedoctorbill.com"; // "http://10.77.2.4:4005";
export const API_BASE = selectAPI_BASE();
export const safeCompare = (a, b) => JSON.stringify(a) === JSON.stringify(b);
