import React, { useEffect } from 'react';
import { useState } from 'react';
import { Header, SideNav } from '..';
import { connect } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  Typography as Label,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';

import { setTabTitle } from '../../utilities';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UserInformation } from '../common';
import {
  ContentArea,
  FAQContainer,
  DesktopContainer,
  FAQContent
} from './FAQStyles';

function FAQSet({ q, a, ...props }) {
  return (
    <ExpansionPanel {...props}>
      <ExpansionPanelSummary expandIcon={<Icon icon={faChevronDown} />}>
        <Label
          dangerouslySetInnerHTML={{
            __html: q
          }}
        ></Label>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Label
          dangerouslySetInnerHTML={{
            __html: a
          }}
        ></Label>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

const FAQs = [
  {
    Q:
      '<strong>I just received your bill in the mail, what is it for?</strong>',
    A:
      'This bill is for the emergency room physician who took care of you during your visit to the emergency department.',
    key: 1
  },
  {
    Q: '<strong>Why did I receive two bills?</strong>',
    A:
      'You will receive at least two bills, one from the emergency room physician for his/her services because they are independent contractors and not employees of the hospital.  The other one will be from the hospital for the use of their facility. <br /><br /> You may also receive a bill from a pathologist if you had any lab work done, and/or a radiologist if there were any x-rays taken.',
    key: 2
  },
  {
    Q: '<strong>Has my insurance been filed?</strong>',
    A:
      'Please click Account History to view the details of when your insurance was filed.',
    key: 3
  },
  {
    Q: '<strong>Has my insurance company paid this bill?</strong>',
    A: `The most recent information regarding insurance payments can be found under Account History.  If payment doesn’t appear please verify that the insurance information we have on your account is correct.

        If the insurance information is correct, the next step would be to contact your insurance company to find out if they are processing the claim in question and when to expect payment.  If your insurance company IS processing the claim, please call us so we can place a 30 day hold on your account to allow time to receive the payment. 
        
        If the insurance company is stating they have NOT received the claim, please contact us <a href="/#/Contact"><strong>here</strong></a> with an insurance representative’s name and a fax number so the claim can be resubmitted.`,
    key: 4
  },
  {
    Q: '<strong>What is my account balance?</strong>',
    A: `Please click Account Details to view the details of your account balance.`,
    key: 5
  },
  {
    Q:
      '<strong>Can I give you my insurance information so you can file a claim?</strong>',
    A:
      'Yes. Please click Account Details to review or edit your insurance information.',
    key: 6
  },
  {
    Q: '<strong>Can I make monthly payments/payment arrangements?</strong>',
    A:
      'Yes.  You can make monthly payments online by clicking the ‘Pay’ button on Account Details, sending credit card details or a check with the remittance on your monthly invoice, or by contacting us via phone.  Monthly statements will be generated reflecting your last payment and current outstanding balance. <br /> <br />  Automatic reoccurring payment arrangements are currently not available.',
    key: 7
  },
  {
    Q: '<strong>Do you take credit card payments?</strong>',
    A:
      'Yes, credit card payments are accepted. Please click the ‘Pay’ button on the top right corner to make a Credit Card Payment.',
    key: 8
  },
  {
    Q:
      '<strong>Why am I receiving this bill from a doctor? I only saw a nurse.</strong>',
    A:
      'A medical record was generated regarding your visit to the Emergency Department. A medical record is generated only when a Patient is seen by either a Doctor, a Physician Assistant or a Resident. If you were not seen by the Doctor, then you were seen by a Physician Assistant or a Resident. The Physician Assistant or Resident work only under the direct supervision of the Doctor who was present in the Emergency Department. All decisions regarding your medical treatment were made by the Emergency Room Doctor working that day.',
    key: 9
  }
];

function FAQ({ User }) {
  const [showMobileNav, setShowMobileNav] = useState(false);
  useEffect(() => {
    setTabTitle('FAQ');
  }, []);
  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  return (
    <ContentArea>
      <Header toggleMobileNav={toggleMobileNav} />
      <FAQContainer>
        <SideNav showMobileNav={showMobileNav} />
        <FAQContent showMobileNav={showMobileNav}>
          <UserInformation User={User} />
          <DesktopContainer>
            <h2>Frequently Asked Questions</h2>
            {FAQs &&
              FAQs.map(FAQ => (
                <FAQSet
                  key={FAQ.key}
                  id={`faq-panel-item-${FAQ.key}`}
                  q={FAQ.Q}
                  a={FAQ.A}
                />
              ))}
          </DesktopContainer>
        </FAQContent>
      </FAQContainer>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User
});

export default connect(mapStateToProps)(FAQ);
