import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';
const PayNowText = styled.span`
  margin-right: ${props => (props.loading ? '15px' : '0px')};
`;

export const useMaterialSubmitButton = (initialState = {}) => {
  const [state, setState] = useState({
    loading: false,
    disabled: false,
    ...initialState
  });

  const startLoading = () => {
    setState({
      loading: true,
      disabled: true
    });
  };
  const endLoading = () => {
    setState({
      loading: false,
      disabled: false
    });
  };
  const actions = {
    startLoading,
    endLoading
  };

  const MaterialSubmitButton = ({
    children,
    loading,
    disabled,
    ...buttonProps
  }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={disabled}
        {...buttonProps}
      >
        <PayNowText>{children}</PayNowText>
        {loading ? <CircularProgress size={20} /> : null}
      </Button>
    );
  };

  return [state, actions, MaterialSubmitButton];
};
