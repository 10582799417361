import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { MenuButton, HeaderBar, Logo, PrintLogo } from './HeaderStyles';
import { LanguageDropdown } from '../common';
function Header({ toggleMobileNav }) {
  return (
    <HeaderBar style={{ display: 'flex', justifyContent: 'space-between' }}>
      <MenuButton
        icon={faBars}
        size="2x"
        onClick={toggleMobileNav}
        id="header-btn-expand"
      />
      <Logo
        height="35"
        src={require('../../assets/img/physician-bill-pay-light.png')}
        alt="The Doctor Bill"
      />
      <PrintLogo
        height="35"
        src={require('../../assets/img/physician-bill-pay.png')}
        alt="The Doctor Bill"
      />
      <LanguageDropdown style={{ color: 'white' }} />
    </HeaderBar>
  );
}

export default Header;
