import styled from 'styled-components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { theme } from 'lib/theme';
import { BrandColor } from 'constants/brandColor';

const MenuButton = styled(Icon)`
  margin-right: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const HeaderBar = styled.div`
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.palette.primary.dark};
  border-bottom: 2px solid ${BrandColor.ACCENT};
  box-shadow: 0px 2px 4px 0px #0004
  z-index: 1;
  width: 100%;
  height: 50px;

  p {
    color: #fff;
  }

  svg {
    color: #fff;
  }

  @media print {
    display: none;
  }

  @media screen and (min-width: 960px) {
    position: static;
    ${MenuButton} {
      display: none;
    }
  }
`;
const Logo = styled.img`
  margin: 0 auto;
  height: 25px;
  @media print {
    display: none;
  }
  @media screen and (min-width: 960px) {
    margin: 0;
    height: 35px;
  }
`;
const PrintLogo = styled.img`
  display: none;
  margin: 0 auto;
  height: 25px;
  @media print {
    display: block;
  }
  @media screen and (min-width: 960px) {
    margin: 0;
    height: 35px;
  }
`;
export { MenuButton, HeaderBar, Logo, PrintLogo };
