import React, { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';

import { SideNav, Header } from '../../';
import { setTabTitle, formatMoney } from '../../../utilities';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { UserInformation, useLanguage, PBPTable } from '../../common';
import {
  faPrint,
  faCheck,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

import { Typography as Label } from '@material-ui/core';

import {
  ContentArea,
  ConfirmationContainer,
  ConfirmationHeader,
  ConfirmationBody,
  Detail,
  PayBillContent,
  DetailsContainer,
  PrintContainer,
  ConfirmationView
} from './ConfirmPaymentStyles';

function ConfirmPayment({ User, Transaction, PayInvoices }) {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { getLanguageText } = useLanguage();
  useEffect(() => {
    setTabTitle('Payment Confirmation');
  }, []);
  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  return (
    <ContentArea>
      <Header toggleMobileNav={toggleMobileNav} />
      <PayBillContent showMobileNav={showMobileNav}>
        <SideNav showMobileNav={showMobileNav} />
        <ConfirmationContainer showMobileNav={showMobileNav}>
          <UserInformation User={User} className="user-information" />
          <ConfirmationView>
            <ConfirmationHeader>
              <Icon icon={faCheckCircle} size="5x" style={{ color: 'green' }} />
              <h1>{getLanguageText('Payment Confirmation')}</h1>
            </ConfirmationHeader>
            <hr />
            <ConfirmationBody>
              <Label>
                <Icon icon={faCheck} style={{ color: 'green' }} />
                &nbsp; {getLanguageText('Your payment was made successfully')}
              </Label>
              <br />
              <DetailsContainer>
                <Detail>
                  <Label>{getLanguageText('Name')}:</Label>
                  <Label id="user-name">
                    {User.firstName} {User.lastName}
                  </Label>
                </Detail>

                <Detail>
                  <Label>{getLanguageText('Approval Code')}:</Label>
                  <Label id="transaction-id">{Transaction.transactionID}</Label>
                </Detail>

                <Detail>
                  <Label>{getLanguageText('Transaction ID')}:</Label>
                  <Label id="transaction-trans-log">
                    {Transaction.transLog}
                  </Label>
                </Detail>

                <Detail>
                  <Label>{getLanguageText('Transaction Date')}:</Label>
                  <Label id="transaction-date">
                    {moment().format('MM/DD/YYYY LT')}
                  </Label>
                </Detail>

                <Detail>
                  <Label>{getLanguageText('Transaction Amount')}:</Label>
                  <Label id="transaction-amount-posted">
                    {Transaction.amountPosted}
                  </Label>
                </Detail>
              </DetailsContainer>
              <PBPTable
                hidePayButton
                style={{ marginTop: '12px' }}
                ComponentWhenDataEmpty={
                  <p>{getLanguageText('No Invoices to Display')}</p>
                }
                columns={React.useMemo(() => {
                  return [
                    {
                      Header: getLanguageText('Invoice'),
                      accessor: 'InvoiceNumber',
                      Cell: data => {
                        return (
                          <div id={`invoice-${data.cell.value}`}>
                            {data.cell.value}
                          </div>
                        );
                      }
                    },
                    {
                      Header: getLanguageText('Amount'),
                      accessor: 'PaymentAmount',
                      Cell: data => {
                        const { InvoiceNumber } = data.row.values;
                        return (
                          <div
                            style={{ width: '100%' }}
                            id={`invoice-${InvoiceNumber}-pay-amount`}
                          >
                            ${formatMoney(data.cell.value)}
                          </div>
                        );
                      }
                    }
                  ];
                }, [getLanguageText])}
                data={Transaction && Transaction.invoices}
              />
            </ConfirmationBody>
            <PrintContainer
              onClick={() => window.print()}
              id="confirm-payment-div-print"
              className="action"
            >
              <Icon icon={faPrint} size="2x" />
              &nbsp;
              <Label>{getLanguageText('Print Receipt')}</Label>
            </PrintContainer>
            <br />
          </ConfirmationView>
        </ConfirmationContainer>
      </PayBillContent>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User,
  Transaction: state.Transaction
});

export default connect(mapStateToProps)(ConfirmPayment);
