import jwtDecode from 'jwt-decode';
export default class AuthService {
  // Initializing important variables
  constructor() {
    this.getProfile = this.getProfile.bind(this);
  }

  isLoggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from sessionStorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired.
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  setToken(token) {
    // Saves user token to sessionStorage
    sessionStorage.setItem('token', token);
  }

  getToken() {
    // Retrieves the user token from sessionStorage
    return sessionStorage.getItem('token');
  }
  removeToken() {
    sessionStorage.removeItem('token');
  }

  logout() {
    // Clear user token and profile data from sessionStorage
    sessionStorage.removeItem('token');
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return jwtDecode(this.getToken());
  }

  _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
