import styled from 'styled-components';

const ContentArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: white;


  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: rgb(243, 242, 241);
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .MuiButtonBase-root {
    background-color: #abab25;
  }

  button {
    white-space: nowrap;

    &:hover {
      a {
        color: #fff;
      }
      background-color: #415463;
    }
  }
`;

const FormsContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
`;

const DesktopContainer = styled.div`
  padding: 10px 15px;

  a {
    width: 325px;
    display: flex;
    margin-bottom: 25px;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  h4 {
    display: inline;
    a {
      width: auto;
      display: inherit;
      text-decoration: underline;
    }
  }
`;

const MobileContainer = styled.div`
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 325px;
  }
`;

const MediaQueryAdobe = styled.div`
   @media screen and (max-width: 960px) {
    h4 {
     display: none   
    }   
    
   }
`

const FormContent = styled.div`
position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};

  displa: flex;
  width: 100%;
  height: 100%;

  .fa-check-circle {
    color: green;
    margin-right: 5px;
  }

  .fa-times-circle {
    color: red;
  }
`;

export {
    ContentArea,
    FormsContainer,
    DesktopContainer,
    MobileContainer,
    FormContent,
    MediaQueryAdobe
}