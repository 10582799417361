import styled from 'styled-components';

import { ValidatorForm } from 'react-material-ui-form-validator';

//Hide Pay Bill
//#region ContentArea
const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  width: 100%;

  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: rgb(243, 242, 241);
  }

  a {
    color: #000;
    text-decoration: none;
  }

  
    &.plain {
      color: #000;
    }
  }
`;
//#endregion

//#region PayBillContainer
const PayBillContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
`;
//#endregion

const ViewContainer = styled.div`
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  textarea {
    width: 50%;
    resize: none;
  }

  button {
    float: right;
  }

  fieldset {
    background-color: inherit;
  }
`;

//#region Pay Bill Form Container
const PayBillFormContainer = styled(ValidatorForm)`
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & div {
    width: 100%;
  }

  @media screen and (min-width: 1280px) {
    width: 75%;
  }

  @media screen and (min-width: 1920px) {
    width: 50%;
  }

  .MuiCheckbox-root {
    background-color: transparent;
  }
`;
//#endregion

//#region Pay Bill Content
const PayBillContent = styled.div``;
//#endregion

const CardIconContainer = styled.div`
  text-align: right;
  svg {
    margin-right: 5px;
  }

  @media screen and (min-width: 960px) {
    svg {
      margin-top: 5px;
    }
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
const DialogBody = styled.div`
  padding: 16px 24px;

  button {
    float: right;
    margin-top: 24px;
    margin-left: 10px;
  }

  p {
    font-size: 18px;
  }

  button {
    white-space: nowrap;

    &.plain {
      color: #000;
    }
  }
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  & > * {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
const IncDialogBody = styled.div`
  padding: 16px 24px;

  /* p {
    display: inline-block;
  } */
  button {
    /* display: inline-block; */
    width: 25%;

    &.plain {
      color: #000;
    }
  }

  p {
    font-size: 18px;
    text-align: center;
  }
`;

const IncDialogQContainer = styled.div`
  width: 74%;
  border: solid 2px black;
  border-radius: 4px;
`;
export {
  ContentArea,
  PayBillContainer,
  PayBillFormContainer,
  PayBillContent,
  CardIconContainer,
  ViewContainer,
  FlexDiv,
  DialogBody,
  IncDialogBody,
  Row,
  IncDialogQContainer
};
