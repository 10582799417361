const initialState = {
  language: 'en'
};

export const Language = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      const { language } = action;
      return {
        ...state,
        language
      };
    }
    case 'CLEAR_DATA': {
      return {};
    }
    default:
      return state;
  }
};
