import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const PageTitle = styled.h2`
  margin-top: 0;
  font-size: 21px;
  font-weight: 700;
`;
const ContentArea = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: white;

  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: lightgrey;
`;
const ContactContainer = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
`;

const Container = styled.div`
  padding: 10px 15px;
  max-width: 400px;
  width: 100%;
  height: auto;
`;
const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
`;
const AccountNumber = styled.p`
  font-size: 14px;
  margin: 0 0 14px 0;
`;
const ContactGroup = styled.div`
  padding: 24px 0;
`;
const InlineInputGroup = styled.div`
  display: flex;
  margin: 4px 0;
  .label {
    vertical-align: center;
    margin: 12px 12px 0 0;
    font-size: 14px;
  }
`;
const MessageInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`;
const MessageTextArea = styled(TextField)`
  width: 100%;
`;
const ContactContent = styled.div`
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  height: 100%;
  width: 100%;
  @media screen and (min-width: 960px) {
  }

  .fa-check-circle {
    color: green;
    margin-right: 5px;
  }

  .fa-times-circle {
    color: red;
  }

  .noMargin {
    margin: 0;
  }
`;
export {
  PageTitle,
  ContentArea,
  Divider,
  ContactContainer,
  Container,
  EmailForm,
  AccountNumber,
  ContactGroup,
  InlineInputGroup,
  MessageInputGroup,
  MessageTextArea,
  ContactContent
};
