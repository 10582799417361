import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  min-width: 500px;
  width: 100%;
  @media screen and (max-width: 960px) {
    padding: 30px;
  }
  background: url(${props => props.src}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const Background = styled.img`
  display: none;

  @media screen and (min-width: 960px) {
    // display: inline;
    // /* Set rules to fill background */
    min-height: 100%;
    // min-width: 1024px;

    // /* Set up proportionate scaling */
    width: 100%;
    height: 100%;

    // /* Set up positioning */
    // // position: fixed;
    // // top: 0;
    // // left: 0;
    height: 100%;
  }
`;
const LoginPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20%;
  // position: absolute;
  // top: 0;
  padding: 15px;
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  background: white;
  svg {
    cursor: pointer;
  }

  p.error {
    color: red;
  }

  img {
    width: 250px;
    margin-bottom: 10px;
  }

  /* Material UI Embedded Classes */
  .MuiFormControl-root {
    width: 280px;
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #3c2109;
    }
  }

  a {
    text-decoration: none;
  }

  @media screen and (min-width: 960px) {
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 400px;
    //align-self: center;

    /* Material UI Embedded Classes */
    .MuiFormControl-root {
      width: 280px;
    }
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  button {
    margin-top: 0;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  @media screen and (min-width: 960px) {
    height: 100%;
    flex-direction: row;

    div {
      display: flex;
    }
  }
`;

const BirthDayContainer = styled.div`
  display: flex;

  /* Material UI Embedded Classes */
  .MuiFormControl-root:first-child {
    width: 65px;
  }

  .MuiFormControl-root:nth-child(2) {
    width: 65px;
  }

  .MuiFormControl-root:last-child {
    width: 85px;
  }

  @media screen and (min-width: 960px) {
    height: 100%;
    /* Material UI Embedded Classes */
    .MuiFormControl-root:first-child {
      width: 65px;
    }

    .MuiFormControl-root:nth-child(2) {
      width: 65px;
    }

    .MuiFormControl-root:last-child {
      width: 85px;
    }
  }

  margin-bottom: 10px;
`;

const CertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  border-top: 2px solid grey;
  padding: 24px 0 0 0;
  justify-self: stretch;
  align-self: stretch;
`;
const CertGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  padding: 0 40px;
`;

const CertImage = styled.img`
  cursor: pointer;
  max-width: 80px;
  height: auto;
`;
const PoweredBy = styled.p`
  font-size: 9px;
  margin: 0;
`;
const VentraGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const VentraLogo = styled.img`
  margin: 0;
  max-width: 120px;
  height: auto;
`;

export {
  LoginContainer,
  Background,
  LoginPanel,
  FlexDiv,
  BirthDayContainer,
  CertContainer,
  CertGroup,
  CertImage,
  PoweredBy,
  VentraGroup,
  VentraLogo
};
