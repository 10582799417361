import styled from 'styled-components';

const ContentArea = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: rgb(243, 242, 241);
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;

const HistoryContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
`;

const DesktopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 15px;
  p.block {
    display: block;
  }

  table {
    width: 100%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }

  table thead tr {
    background-color: gray;
    text-align: left;
  }

  table thead th {
    color: #fff;
    border-left: 1px solid black;

    &:hover {
      cursor: pointer;
    }
  }

  table tbody td {
    border-left: 1px solid black;
  }

  table tbody tr:nth-child(odd) {
    background-color: lightgray;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  width: 100%;
  height: 100%;
`;

const HistoryContent = styled.div`
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  width: 100%;
  display: ${props => (props.showMobileNav === true ? '0' : 'flex')};
  flex-direction: column;

  ${DesktopContainer} {
    display: none;
  }

  @media screen and (min-width: 960px) {
    ${DesktopContainer} {
      display: flex;
      flex-direction: column;
    }

    ${MobileContainer} {
      display: none;
    }
  }

  .fa-check-circle {
    color: green;
    margin-right: 5px;
  }

  .fa-times-circle {
    color: red;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  max-width: 500px;
  gap: 10px;

  align-items: center;
  padding-bottom: 5px;
  button {
    width: 115px;
    height: 36px;
    align-self: flex-end;
    justify-self: flex-start;

    &:hover {
      color: #fff;
    }
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    button {
      width: 100%;
      flex-grow: 1;
      height: 36px;
      align-self: flex-end;
      justify-self: flex-start;

      &:hover {
        color: #fff;
      }
    }
  }
`;

const CalenderInputMobileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: rgb(243, 242, 241);
  border: 1px solid rgba(0, 0, 0, 0.27);
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div:last-child {
    display: block;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.27);
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }
`;

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
`;

const OpacityBlock = styled.div`
  position: absolute;
  left: 200px;
  right: 200px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  p {
    font-size: 24px;
    white-space: nowrap;
  }
`;

const CalendarContainer = styled.div`
  position: relative;

  p.label {
    font-size: 14px;
  }

  div.rc-calendar {
    position: absolute;
  }
`;

const CalendarInputContainer = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 36px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid black;

  &:hover {
    cursor: pointer;
  }
`;

export {
  ContentArea,
  HistoryContainer,
  DesktopContainer,
  MobileContainer,
  HistoryContent,
  FilterContainer,
  DataCard,
  CardListContainer,
  OpacityBlock,
  CalendarContainer,
  CalendarInputContainer,
  CalenderInputMobileContainer
};
