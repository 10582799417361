import React, { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { connect } from 'react-redux';

import { SideNav, Header } from '../';
import { setTabTitle } from '../../utilities';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { UserInformation } from '../common';
import {
  faPrint,
  faCheck,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

import { Typography as Label } from '@material-ui/core';

import {
  ContentArea,
  ConfirmationContainer,
  ConfirmationHeader,
  ConfirmationBody,
  Detail,
  PayBillContent,
  DetailsContainer,
  PrintContainer,
  ConfirmationView
} from './EmailConfirmationStyles';

function EmailConfirmation({ User, Transaction }) {
  const [showMobileNav, setShowMobileNav] = useState(false);
  useEffect(() => {
    setTabTitle('Payment Confirmation');
  }, []);
  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  return (
    <ContentArea>
      <Header toggleMobileNav={toggleMobileNav} />
      <PayBillContent showMobileNav={showMobileNav}>
        <SideNav showMobileNav={showMobileNav} />
        <ConfirmationContainer showMobileNav={showMobileNav}>
          <UserInformation User={User} className="user-information" />
          <ConfirmationView>
            <ConfirmationHeader>
              <Icon icon={faCheckCircle} size="5x" />
              <h1>Email Confirmation</h1>
            </ConfirmationHeader>
            <hr />
            <ConfirmationBody>
              <Label>
                <Icon icon={faCheck} />
                &nbsp; Your email was confirmed successfully
              </Label>
              <br />
              <DetailsContainer>
                <Detail>
                  <Label>Name:</Label>
                  <Label>
                    {User.firstName} {User.lastName}
                  </Label>
                </Detail>

                <Detail>
                  <Label>Approval Code:</Label>
                  <Label>{Transaction.transactionID}</Label>
                </Detail>

                <Detail>
                  <Label>Transaction ID:</Label>
                  <Label>{Transaction.transLog}</Label>
                </Detail>

                <Detail>
                  <Label>Transaction Date:</Label>
                  <Label>{moment().format('MM/DD/YYYY LT')}</Label>
                </Detail>

                <Detail>
                  <Label>Transaction Amount:</Label>
                  <Label>{Transaction.amountPosted}</Label>
                </Detail>
              </DetailsContainer>
            </ConfirmationBody>
            <PrintContainer
              onClick={() => window.print()}
              id="confirm-payment-div-print"
              className="action"
            >
              <Icon icon={faPrint} size="2x" />
              &nbsp;
              <Label>Print Receipt</Label>
            </PrintContainer>
            <br />
          </ConfirmationView>
        </ConfirmationContainer>
      </PayBillContent>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User,
  Transaction: state.Transaction
});

export default connect(mapStateToProps)(EmailConfirmation);
