const initialState = {
  invoices: []
};

export const Transaction = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TRANSACTION': {
      const { transaction } = action;
      return transaction;
    }
    case 'CLEAR_DATA': {
      return {};
    }
    default:
      return state;
  }
};
