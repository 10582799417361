import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
const PayAmountField = ({
  globalDispatch,
  clientID,
  PayInvoices,
  invoiceID,
  style,
  isCollections,
  ...props
}) => {
  const invoices = PayInvoices.divisions[clientID]?.invoices || {}
  const invoice = useRef(invoices[invoiceID] || null);
  const invoiceIDRef = useRef(invoiceID);
  const [payAmount, setPayAmount] = useState('');
  const formatAmount = amount => {
    return String(Number(amount).toFixed(2));
  };
  useEffect(() => {
    const invoiceAmount = Number(
      window.sessionStorage.getItem(invoiceIDRef.current)
    );

    if (invoiceAmount >= props.maxConstraint) {
      setPayAmount(formatAmount(props.maxConstraint));
    } else if (invoiceAmount <= props.minConstraint) {
      setPayAmount('');
    } else {
      setPayAmount(
        formatAmount(
          (invoice && invoice.current && invoice.current.PaymentAmount) ||
            invoiceAmount
        )
      );
    }
  }, [props.minConstraint, props.maxConstraint]);
  useEffect(() => {
    if (payAmount) {
      globalDispatch({
        type: 'ADD_PAY_INVOICE',
        clientID,
        invoiceID,
        payAmount: payAmount,
        isCollections
      });
    }
  }, [payAmount, invoiceID, clientID, globalDispatch, isCollections]);
  useEffect(() => {
    globalDispatch({
      type: 'SUM_PAY_INVOICES',
      clientID
    });
  }, [payAmount, globalDispatch, clientID]);
  return (
    <input
      type="text"
      style={{
        padding: '4px',
        textAlign: 'right',
        ...style
      }}
      placeholder="Enter Amount"
      value={payAmount}
      id={`invoice-${invoiceID}-pay-amount`}
      onChange={e => {
        const { value } = e.target;

        // [^0-9\.] - Checks whether the value is not a number or a period
        // /\.{2,}/g - Checks if the value has two or more dots, only want to allow one dot
        if (
          // eslint-disable-next-line no-useless-escape
          /[^0-9\.]/.test(value) ||
          (value.match(/\./g) && value.match(/\./g).length >= 2) ||
          value.match(/\d+\.\d{3,}/) ||
          /^0\d$/.test(value)
        ) {
          return;
        } else if (Number(value) > props.maxConstraint) {
          setPayAmount(String(props.maxConstraint));
        } else {
          setPayAmount(value);
        }
      }}
      onFocus={() => {
        globalDispatch({
          type: 'SET_LAST_MODIFIED',
          clientID
        });
      }}
      onBlur={() => {
        if (Number(payAmount) >= Number(props.maxConstraint)) {
          setPayAmount(formatAmount(props.maxConstraint));
        } else if (Number(payAmount) <= props.minConstraint) {
          setPayAmount(formatAmount(0));
        } else {
          setPayAmount(formatAmount(payAmount));
        }
        window.sessionStorage.setItem(invoiceID, formatAmount(payAmount));
      }}
    />
  );
};
const mapStateToProps = state => {
  return {
    PayInvoices: state.PayInvoices
  };
};
const mapDispatchToProps = dispatch => {
  return {
    globalDispatch: dispatch
  };
};

PayAmountField.defaultProps = {
  style: {},
  minConstraint: 0,
  maxConstraint: 0
};
const PayAmountFieldWithHOCs = connect(
  mapStateToProps,
  mapDispatchToProps
)(PayAmountField);
export { PayAmountFieldWithHOCs as PayAmountField };
