import React from 'react';
import { useLanguage } from 'components/common';

export const FilterInvoicesSelectMenu = props => {
  const { getLanguageText } = useLanguage();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label
        htmlFor="filter-invoice-select"
        style={{ fontWeight: 700, margin: '0 0 6px 0' }}
      >
        {getLanguageText('Filter Invoices')}
      </label>
      <select id="filter-invoice-select" {...props}>
        <option value="all" id="filter-invoices-item-all">
          {getLanguageText('All')}
        </option>
        <option value="SELF PAY" id="filter-invoices-item-outstanding">
          {getLanguageText('Outstanding')}
        </option>
      </select>
    </div>
  );
};
