import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import toastr from 'toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyTablePlaceholder } from './EmptyTablePlaceholder';
import styled from 'styled-components';
import { MobilePBPTableAlternative } from './MobilePBPTableAlternative/MobilePBPTableAlternative';
import { PayButton } from 'components/Account/Account/PayButton/PayButton';
import { PayAmountField } from 'components/Account/Account/PayAmountField/PayAmountField';
import { Field } from 'formik';
import { MoneyCell, useLanguage } from '..';
import { InsuranceInfoButton } from 'components/Account/Account/InsuranceInfoButton/InsuranceInfoButton';
import { InsuranceButtonWrapper } from 'components/Account/Account/AccountStyles';
import { formatMoney } from 'utilities';
import { connect } from 'react-redux';

const Table = styled.table`
  display: none;
  width: 100%;
  border: 1px solid black;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: ${props =>
      String(props.mobileViewMaxWidth + 1 + 'px')}) {
    display: table;
  }
  @media print {
    display: block;
  }
`;
const TableHeaderRow = styled.tr`
  background-color: gray;
  text-align: left;
`;
const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: lightgrey;
  }
`;
const TableHeader = styled.th`
  color: #fff;
  border-left: 1px solid black;
  &:last-child {
    border-right: 1px solid black;
  }
`;
const TableCell = styled.td`
  border-left: 1px solid black;
  width: auto;
  &:last-child {
    border-right: 1px solid black;
  }
`;

export const PBPTable = ({
  data,
  columns,
  clientID,
  emptyDataMessage,
  ComponentWhenDataEmpty,
  mobileViewMaxWidth,
  mobileRender,
  tableRender,
  style,
  mobileProps,
  setInsuranceDrawerOpen,
  setPrimaryInsuranceData,
  setSecondaryInsuranceData,
  setInsuranceInvoiceID,
  decodedJWT,
  PayInvoices,
  globalDispatch,
  history,
  hidePayButton,
  children,
  ...props
}) => {

  const { getLanguageText, language } = useLanguage();
  const columnsToUse = useMemo(() => columns || [
    {
      Header: getLanguageText('Invoice'),
      accessor: 'invoiceID',
      Cell: data => {
        const { invoiceID } = data.row.values;
        return (
          <span
            id={`${invoiceID}-invoice-id`}
          >
            {data.cell.value}
          </span>
        );
      }
    },
    {
      Header: getLanguageText(
        'Date(s) of Service'
      ),
      accessor: 'dosFormatted',
      sortDescFirst: true,
      Cell: data => {
        const {
          dosFormatted,
          invoiceID
        } = data.row.values;
        
        return (
          <span
            id={`${invoiceID}-dos-${dosFormatted}`}
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {data.cell.value}
          </span>
        );
      }
    },
    {
      Header: getLanguageText('Charge'),
      accessor: 'chargeTotal',
      Cell: data => {
        const {
          invoiceID,
          chargeTotal
        } = data.row.values;
  
        return (
          <MoneyCell
            id={`${invoiceID}-charge-total-${chargeTotal}`}
          >
            {data.cell.value}
          </MoneyCell>
        );
      }
    },
    {
      Header: getLanguageText('Ins. Paid'),
      accessor: 'insurancePaidTotal',
      Cell: data => {
        const {
          invoiceID,
          insurancePaidTotal
        } = data.row.values;
  
        return (
          <MoneyCell
            id={`${invoiceID}-insurance-paid-total-${insurancePaidTotal}`}
          >
            {data.cell.value}
          </MoneyCell>
        );
      }
    },
    {
      Header: getLanguageText('Patient Paid'),
      accessor: 'patientPaidTotal',
      Cell: data => {
        const {
          invoiceID,
          patientPaidTotal
        } = data.row.values;
  
        return (
          <MoneyCell
            id={`${invoiceID}-patient-paid-total-${patientPaidTotal}`}
          >
            {data.cell.value}
          </MoneyCell>
        );
      }
    },
    {
      Header: getLanguageText('Adjustments'),
      accessor: 'adjustmentsTotal',
      Cell: data => {
        const {
          invoiceID,
          adjustmentsTotal
        } = data.row.values;
        return (
          <MoneyCell
            id={`${invoiceID}-adjustments-total-${adjustmentsTotal}`}
          >
            {data.cell.value}
          </MoneyCell>
        );
      }
    },
    {
      Header: getLanguageText(
        'Responsibility'
      ),
      accessor: 'responsibleParty',
      Cell: data => {
        const { invoiceID } = data.row.values;
        return (
          <div
            id={`invoice-${invoiceID}-responsibility`}
          >
            {getLanguageText(data.cell.value)}
          </div>
        );
      }
    },
    {
      Header: getLanguageText(
        'Insurance Details'
      ),
      id: 'insuranceDetails',
      accessor: ({
        primaryInsurance,
        secondaryInsurance
      }) => {
        return {
          primaryInsurance,
          secondaryInsurance
        };
      },
      disableSorting: true,
      Cell: data => {
        const {
          primaryInsurance,
          secondaryInsurance
        } = data.row.values[
          'insuranceDetails'
        ];
        const { invoiceID } = data.row.values;
        return (
          <InsuranceButtonWrapper>
            <InsuranceInfoButton
              onClick={() => {
                setInsuranceDrawerOpen(true);
                setPrimaryInsuranceData(
                  primaryInsurance
                );
                setSecondaryInsuranceData(
                  secondaryInsurance
                );
                setInsuranceInvoiceID(
                  invoiceID
                );
              }}
              id={`${data.row.values.invoiceID}-insurance-info-button`}
            >
              {language === 'en'
                ? 'Ins. Info'
                : getLanguageText(
                    'Insurance Details'
                  )}
            </InsuranceInfoButton>
          </InsuranceButtonWrapper>
        );
      }
    },
    {
      Header: getLanguageText('Balance'),
      accessor: 'balanceOwedTotal',
      Cell: data => {
        const {
          invoiceID,
          balanceOwedTotal,
          responsibleParty
        } = data.row.values;
        if (
          responsibleParty ===
          'Payment Pending'
        ) {
          return (
            <div
              id={`${invoiceID}-balance-owed-total`}
            ></div>
          );
        } else {
          return (
            <MoneyCell
              id={`${invoiceID}-balance-owed-total`}
            >
              {balanceOwedTotal}
            </MoneyCell>
          );
        }
      }
    },
    {
      Header: getLanguageText('Pay Amount'),
      id: 'payAmount',
      Cell: data => {
        const {
          balanceOwedTotal,
          invoiceID
        } = data.row.values;
  
        if (
          balanceOwedTotal <= 0 &&
          decodedJWT.InquiryCommandID !== '2'
        ) {
          return null;
        }
  
        return (
          <Field
            name={`payAmount${invoiceID}`}
          >
            {({ field, form }) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent:
                      'flex-end',
                    width: '100%',
                    minWidth: '100px',
                    maxWidth: '100px',
                    marginLeft: 'auto'
                  }}
                >
                  <PayAmountField
                    field={field}
                    form={form}
                    clientID={clientID}
                    invoiceID={invoiceID}
                    isCollections={
                      data.row.original
                        .isCollections
                    }
                    minConstraint={0}
                    maxConstraint={
                      decodedJWT.InquiryCommandID ===
                      '2'
                        ? Infinity
                        : balanceOwedTotal
                    }
                    style={{
                      width: '100%'
                    }}
                  />
                </div>
              );
            }}
          </Field>
        );
      }
    }
  ], []);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns: columnsToUse,
      data: data,
      initialState: {
        sortBy: [
          {
            id: "dosFormatted"
          }
        ]
      }
    },
    useSortBy,
  );

  return (
    <div style={{ marginTop: '12px' }}>
      {tableRender(
        <div>
          <Table
            {...getTableProps()}
            cellSpacing="0"
            cellPadding="8"
            mobileViewMaxWidth={mobileViewMaxWidth}
            {...style}
            {...props}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <TableHeaderRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <TableHeader
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span style={{ marginLeft: '8px' }}>
                        {column.isSorted === true &&
                          (column.isSortedDesc ? (
                            <FontAwesomeIcon icon="arrow-down" />
                          ) : (
                            <FontAwesomeIcon icon="arrow-up" />
                          ))}
                      </span>
                    </TableHeader>
                  ))}
                </TableHeaderRow>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map(
                  (row, i) =>
                    prepareRow(row) || (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )
                )
              ) : (
                <EmptyTablePlaceholder
                  colSpan={columnsToUse.length}
                  ComponentWhenDataEmpty={ComponentWhenDataEmpty}
                />
              )}
              {children}
            </tbody>
          </Table>
          {!hidePayButton && <PayButtonWrapper>
          <PayButton
            id="pay-total-invoices-btn"
            disabled={PayInvoices.lastModifiedClientId && PayInvoices?.lastModifiedClientId !== clientID}
            onClick={() => {
              if (
                (PayInvoices?.divisions[clientID] && PayInvoices.divisions[clientID].invoicesSum <= 0) ||
                (PayInvoices?.divisions[clientID] && !PayInvoices.divisions[clientID].invoicesSum)
              ) {
                toastr.error(
                  language === 'en'
                    ? 'Please enter an amount on one or more invoices to continue.'
                    : 'Ingrese un monto en una o màs facturas para continuar.'
                );
              } else {
                // We need to process the invoices for different divisions separately, so set the client to be paid
                globalDispatch({
                  type: 'SET_PAID_CLIENT',
                  clientID
                });
                // Then navigate to the pay page
                history.push('/Account/Pay');
              }
            }}
          >
            {`${getLanguageText('Pay Now')} `}
            <span id="pay-now-sum" style={{ marginLeft: '4px' }}>
              $
              {(PayInvoices?.divisions[clientID]?.invoicesSum &&
                formatMoney(PayInvoices?.divisions[clientID]?.invoicesSum.toFixed(2))) ||
                formatMoney(0)}
            </span>
          </PayButton>
        </PayButtonWrapper>
        }
        </div>
      )}
      {mobileRender(
        <>
          <MobilePBPTableAlternative
            rows={rows}
            columns={columnsToUse}
            mobileProps={mobileProps}
            mobileViewMaxWidth={mobileViewMaxWidth}
            prepareRow={prepareRow}
          />
          {!hidePayButton && <PayButton
              id="pay-total-invoices-btn"
              disabled={PayInvoices.lastModifiedClientId && PayInvoices?.lastModifiedClientId !== clientID}
              onClick={() => {
                if (
                  (PayInvoices?.divisions[clientID] && PayInvoices.divisions[clientID].invoicesSum <= 0) ||
                  (PayInvoices?.divisions[clientID] && !PayInvoices.divisions[clientID].invoicesSum)
                ) {
                  toastr.error(
                    language === 'en'
                      ? 'Please enter an amount on one or more invoices to continue.'
                      : 'Ingrese un monto en una o màs facturas para continuar.'
                  );
                } else {
                  // We need to process the invoices for different divisions separately, so set the client to be paid
                  globalDispatch({
                    type: 'SET_PAID_CLIENT',
                    clientID
                  });
                  // Then navigate to the pay page
                  history.push('/Account/Pay');
                }
              }}
            >
              {`${getLanguageText('Pay Now')} `}
              <span id="pay-now-sum" style={{ marginLeft: '4px' }}>
                $
                {(PayInvoices?.divisions[clientID]?.invoicesSum &&
                  formatMoney(PayInvoices?.divisions[clientID]?.invoicesSum.toFixed(2))) ||
                  formatMoney(0)}
              </span>
            </PayButton>}
        </>
      )}
      
    </div>
  );
};

PBPTable.defaultProps = {
  ComponentWhenDataEmpty: <p>No Records To Display</p>,
  style: {},
  mobileProps: {},
  mobileViewMaxWidth: 100,
  mobileRender: MobileTable => {
    return React.cloneElement(MobileTable, {});
  },
  tableRender: Table => {
    return React.cloneElement(Table, {});
  }
};

const PayButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: 901px) {
    display: flex;
    justify-content: flex-end;
  }
`;


const mapStateToProps = state => {
  return {
    PayInvoices: state.PayInvoices
  };
};

const mapDispatchToProps = dispatch => {
  return {
    globalDispatch: dispatch
  };
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(PBPTable);