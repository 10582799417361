import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    padding: '6px 8px'
  }
});

export const InsuranceInfoButton = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Button variant="contained" color="primary" className={classes.root} {...props}>
      {children}
    </Button>
  );
};
