import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { FormTextField, FormSelect } from '../../../common';
import states from 'states.json';
import sex from 'sex.json';
import relationship from 'relationship.json';
import toastr from 'toastr';
import {
  requiredValidator,
  zipValidator,
  composeValidators
} from 'sd-formik-validators';
import { useLanguage } from '../../../common';
import AuthService from '../../../../lib/AuthService';
import { formatZip, API_BASE } from '../../../../utilities';
import { AccountCard } from '../AccountCard/AccountCard';
import { AccountCardRow2 } from '../PatientDetailsCard/PatientDetailsCardStyles';
import { CardLabel } from '../AccountCard/CardLabel';
import axios from 'axios';
import { Prompt } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Typography as Label, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const Axios = axios.create({
  timeout: 30000,
  baseURL: API_BASE
});
const FieldSetButtonContainer = styled.div`
  position: absolute;
  top: 1px;
  right: 4px;

  button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  p {
    font-size: 14px;
    text-transform: none;
  }
`;
const DualFieldSetButton = styled.div`
button:nth-child(1) {
    
  border-top: none;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  margin-right: 8px;
}
button:nth-child(2) {
  border-top: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
`;
export const InsuranceCard = ({ data = {}, User, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props.isEditing]);
  const relationships = {
    SELF: '1',
    SPOUSE: '2',
    CHILD: '3',
    OTHER: '4',
    '1': 'Self',
    '2': 'Spouse',
    '3': 'Child',
    '4': 'Other'
  };
  const getRelationshipToPatient = () => {
    const relationshipToPatient = data.relationshipToPatient.toUpperCase();

    return relationships[relationshipToPatient]
      ? relationships[relationshipToPatient]
      : '';
  };
  const handleSubmit = (values, actions) => {
    const auth = new AuthService();
    const decodedJWT = auth.getProfile();
    const token = auth.getToken();
    const payload = {
      ID: decodedJWT.ID,
      SystemName: decodedJWT.SystemName,
      EmpID: decodedJWT.EmpID,
      ReqAction: props.reqAction,
      [props.payloadName]: {
        ...values
      }
    };
    Axios.post(
      `/DoctorBill/Insurance`,
      {
        ...payload
      },
      {
        headers: {
          user_token: token,
          Pragma: 'no-cache'
        }
      }
    )
      .then(response => {
        const { status } = response;
        if (status === 200) {
          alert(
            getLanguageText(
              'Saved successfully. It may take up to one billing cycle for changes to be reflected.'
            )
          );
          actions.resetForm({
            PlanName: data.planName || '',
            GroupID: data.groupID || '',
            PolicyID: data.policyID || '',
            CompanyAddress: data.companyAddress || '',
            CompanyCity: data.companyCity || '',
            CompanyState: data.companyState || '',
            CompanyZip: data.companyZip || '',
            RelationshipToPatient: data.relationshipToPatient
              ? getRelationshipToPatient()
              : '',
            InsuredFirstName: data.insuredFirstName || "",
            InsuredLastName: data.insuredLastName || "",
            InsuredSex: data.insuredSex || '',
            InsuredAddress: data.insuredAddress || '',
            InsuredCity: data.insuredCity || '',
            InsuredState: data.insuredState || '',
            InsuredZip: data.insuredZip || '',
            EmployerName: data.employerName || '',
            InsuredBirthDate: data.insuredBirthDate
              ? moment(new Date(data.insuredBirthDate)).format('MM/DD/YYYY')
              : ''
          });
          setIsEditing(false);
        }
      })
      .catch(error => {
        toastr.error(getLanguageText('We were not able to save your updates.'));
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
  const { getLanguageText } = useLanguage();
  return (
    <AccountCard legend={props.legend}>
      <Formik
        id={`${props.ordinalNumberID}-insurance-formik`}
        enableReinitialize={true}
        initialValues={{
          PlanName: data.planName || '',
          GroupID: data.groupID || '',
          PolicyID: data.policyID || '',
          CompanyAddress: data.companyAddress || '',
          CompanyCity: data.companyCity || '',
          CompanyState: data.companyState || '',
          CompanyZip: data.companyZip || '',
          RelationshipToPatient: data.relationshipToPatient
            ? getRelationshipToPatient()
            : '',
          InsuredFirstName: data.insuredFirstName || '',
          InsuredLastName: data.insuredLastName || '',
          InsuredSex: data.insuredSex || '',
          InsuredAddress: data.insuredAddress || '',
          InsuredCity: data.insuredCity || '',
          InsuredState: data.insuredState || '',
          InsuredZip: data.insuredZip || '',
          EmployerName: data.employerName || '',
          InsuredBirthDate: data.insuredBirthDate
            ? moment(new Date(data.insuredBirthDate)).format('MM/DD/YYYY')
            : ''
        }}
        onSubmit={handleSubmit}
        render={({ dirty, values, isSubmitting, isValidating, resetForm }) => {
          return (
            <Form
              style={{ marginTop: '12px' }}
              id={`${props.ordinalNumberID}-insurance-form`}
            >
              <Prompt
                when={dirty}
                message={getLanguageText(
                  'You are about to navigate away from this page that has unsaved changes. Click OK to continue.'
                )}
                id="form-leaving-prompt"
              />
              <AccountCardRow2>
                {isEditing ? (
                  <Field name="PlanName" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText('Insurance Provider')}*`}
                        id={`account-txt-${props.ordinalNumberID}-insurance-plan-name`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Insurance Provider')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-plan-name-label`}
                  >
                    {values.PlanName}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <AccountCardRow2>
                {isEditing ? (
                  <Field name="GroupID">
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={getLanguageText('Group ID')}
                        id={`account-txt-${props.ordinalNumberID}-insurance-group-id`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Group ID')}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-group-id-label`}
                  >
                    {values.GroupID}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field name="PolicyID" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText('Policy ID')}*`}
                        id={`account-txt-${props.ordinalNumberID}-insurance-policy-id`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Policy ID')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-policy-id-label`}
                  >
                    {values.PolicyID}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <AccountCardRow2>
                {isEditing ? (
                  <Field name="CompanyAddress" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText('Address')}*`}
                        id={`edit-${props.ordinalNumberID}-insurance-address`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Address')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-address-label`}
                  >
                    {values.CompanyAddress}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field name="CompanyCity" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText('City')}*`}
                        id={`account-txt-${props.ordinalNumberID}-insurance-comp-city`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('City')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-comp-city-label`}
                  >
                    {values.CompanyCity}
                  </CardLabel>
                )}
                {/* </AccountCardRow2>
              <AccountCardRow2> */}
                {isEditing ? (
                  <Field name="CompanyState" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormSelect
                        field={field}
                        form={form}
                        data={states}
                        label={`${getLanguageText('State')}*`}
                        id={`account-select-${props.ordinalNumberID}-insurance-comp-state`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('State')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-comp-state-label`}
                  >
                    {values.CompanyState
                      ? states.find(obj => obj.code === values.CompanyState) &&
                        states.find(obj => obj.code === values.CompanyState)
                          .name
                      : ''}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field
                    name="CompanyZip"
                    validate={composeValidators(
                      requiredValidator,
                      zipValidator
                    )}
                  >
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText('Zip Code')}*`}
                        id={`account-txt-${props.ordinalNumberID}-insurance-comp-zip`}
                        onChange={e =>
                          form.setFieldValue(
                            field.name,
                            formatZip(e.target.value),
                            true
                          )
                        }
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Zip Code')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-comp-zip-label`}
                  >
                    {values.CompanyZip}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <div
                style={{
                  height: '2px',
                  background: 'lightgrey',
                  marginBottom: '6px'
                }}
              />
              <AccountCardRow2>
                {isEditing ? (
                  <Field name="InsuredLastName" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText("Insured's Last Name")}*`}
                        id={`account-txt-${props.ordinalNumberID}-insurance-Insured-last-name`}
                        autoComplete={'new-password'}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText("Insured's Last Name")}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-Insured-last-name-label`}
                  >
                    {values.InsuredLastName}
                  </CardLabel>
                )}
                 {isEditing ? (
                  <Field name="InsuredFirstName" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={`${getLanguageText("Insured's First Name")}*`}
                        id={`account-txt-${props.ordinalNumberID}-insurance-Insured-first-name`}
                        autoComplete={'new-password'}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText("Insured's First Name")}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-Insured-first-name-label`}
                  >
                    {values.InsuredFirstName}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field name="RelationshipToPatient">
                    {({ field, form }) => (
                      <FormSelect
                        field={field}
                        form={form}
                        data={relationship}
                        label={getLanguageText(
                          'Patient Relationship to Insured'
                        )}
                        id={`account-txt-${props.ordinalNumberID}-insurance-relationship`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText(
                      'Patient Relationship to Insured'
                    )}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-relationship-label`}
                  >
                    {relationships[values.RelationshipToPatient]}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <AccountCardRow2>
                {isEditing &&
                //If the relationship is other than self show text field.
                (values.RelationshipToPatient !== '1' ||
                  // If the user puts the relationship to self and there was no birth date to begin with then I need to show an edit field
                  (values.RelationshipToPatient === '1' && !data.BirthDate) ||
                  // If the user puts the relationship to self and has a birth date then I need to show a read only label of birth date.
                  !(values.RelationshipToPatient === '1' && data.BirthDate)) ? (
                  <Field
                    name="InsuredBirthDate"
                    validate={composeValidators(
                      requiredValidator,
                      value => {
                        const now = moment();
                        if (!moment(value).isValid()) {
                          return undefined;
                        }
                        return now.diff(value) > 0
                          ? undefined
                          : getLanguageText('Invalid DOB');
                      },
                      value => {
                        return moment(value).isValid()
                          ? undefined
                          : getLanguageText('Invalid DOB');
                      }
                    )}
                  >
                    {({ field, form }) => (
                      <InputMask
                        mask="99/99/9999"
                        id="login-mask-dob"
                        {...field}
                      >
                        {inputProps => {
                          return (
                            <FormTextField
                              {...inputProps}
                              field={field}
                              form={form}
                              label={`${getLanguageText('Date of Birth')}*`}
                              helperText={
                                (form.touched[field.name] &&
                                form.errors[field.name]
                                  ? getLanguageText(form.errors[field.name])
                                  : '') || 'mm/dd/yyyy'
                              }
                              id={`account-txt-${props.ordinalNumberID}-insurance-birth-date`}
                            />
                          );
                        }}
                      </InputMask>
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Date of Birth')}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-birth-date-label`}
                  >
                    {values.InsuredBirthDate}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field name="InsuredSex" validate={requiredValidator}>
                    {({ field, form }) => (
                      <FormSelect
                        field={field}
                        form={form}
                        data={sex}
                        label={`${getLanguageText("Insured's Sex")}*`}
                        id={`account-select-${props.ordinalNumberID}-insurance-patient-sex`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText("Insured's Sex")}*`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-patient-sex-label`}
                  >
                    {values.InsuredSex
                      ? sex.find(obj => obj.code === values.InsuredSex) &&
                        sex.find(obj => obj.code === values.InsuredSex).name
                      : ''}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <AccountCardRow2>
                {isEditing ? (
                  <Field name="InsuredAddress">
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={getLanguageText(`Insured's Address`)}
                        id={`account-txt-${props.ordinalNumberID}-insurance-ins-address`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText(`Insured's Address`)}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-ins-address-label`}
                  >
                    {values.InsuredAddress}
                  </CardLabel>
                )}
                {/* </AccountCardRow2>
              <AccountCardRow2> */}
                {isEditing ? (
                  <Field name="InsuredCity">
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={getLanguageText('City')}
                        id={`account-txt-${props.ordinalNumberID}-insurance-ins-city`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('City')}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-ins-city-label`}
                  >
                    {values.InsuredCity}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field name="InsuredState">
                    {({ field, form }) => (
                      <FormSelect
                        field={field}
                        form={form}
                        label={getLanguageText('State')}
                        data={states}
                        id={`account-select-${props.ordinalNumberID}-insurance-ins-state`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('State')}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-ins-state-label`}
                  >
                    {values.InsuredState
                      ? states.find(obj => obj.code === values.InsuredState) &&
                        states.find(obj => obj.code === values.InsuredState)
                          .name
                      : ''}
                  </CardLabel>
                )}
                {isEditing ? (
                  <Field name="InsuredZip" validate={zipValidator}>
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        onChange={e =>
                          form.setFieldValue(
                            field.name,
                            formatZip(e.target.value),
                            true
                          )
                        }
                        form={form}
                        label={getLanguageText('Zip Code')}
                        id={`account-txt-${props.ordinalNumberID}-insurance-ins-zip`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText('Zip Code')}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-ins-zip-label`}
                  >
                    {values.InsuredZip}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <div
                style={{
                  height: '2px',
                  background: 'lightgrey',
                  marginBottom: '12px'
                }}
              />
              <AccountCardRow2>
                {isEditing ? (
                  <Field name="EmployerName">
                    {({ field, form }) => (
                      <FormTextField
                        field={field}
                        form={form}
                        label={getLanguageText("Insured's Employer")}
                        id={`account-txt-${props.ordinalNumberID}-insurance-employer`}
                      />
                    )}
                  </Field>
                ) : (
                  <CardLabel
                    labelSubject={`${getLanguageText("Insured's Employer")}`}
                    labelID={`account-txt-${props.ordinalNumberID}-insurance-employer-label`}
                  >
                    {values.EmployerName}
                  </CardLabel>
                )}
              </AccountCardRow2>
              <FieldSetButtonContainer>
                {isEditing ? (
                  <DualFieldSetButton>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        margin: 0
                      }}
                      id={`account-btn-edit-${props.ordinalNumberID}-insurance-cancel`}
                      onClick={() => {
                        setIsEditing(false);
                        resetForm({
                          PlanName: data.planName || '',
                          GroupID: data.groupID || '',
                          PolicyID: data.policyID || '',
                          CompanyAddress: data.companyAddress || '',
                          CompanyCity: data.companyCity || '',
                          CompanyState: data.companyState || '',
                          CompanyZip: data.companyZip || '',
                          RelationshipToPatient: data.relationshipToPatient
                            ? getRelationshipToPatient()
                            : '',

                          InsuredFirstName: data.insuredFirstName || '',
                          InsuredLastName: data.insuredLastName || '',
                          InsuredSex: data.insuredSex || '',
                          InsuredAddress: data.insuredAddress || '',
                          InsuredCity: data.insuredCity || '',
                          InsuredState: data.insuredState || '',
                          InsuredZip: data.insuredZip || '',
                          EmployerName: data.employerName || '',
                          InsuredBirthDate: data.insuredBirthDate
                            ? moment(new Date(data.insuredBirthDate)).format(
                                'MM/DD/YYYY'
                              )
                            : ''
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={'times'}
                        style={{ marginRight: '8px' }}
                      />
                      <Label>
                        <strong>{getLanguageText('Cancel')}</strong>
                      </Label>
                    </Button>
                    <Button
                    variant="contained"
                    color="primary"
                    style={{
                      margin: 0
                    }}

                      type="submit"
                      disabled={isSubmitting || isValidating || !dirty}
                      id={`account-btn-edit-${props.ordinalNumberID}-insurance-save`}
                    >
                      {!(isSubmitting || isValidating) && (
                        <FontAwesomeIcon
                          icon={'save'}
                          style={{ marginRight: '8px' }}
                        />
                      )}
                      {(isSubmitting || isValidating) && (
                        <CircularProgress size={20} />
                      )}
                      <Label>
                        <strong>{getLanguageText('Save')}</strong>
                      </Label>
                    </Button>
                  </DualFieldSetButton>
                ) : (
                  <Button
                  variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    id={`account-btn-edit-${props.ordinalNumberID}-insurance-update`}
                  >
                    <FontAwesomeIcon
                      icon={'pencil-alt'}
                      style={{ marginRight: '8px' }}
                    />
                    <Label>
                      <strong>{getLanguageText('Update')}</strong>
                    </Label>
                  </Button>
                )}
              </FieldSetButtonContainer>
            </Form>
          );
        }}
      />
    </AccountCard>
  );
};
