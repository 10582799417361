import styled from 'styled-components';

export const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: rgb(243, 242, 241);
  div {
    display: flex;
  }
`;
