import React from 'react';
import styled from 'styled-components';
import { isNonProductionEnv, formatMoney } from '../../utilities';
import { Button, Typography as Label } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLanguage } from '.';
import { theme } from 'lib/theme';

const UserInformationWrapper = styled.div`
  display: flex;
  height: 58px;
  max-height: 58px;
  padding: 28px 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid ${theme.palette.divider};
  .MuiButtonBase-root {
    background-color: #abab25;
  }
  a {
    text-decoration: none;
  }
  p {
    font-weight: 500;
  }
  button {
    margin-left: 10px;
    white-space: nowrap;
    color: black;
  }
  @media screen and (min-width: 890px) {
    padding: 10px 15px;
    button {
      white-space: nowrap;
      color: black;
      &:hover {
        color: white;
        background-color: #415463;
      }
    }
  }
`;

export const UserInformation = ({
  User,
  hidePayButton = false,
  showLabelOnly = false,
  ...props
}) => {
  const { getLanguageText } = useLanguage();
  return (
    <UserInformationWrapper {...props}>
      <Label id="user-account-info">
        {getLanguageText('Account Number')}:{' '}
        {
          <span
            style={isNonProductionEnv() ? { color: 'red' } : { color: 'black' }}
            id="user-display-account-number"
          >{` ${User ? User.displayAccountNumber : null}`}</span>
        }
      </Label>

      {!hidePayButton && User && User.balance >= 5 && !showLabelOnly && (
        <Link to="/Account" id="account-btn-pay-link">
          <Button variant="contained" color="primary" id="account-btn-pay">
            {`${getLanguageText('Pay')} $${formatMoney(User.balance)}`}
          </Button>
        </Link>
      )}
      {!hidePayButton &&
        User &&
        User.inCollections &&
        User.collectionBalance >= 5 &&
        !showLabelOnly && (
          <Link to="/Account" id="account-btn-pay-link">
            <Button variant="contained" color="primary" id="account-btn-pay">
              {`${getLanguageText('Pay')} $${formatMoney(
                User.collectionBalance
              )}`}
            </Button>
          </Link>
        )}
      {!hidePayButton && showLabelOnly && !User.inCollections && (
        <Label id="user-account-balance">{`${getLanguageText(
          'Balance Owed'
        )}: $${formatMoney(User.balance)}`}</Label>
      )}
      {!hidePayButton && showLabelOnly && User.inCollections && (
        <Label id="user-account-collection-balance">{`${getLanguageText(
          'Balance Owed'
        )}: $${formatMoney(User.collectionBalance)}`}</Label>
      )}
    </UserInformationWrapper>
  );
};
