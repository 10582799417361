import styled from 'styled-components';

const UserInformationRows = styled.div`
  padding: 24px 15px;
  @media screen and (min-width: 890px) {
    padding: 10px 15px;
    button {
      white-space: nowrap;
    }
  }
`;
const InsuranceButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 890px) {
    justify-content: center;
    width: 100%;
  }
`;
const AccountDetailContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
`;

const ContentArea = styled.div`
  display: flex;
  height: auto;
  min-height: 100vh;
  flex-direction: column;
`;

const AccountDetailsContent = styled.div`
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  legend {
    line-height: 0;
  }
  fieldset {
    border-radius: 5px;
    height: 100%;
  }

  a {
    text-decoration: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  .account-card {
    margin: 0 0 24px 0;
  }

  @media screen and (min-width: 890px) {
    flex-direction: row;
    flex: 2 0 0;
    width: 100%;
    margin: 0 0 24px 0;
    .account-card {
      margin: 0 24px 0 0;
    }
  }
`;
const LoadingContainer = styled.div`
  top: 0;
  bottom: 0;
  left: 200px;
  right: 200px;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  height: 100vh;
  width: 100%;

  p {
    font-size: 24px;
    white-space: nowrap;
  }
`;
const Divider = styled.div`
  height: 2px;
  background: grey;
  margin-bottom: 12px;
`;

const OpacityBlock = styled.div`
  position: absolute;
  left: 200px;
  right: 200px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  p {
    font-size: 24px;
    white-space: nowrap;
  }
`;

const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: rgb(243, 242, 241);
  border: 1px solid rgba(0, 0, 0, 0.5);
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div:last-child {
    display: block;
    margin-top: 10px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }
`;
const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
`;
const DTContainer = styled.div``;
const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  width: 100%;
  height: 100%;
`;
const HistoryContent = styled.div`
  ${DTContainer} {
    display: none;
  }

  @media screen and (min-width: 960px) {
    ${DTContainer} {
      display: flex;
      flex-direction: column;
    }

    ${MobileContainer} {
      display: none;
    }
  }
`;

export {
  AccountDetailsContent,
  Row,
  ContentArea,
  AccountDetailContainer,
  UserInformationRows,
  LoadingContainer,
  InsuranceButtonWrapper,
  Divider,
  MobileContainer,
  OpacityBlock,
  DataCard,
  CardListContainer,
  HistoryContent,
  DTContainer
};
