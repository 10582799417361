import toastr from 'toastr';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Header, SideNav } from '..';
import { connect } from 'react-redux';
import {
  Typography as Label,
  TextField,
  FormControl,
  makeStyles,
  FormHelperText
} from '@material-ui/core';
import Axios from 'axios';
import { API_BASE, isEmail, setTabTitle } from '../../utilities';
import {
  useMaterialSubmitButton,
  UserInformation,
  useLanguage
} from '../common';
import AuthService from '../../lib/AuthService';
import {
  PageTitle,
  ContentArea,
  Divider,
  ContactContainer,
  Container,
  EmailForm,
  AccountNumber,
  ContactGroup,
  InlineInputGroup,
  MessageInputGroup,
  MessageTextArea,
  ContactContent
} from './ContactStyles';

const useStyles = makeStyles({
  errorFormHelperText: {
    marginBottom: '12px',
    color: 'red',
    fontWeight: 300
  }
});
function Contact({ User }) {
  const classes = useStyles();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitState, submitActions, SubmitBtn] = useMaterialSubmitButton();
  const { getLanguageText, language } = useLanguage();
  const [formErrors, setFormErrors] = useState({
    email: false,
    message: false
  });
  useEffect(() => {
    setTabTitle('Contact Us');
  }, []);
  useEffect(() => {
    setEmail(User && User.useEmail ? User.email : '');
  }, [User]);
  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }
  function changeEmailHandler(e) {
    const { value } = e.target;
    if (isEmail(value) || value === '') {
      setFormErrors({
        ...formErrors,
        email: false
      });
    } else {
      setFormErrors({
        ...formErrors,
        email: true
      });
    }
    setEmail(value);
  }
  function changeMessageHandler(e) {
    const { value } = e.target;
    if (value === '') {
      setFormErrors({
        ...formErrors,
        message: true
      });
    } else {
      setFormErrors({
        ...formErrors,
        message: false
      });
    }
    setMessage(value);
  }
  function handleSubmit() {
    // console.log(email);
    if (!isEmail(email) || email.trim() === '') {
      setFormErrors({
        ...formErrors,
        email: true
      });
      return;
    }
    if (message === '') {
      setFormErrors({
        ...formErrors,
        message: true
      });
      return;
    } else {
      setFormErrors({
        ...formErrors,
        message: false
      });
    }

    // console.log(id, email, message)
    const auth = new AuthService();
    const user_token = auth.getToken();
    const decodedJWT = auth.getProfile();
    const { ID } = decodedJWT;
    const requestBody = {
      EncounterID: ID,
      PatientEmail: email,
      Acct: ID,
      Message: message,
      PatEmail: email
    };
    // console.log(requestBody)
    submitActions.startLoading();
    Axios.post(`${API_BASE}/DoctorBill/ContactUs`, requestBody, {
      headers: {
        user_token,
        Pragma: 'no-cache'
      }
    })
      .then(response => {
        const { data } = response;
        toastr.success(getLanguageText('Submitted Successfully.'));
        setEmail('');
        setMessage('');
        console.log(data);
      })
      .catch(err => {
        console.log(err);
        toastr.error(
          getLanguageText('Sorry message was not sent, please try again.')
        );
      })
      .finally(() => {
        submitActions.endLoading();
      });
  }

  return (
    <ContentArea>
      <Header toggleMobileNav={toggleMobileNav} />
      <ContactContainer>
        <SideNav showMobileNav={showMobileNav} />
        <ContactContent showMobileNav={showMobileNav}>
          <UserInformation User={User} />

          <Container>
            <ContactGroup>
              <PageTitle>{getLanguageText('Contact Us')}</PageTitle>
              <Label>
                <strong>
                  <img
                    src={require('../../assets/img/phone-icon.png')}
                    alt=""
                  />{' '}
                  {getLanguageText('Phone')}:
                </strong>{' '}
                {User.facPhone &&
                  User.facPhone.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1)$2-$3')}
              </Label>
            </ContactGroup>
            <Divider />
            <ContactGroup>
              <Label>
                <strong>
                  <img src={require('../../assets/img/mail-icon.png')} alt="" />{' '}
                  {getLanguageText('Email')}:
                </strong>
              </Label>
              <EmailForm
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <InlineInputGroup style={{ margin: '16px 0 16px 0' }}>
                  <Label className="label">
                    <strong>
                      <label htmlFor="contact-email">
                        {language === 'en' ? 'From' : 'De'}:
                      </label>
                    </strong>
                  </Label>
                  <FormControl>
                    <TextField
                      name="email"
                      id="contact-email"
                      error={!!formErrors.email}
                      placeholder={getLanguageText('Your Email')}
                      onChange={changeEmailHandler}
                      value={email}
                    />
                    {formErrors.email && (
                      <FormHelperText
                        id="contact-email-invalid-msg"
                        className={classes.errorFormHelperText}
                      >
                        {getLanguageText('Please enter a valid email address.')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </InlineInputGroup>
                <AccountNumber>
                  <strong id={'subject-label'}>
                    {getLanguageText('Subject')}:{' '}
                    <span id="subject-number">{User.id}</span>
                  </strong>
                </AccountNumber>
                <FormControl style={{ marginBottom: '10px' }}>
                  <strong style={{ marginBottom: '12px' }}>
                    <label htmlFor="contact-txt-message">
                      {getLanguageText('Message')}:
                    </label>
                  </strong>
                  <MessageTextArea
                    multiline
                    variant="outlined"
                    error={!!formErrors.message}
                    style={{ width: '100%' }}
                    onChange={changeMessageHandler}
                    value={message}
                    id="contact-txt-message"
                    rows="15"
                  />
                  {formErrors.message && (
                    <FormHelperText
                      className={classes.errorFormHelperText}
                      id="message-invalid-error-text"
                    >
                      {getLanguageText('Required field')}
                    </FormHelperText>
                  )}
                </FormControl>
                <SubmitBtn
                  {...submitState}
                  type="submit"
                  id="contact-btn-submit"
                  style={{ marginLeft: 'auto' }}
                >
                  {language === 'en' ? 'Submit' : 'Enviar'}
                </SubmitBtn>
              </EmailForm>
            </ContactGroup>
            <Divider />
            <ContactGroup>
              <Label
                style={{
                  display: 'flex',
                  alignItems: 'flex-end'
                }}
              >
                <img
                  src={require('../../assets/img/pin-icon.png')}
                  alt=""
                  style={{ marginRight: '4px' }}
                />
                <strong>{getLanguageText('Mail')}:</strong>
              </Label>
              <Label id="contact-address-info">
                {User.facName}
                <br />
                {User.facAddress}
                <br />
                {User.facCity} {User.facState} {User.facZipCode}
              </Label>
            </ContactGroup>
          </Container>
        </ContactContent>
      </ContactContainer>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User
});

export default connect(mapStateToProps)(Contact);
