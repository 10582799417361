export const es = {
  'Acct Number/Patient-Last Name/DOB':
    'Numero de Cuenta/Apellido del Paciente/Fecha de Nacimiento',
  Year: 'Año',
  'Invalid DOB': 'Fecha de Nacimiento Inválida',
  Login: 'Iniciar sesión',
  Phone: 'Teléfono',
  All: 'Todo',
  Charge: 'Cargo',
  Open: 'Abrir',
  'Last Name': 'Apellido ',
  'Invoice Number(s)': 'Número(s) de factura',
  'Filter history by date': 'Filtrar historial por fecha',
  'Filter Invoices': 'Filtrar Facturas',
  'Enter a valid account number.': 'Ingrese un número de cuenta válido.',
  'Account number requires 6 or more characters.':
    'El número de cuenta requiere 6 o más caracteres.',
  'Please enter the amount you would like to pay in the Pay Amount column, then click the Pay Button to continue.':
    'Ingrese el monto que desea pagar en la columna Monto de pago, luego haga clic en el botón Pagar para continuar.',
  'Your Email': 'Su Correo Electrónico',
  'Account Number': 'Numero de Cuenta',
  'Saved successfully. It may take up to one billing cycle for changes to be reflected.':
    'Guardado exitosamente. Puede tomar hasta un ciclo de facturación para que se reflejen los cambios.',
  'Saved successfully.': 'Guardado exitosamente.',
  DOB: 'Fecha de Nacimiento',
  'My Invoices': 'Mis Facturas',
  Invoice: 'Factura',
  'Date of Birth': 'Fecha de Nacimiento',
  'Patient DOB': 'Fecha de Nacimiento del Paciente',
  'Date of Service': 'Fecha de Servicio',
  'Date(s) of Service': 'Fecha(s) de Servicio',
  'You must enter an account number.': 'Debe ingresar un número de cuenta.',
  'Enter Amount': 'Ingresar cantidad',
  'Please enter an amount on one or more invoices to continue.':
    'Ingrese un monto en una o más facturas para continuar.',
  'Go Back': 'Volver',
  'Invalid Login.': 'Ingreso Invalido',
  'Account History': 'Historial de Cuenta',
  'Account Information': 'Información de la Cuenta',
  'Account Details': 'Detalles de la Cuenta',
  Address: 'Dirección',
  'Was not able to process transaction.': 'No se pudo procesar la transacción',
  'We were not able to save your updates.':
    'No pudimos guardar tus actualizaciones.',
  'Was not able to update account information at this time. Refresh or come back later.':
    'No se pudo actualizar la información de la cuenta en este momento. Actualizar o volver más tarde.',
  'Address Line 2': 'Dirección linea 2',
  Adjustments: 'Ajustes',
  'Approval Code': 'Código de Aprobación',
  Balance: 'Saldo',
  'Balance Owed': 'Saldo Adeudado',
  'Balance in Collections': 'Saldo en Colecciones',
  'Billing address must be 100 characters or less.':
    ' La dirección de facturación debe tener 100 caracteres o menos',
  'By clicking Save you agree to our':
    'Al hacer clic en "Guardar", acepta nuestra',
  'privacy policy': 'política de privacidad',
  'and to receive all future correspondence and statements via email.':
    'y recibirá toda la correspondencia y declaraciones futuras por correo electrónico',
  'Card Number': 'Número de tarjeta',
  Amount: 'Cantidad',
  Cancel: 'Cancelar',
  'Cardholder Billing Address':
    'Dirección de facturación del titular de la tarjeta',
  Charges: 'Cargos',
  City: 'Ciudad',
  'Contact Us': 'Contáctenos',
  'Current Balance': 'Saldo Actual',
  Male: 'Masculino',
  Female: 'Femenino',
  Self: 'Yo',
  Spouse: 'Esposo(a)',
  Child: 'Hijo',
  Other: 'Otro',
  'Auto Accident': 'Accidente Automovilístico',
  Employment: 'Empleo',
  Illness: 'Enfermedad',
  'Other Accident': 'Otro Accidente',
  'Please Confirm Payment': 'Por favor confirme el pago',
  Description: 'Descripción',
  'Date of Transaction': 'Fecha de Transacción',
  'Date of Service': 'Fecha de Servicio',
  "Don't have Adobe? Get it here.": 'No tienes Adobe? Consiguelo aqui.',
  'Patient Details': 'Detalles del Paciente',
  Email: 'Correo Electrónico',
  'Email Reminder': 'Recordatorio por Correo Electrónico',
  'Email Address': 'Dirección de Correo Electrónico',
  Employer: 'Empleador',
  "Don't have Adobe?": 'No tienes Adobe?',
  'Get it': 'Consiguelo',
  here: 'aqui',
  'File to Insurance': 'Presentar al seguro',
  'Credit Card Payment': 'Pago con Tarjeta de Crédito',
  'Patient Statement': 'Factura del Paciente',
  'Filter History by date': 'Filtrar historial por fecha',
  'Frequently Asked Questions': 'Preguntas Frecuentes',
  'From Date': 'Partir de la Fecha',
  'Group ID': 'Identificación del grupo',
  'Guarantor Name': 'Nombre de la persona encargada',
  'I understand changes may take up to one billing cycle.':
    'Entiendo que los cambios pueden tomar hasta un ciclo de facturación',
  'If Auto Accident / State': 'Si, accidente automovilístico / Estado',
  'If this is correct please click proceed.':
    'Si esto es correcto, haga clic en continuar',
  'Insurance Forms': 'Formularios de Seguros',
  'Insurance Pending': 'Seguro pendiente',
  'Total Balance': 'Balance Total',
  'Patient Due': 'Paciente debido',
  Patient: 'Paciente',
  'Insurance Payments': 'Pagos de Seguros',
  'Insurance Details': 'Detalles del seguro',
  'All Invoices': 'Todas las facturas',
  'No Invoices to Display': 'No hay facturas para mostrar',
  'No Invoices Found to make Payment.':
    'No se encontraron facturas para realizar el pago.',
  Invoices: 'Facturas',
  'Ins. Paid': 'Pagos de seguros',
  'Patient Paid': 'Pagos de paciente',
  'Insurance Payment(s)': 'Pago(s) de Seguros',
  'Insurance Provider': 'Proveedor de Seguros',
  "Insured's First Name": 'Nombre del Asegurado',
  "Insured's Last Name": 'Apellido del Asegurado',
  "Insured's Sex": 'Sexo del Asegurado',
  "Insured's Address": 'Dirección del Asegurado',
  "Insured's Employer": 'Empleador del asegurado',
  Logout: 'Cerrar la sesion',
  Close: 'Cerrar',
  'Minimum Amount': 'Cantidad Minima',
  Outstanding: 'Pendientes',
  'Outstanding Invoices': 'Facturas pendientes',
  Mail: 'Correo',
  Month: 'Mes',
  Message: 'Mensaje',
  'Account Information': 'Información de la cuenta',
  'My Account': 'Mi Cuenta',
  Name: 'Nombre',
  'Name on Card': 'Nombre en la Tarjeta',
  'Opt-in': 'Optar En',
  'Other Amount': 'Otra Cantidad',
  Country: 'País',
  Pay: 'Pagar',
  'Patient Forms': 'Formularios de pacientes',
  'Patient Name': 'Nombre del Paciente',
  'Patient Payments': 'Pagos de Paciente',
  'Patient Payment(s)': 'Pago(s) de Paciente',
  'Patient Relationship to Insured': 'Relación del Paciente con el Asegurado',
  'Patient Services': 'Servicios al Paciente',
  'Pay Amount': 'Cantidad a Pagar',
  'Pay Now': 'Pagar Ahora',
  Reset: 'Reiniciar',
  Responsibility: 'Responsabilidad',
  'Payment Confirmation': 'Confirmación de pago',
  'Please accept that this change may take one billing cycle.':
    'Acepte que este cambio puede llevar un ciclo de facturación',
  'Please email me a copy of my receipt.':
    'Por favor envíeme una copia de mi recibo por correo electrónico.',
  'Please enter a valid email address.':
    'Por favor, introduce una dirección de correo electrónico válida.',
  'Please make sure all required fields "*" are completed.':
    'Asegúrese de completar todos los campos requeridos "*"',
  'Please select a month.': 'Por favor seleccione un mes.',
  'Please select a year.': 'Por favor seleccione un año.',
  'Please select a state.': 'Por favor seleccione un estado.',
  'Please select a country.': 'Por favor seleccione un pais.',
  'Please wait while we load data...':
    'Por favor espere mientras cargamos datos ...',
  'Policy ID': 'Número de identificación de la póliza',
  'Primary Insurance': 'Seguro Primario',
  'Print Receipt': 'Imprima el recibo',
  'Privacy Policy': 'Política de privacidad',
  Proceed: 'Proceder',
  'Required Field': 'Campo requerido',
  Required: 'Requerido',
  Save: 'Guardar',
  'Secondary Insurance': 'Seguro secundario',
  'Sorry message was not sent, please try again.':
    'Lo sentimos, el mensaje no fue enviado, por favor intente nuevamente.',
  State: 'Estado',
  Subject: 'Asunto',
  'Submitted Successfully.': 'Enviado satisfactoriamente',
  'To Date': 'Hasta la fecha',
  'Transaction Amount': 'Cantidad de transacción',
  'Transaction Date': 'Fecha de Transacción',
  'Transaction ID': 'ID de transacción',
  'Type of Visit': 'Tipo de visita',
  Update: 'Actualizar',
  'You are about to charge your credit card $5.00. If this is correct please click proceed':
    'Está a punto de cobrarle a su tarjeta de crédito $ 5.00. Si esto es correcto, haga clic en continuar',
  'You are about to navigate away from this page that has unsaved changes. Click OK to continue.':
    'Estás a punto de navegar lejos de esta página que tiene cambios sin guardar. Haga clic en OK para continuar .',
  'Your payment was made successfully': 'Su pago se realizó con éxito.',
  'You are about to charge your credit card':
    'Está a punto de cobrarle a su tarjeta de crédito',
  'Zip Code': 'Código Postal',
  'Not a valid zip code': 'No es un código postal válido',
  'Zip code should be 5 or 9 digits.':
    'El código postal debe tener 5 o 9 dígitos.',
  January: 'Enero',
  February: 'Febrero',
  March: 'Marzo',
  April: 'Abril',
  May: 'Mayo',
  June: 'Junio',
  July: 'Julio',
  August: 'Agosto',
  September: 'Septiembre',
  October: 'Octubre',
  November: 'Noviembre',
  December: 'Diciembre',
  'mm/dd/yyyy': 'mm/dd/aaaa'
};
