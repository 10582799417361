import moment from 'moment';

const HistoryPayload = [
  {
    hisDate: '2012-12-11T10:20:25.717',
    hisAmt: 323.45,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2003-11-10T19:24:10.28',
    hisAmt: 323.45,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2003-11-06T18:13:22.373',
    hisAmt: 323.45,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2003-10-29T18:55:45.02',
    hisAmt: 323.45,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2003-10-23T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'MEDICARE PART B          '
  },
  {
    hisDate: '2003-10-21T18:49:27.483',
    hisAmt: 323.45,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2003-09-25T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'Starbucks                '
  },
  {
    hisDate: '2003-07-07T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'CAREFIRST BC/BS STANDARD '
  },
  {
    hisDate: '2002-08-16T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'AARP SECONDARY           '
  },
  {
    hisDate: '2002-06-23T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'MDIPA                    '
  },
  {
    hisDate: '2002-05-31T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'APWU                     '
  },
  {
    hisDate: '2002-05-08T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'APWU                     '
  },
  {
    hisDate: '2002-04-24T00:00:00',
    hisAmt: 323.45,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2002-03-05T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'CAREFIRST BC/BS STANDARD '
  },
  {
    hisDate: '2002-02-17T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'MEDICARE PART B          '
  },
  {
    hisDate: '2002-02-12T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'MEDICARE PART B          '
  },
  {
    hisDate: '2002-01-09T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'GALLAGHER BASSETT SVCS   '
  },
  {
    hisDate: '2001-12-26T00:00:00',
    hisAmt: 323,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2001-12-21T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'GALLAGHER BASSETT SVCS   '
  },
  {
    hisDate: '2001-12-13T00:00:00',
    hisAmt: 323,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2001-12-02T00:00:00',
    hisAmt: 323,
    actDesc: 'Yellow Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2001-10-18T00:00:00',
    hisAmt: 323,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2001-10-17T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'GALLAGHER BASSETT SVCS   '
  },
  {
    hisDate: '2001-10-02T00:00:00',
    hisAmt: 323,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2001-10-01T00:00:00',
    hisAmt: 323.45,
    actDesc: 'File insurance',
    iPlan: 'GALLAGHER BASSETT SVCS   '
  },
  {
    hisDate: '2001-09-21T00:00:00',
    hisAmt: 323,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  },
  {
    hisDate: '2001-08-28T00:00:00',
    hisAmt: 323,
    actDesc: 'White Bills',
    iPlan: 'Bill Sent to Guarantor'
  }
];

export const AccountInfo = (state = {}, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_INFO': {
      const { update } = action;
      // console.log(update);
      const { ...Info } = update[0];
      // console.log(Info);

      if (Info.primaryInsurance) {
        const {
          dateOfBirth,
          primaryInsurance: { birthDate: primaryBirthDate }
        } = Info;
        Info.dateOfBirth = moment(dateOfBirth).format('MM/DD/YYYY');
        Info.primaryInsurance.birthDate = !!primaryBirthDate
          ? moment(primaryBirthDate).format('MM/DD/YYYY')
          : '';
      } else {
        Info.primaryInsurance = {};
      }
      if (Info.secondaryInsurance) {
        const {
          secondaryInsurance: { birthDate: secondaryBirthDate }
        } = Info;
        Info.secondaryInsurance.birthDate = !!secondaryBirthDate
          ? moment(secondaryBirthDate).format('MM/DD/YYYY')
          : '';
      } else {
        Info.secondaryInsurance = {};
      }

      return Info;
    }
    case 'CLEAR_DATA': {
      return {};
    }
    default:
      return state;
  }
};

export const AccountHistory = (state = [], action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_HISTORY': {
      const { update } = action;

      if (!update) {
        return HistoryPayload.map(hist => ({
          ...hist,
          formattedDate: moment(hist.hisDate).format('MM/DD/YYYY')
        }));
      }

      return update.map(hist => ({
        ...hist,
        formattedDate: moment(hist.hisDate).format('MM/DD/YYYY')
      }));
    }

    case 'CLEAR_DATA': {
      return [];
    }

    default:
      return state;
  }
};
