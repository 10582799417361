/* // Test Data
const initialState = {
  collectionBalance: 0,
  inCollections: false,
  id: '3581808050001',
  sourceSystem: 'MGA',
  empID: 0,
  lastName: 'USER',
  firstName: 'TEST',
  guarantor: 'TEST GUARANTOR',
  homePhone: '1234567890',
  birthDate: '1970-09-30T00:00:00',
  birthDateFormatted: '09/30/1970',
  sex: 'M',
  email: '',
  useEmail: false,
  employmentStatus: '',
  autoAccidentState: '',
  maritalStatus: '',
  patientCondition: null,
  address: '123 TEST DR',
  city: 'TEST CITY',
  state: 'FL',
  zipCode: '12345-123',
  facName: 'Test Facility LLC',
  facAddress: 'PO Box 12345',
  facCity: 'Gainesville',
  facState: 'FL',
  facZipCode: '12345-123',
  facPhone: '1234567890',
  dateOfService: '2018-08-04T00:00:00',
  charge: 1000,
  pmtInsco: 0,
  pmtPat: 0,
  pmtMCR: 0,
  adj: 0,
  balance: 1000,
  pins: 0,
  sins: 0,
  reqAction: 0,
  nonUsaddress: null,
  divisions: [
    {
      client: 'Single Invoice Provider',
      clientID: 111,
      invoiceBalance: 500,
      patientSelfPayBalance: 500,
      invoices: [
        {
          primaryInsurance: null,
          secondaryInsurance: null,
          invoiceID: '111000001',
          gid: null,
          grp: null,
          dos: '2018-08-04T00:00:00',
          dosFormatted: '08-04-2018',
          facilityName: 'Test Facility',
          providerName: 'Single Invoice Provider',
          divisionClient: null,
          divisionClientID: null,
          location: null,
          fsc: 'SELF PAY',
          chargeTotal: 500,
          balanceOwedTotal: 500,
          adjustmentsTotal: 0,
          patientPaidTotal: 0,
          insurancePaidTotal: 0,
          isSelfPay: true,
          responsibleParty: 'Patient',
          patientSelfPayBalance: 500
        }
      ]
    },
    {
      client: 'Multiple Invoices Provider',
      clientID: 222,
      invoiceBalance: 2000,
      patientSelfPayBalance: 2000,
      invoices: [
        {
          primaryInsurance: null,
          secondaryInsurance: null,
          invoiceID: '222000001',
          gid: '123804',
          grp: 'Grp - 3 Gottlieb [GOTTLTEST]',
          dos: '2021-09-18T00:00:00',
          dosFormatted: '09-18-2021',
          facilityName: 'St. Francis Medical Center',
          providerName: 'Test Provider',
          divisionClient: null,
          divisionClientID: null,
          location: 'EMERGENCY ROOM - HOSPITAL',
          fsc: 'SELF PAY',
          chargeTotal: 500,
          balanceOwedTotal: 500,
          adjustmentsTotal: 0,
          patientPaidTotal: 0,
          insurancePaidTotal: 0,
          isSelfPay: true,
          responsibleParty: 'Patient',
          patientSelfPayBalance: 500,
          isCollections: false
        },
        {
          primaryInsurance: null,
          secondaryInsurance: null,
          invoiceID: '222000002',
          gid: '123804',
          grp: 'Grp - 3 Gottlieb [GOTTLTEST]',
          dos: '2021-09-18T00:00:00',
          dosFormatted: '09-18-2021',
          facilityName: 'St. Francis Medical Center',
          providerName: 'Test Provider',
          divisionClient: null,
          divisionClientID: null,
          location: 'EMERGENCY ROOM - HOSPITAL',
          fsc: 'SELF PAY',
          chargeTotal: 1000,
          balanceOwedTotal: 1000,
          adjustmentsTotal: 0,
          patientPaidTotal: 0,
          insurancePaidTotal: 0,
          isSelfPay: true,
          responsibleParty: 'Patient',
          patientSelfPayBalance: 1000,
          isCollections: false
        },
        {
          primaryInsurance: null,
          secondaryInsurance: null,
          invoiceID: '222000003',
          gid: '123804',
          grp: 'Grp - 3 Gottlieb [GOTTLTEST]',
          dos: '2021-09-18T00:00:00',
          dosFormatted: '09-18-2021',
          facilityName: 'St. Francis Medical Center',
          providerName: 'Test Provider',
          divisionClient: null,
          divisionClientID: null,
          location: 'EMERGENCY ROOM - HOSPITAL',
          fsc: 'SELF PAY',
          chargeTotal: 500,
          balanceOwedTotal: 500,
          adjustmentsTotal: 0,
          patientPaidTotal: 0,
          insurancePaidTotal: 0,
          isSelfPay: true,
          responsibleParty: 'Patient',
          patientSelfPayBalance: 500,
          isCollections: false
        }
      ]
    },
    {
      invoices: [],
      client: 'No Invoices Provider',
      clientID: 1169,
      invoiceBalance: 0,
      patientSelfPayBalance: 0
    }
  ],
  systemArea: null,
  inquiryToken: '',
  inquiryCommandID: '0',
  dob: 'FWeCY7V41h3SyWhWc0Sidw==',
  displayAccountNumber: '3581808050001 - MGA',
  centricityGroup: null,
  centricityGID: null,
  centricityDivisionClientID: null,
  isSelfPay: true,
  responsibleParty: 'Patient',
  systemName: 'MGA',
  patientSelfPayBalance: 0,
  InactivityTimeoutInMinutes: '30'
};
*/
const initialState = {};
export const User = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER': {
      const { user } = action;
      return user;
    }
    case 'CLEAR_DATA': {
      return {};
    }
    default:
      return state;
  }
};
