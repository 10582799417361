import styled from 'styled-components';

const AccountCardRow = styled.div`
  display: flex;
  width: 100%;
  & > *:first-child {
    padding-left: 0;
  }
  & > * {
    width: 100%;
    padding-left: 12px;
  }
  margin-bottom: 24px;
`;
const AccountCardRow3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > * {
    width: 23%;
    min-width: 150px;
    min-height: 30px;
    margin: 8px 4px;
  }
`;
const AccountCardRow2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > * {
    width: 25%;
    min-width: 150px;
    min-height: 44px;
    margin: 4px 8px;
  }
`;

export { AccountCardRow, AccountCardRow2, AccountCardRow3 };
