import React from 'react';
import styled from 'styled-components';
import { DataCard } from 'components/Account/Account/AccountStyles';

const Container = styled.div`
  display: none;
  @media screen and (max-width: ${props =>
      String(props.mobileViewMaxWidth + 'px')}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const MobileDataCard = styled(DataCard)`
  &:nth-child(odd) {
    background-color: rgb(243, 242, 241);
  }
  &:nth-child(even) {
    background: white;
  }
`;
export const MobilePBPTableAlternative = ({
  rows,
  columns,
  prepareRow,
  mobileProps,
  mobileViewMaxWidth,
  ...props
}) => {
  return (
    <Container
      {...mobileProps}
      mobileViewMaxWidth={mobileViewMaxWidth}
      {...props}
    >
      {rows.map(
        (row, i) =>
          prepareRow(row) || (
            <MobileDataCard
              {...row.getRowProps()}
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '400px',
                width: '100%'
              }}
            >
              {row.cells.map(cell => {
                return (
                  <div
                    {...cell.getCellProps()}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                      border: '1px solid lightgrey',
                      padding: '4px'
                    }}
                  >
                    {
                      <span style={{ fontWeight: 700 }}>
                        {cell.column.Header}:
                      </span>
                    }
                    {React.cloneElement(cell.render('Cell'), {
                      isMobile: true,
                      style: {}
                    })}
                  </div>
                );
              })}
            </MobileDataCard>
          )
      )}
    </Container>
  );
};
