export const Months = [
  { numCode: '01', name: 'January' },
  { numCode: '02', name: 'February' },
  { numCode: '03', name: 'March' },
  { numCode: '04', name: 'April' },
  { numCode: '05', name: 'May' },
  { numCode: '06', name: 'June' },
  { numCode: '07', name: 'July' },
  { numCode: '08', name: 'August' },
  { numCode: '09', name: 'September' },
  { numCode: '10', name: 'October' },
  { numCode: '11', name: 'November' },
  { numCode: '12', name: 'December' }
];

export const States = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const Countries = ['United States'];
