import React, { useEffect } from 'react';
import { useState } from 'react';
import { Header, SideNav } from '..';
import { connect } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { setTabTitle } from '../../utilities';

import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { UserInformation, useLanguage } from '../common';

import {
  ContentArea,
  FormsContainer,
  DesktopContainer,
  FormContent,
  MediaQueryAdobe
} from './FormsStyles';

function Forms({ User }) {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { getLanguageText } = useLanguage();
  useEffect(() => {
    setTabTitle('Patient Forms');
  }, []);
  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  return (
    <ContentArea>
      <Header toggleMobileNav={toggleMobileNav} />
      <FormsContainer>
        <SideNav showMobileNav={showMobileNav} />
        <FormContent showMobileNav={showMobileNav}>
          <UserInformation User={User} />
          <DesktopContainer>
            <h2>{getLanguageText('Patient Forms')}</h2>
            <a
              href={require('../../assets/docs/AuthForm.pdf')}
              rel="noopener noreferrer"
              target="_blank"
              id="forms-link-phi-form-desktop"
            >
              Protected Health Insurance (PHI) Authorization
              <Icon icon={faFilePdf} size="2x" />
            </a>
            <MediaQueryAdobe>
              <h4>
                {getLanguageText("Don't have Adobe?")}{' '}
                {getLanguageText('Get it')}{' '}
                <a
                  href="https://get.adobe.com/reader/"
                  target="_blank"
                  id="forms-link-adobe-reader"
                  rel="noopener noreferrer"
                >
                  {getLanguageText('here')}
                </a>
              </h4>
            </MediaQueryAdobe>
          </DesktopContainer>
        </FormContent>
      </FormsContainer>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User
});

export default connect(mapStateToProps)(Forms);
