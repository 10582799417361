const initialState = {
  divisions: {},
  paidClientId: undefined,
  lastModifiedClientId: undefined
};

export const PayInvoices = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_PAY_INVOICE': {
      const { clientID, invoiceID, payAmount, isCollections } = action;
      return {
        ...state,
        divisions: {
          ...state.divisions,
          [clientID]: {
            invoices: {
              ...state.divisions[clientID]?.invoices,
              [invoiceID]: {
                InvoiceNumber: invoiceID,
                PaymentAmount: Number(payAmount).toFixed(2), // rounds to reduce chance of floating point errors
                isCollections
              }
            }
          }
        }
      };
    }
    case 'SUM_PAY_INVOICES': {
      // Add up all the invoices for a given clientID
      const { clientID } = action;
      return {
        ...state,
        divisions: {
          ...state.divisions,
          [clientID]: {
            ...state.divisions[clientID],
            invoicesSum: state.divisions[clientID]?.invoices
              ? Number(
                  Object.keys(state.divisions[clientID]?.invoices)
                    .reduce((sumAccumulator, currInvoiceKey) => {
                      return (
                        sumAccumulator +
                        (Number(
                          state.divisions[clientID]?.invoices[currInvoiceKey]
                            .PaymentAmount
                        ) || 0)
                      );
                    }, 0)
                    .toFixed(2)
                ) // rounds to fix potential floating point errors after operation
              : 0
          }
        }
      };
    }
    case 'RESET_PAY_INVOICES_STATE': {
      const { invoices } = action;
      if (invoices.length > 0) {
        invoices.forEach(invoice => {
          window.sessionStorage.removeItem(invoice.InvoiceNumber);
        });
      }
      return {
        ...initialState
      };
    }
    // case 'DELETE_PAY_INVOICE': {
    //   const { invoiceID } = action.payload;
    //   const newPayInvoices = {
    //     ...state.payInvoices
    //   };
    //   if (newPayInvoices[invoiceID]) {
    //     delete newPayInvoices[invoiceID];
    //   }
    //   return {
    //     ...state,
    //     ...newPayInvoices
    //   };
    // }
    case 'CLEAR_DATA': {
      return initialState;
    }
    case 'SET_PAID_CLIENT': {
      // Sets the client to be paid on the payment screen.
      const { clientID } = action;
      return {
        ...state,
        paidClientId: clientID
      };
    }
    case 'SET_LAST_MODIFIED': {
      // Set the id of the client whose invoice payment amount fields were modified or selected last (to enable/disable the payment button)
      const { clientID } = action;
      return {
        ...state,
        lastModifiedClientId: clientID
      };
    }

    default:
      return state;
  }
};
