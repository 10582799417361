import styled from 'styled-components';

const LoadingDiv = styled.div`
  padding-top: 40vh;
  h1,
  h2 {
    text-align: center;
    margin: 0 auto;
  }
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: url(${props => props.src}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const LoginPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 20%;
  // position: absolute;
  // top: 0;
  padding: 15px;
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  background: white;
  svg {
    cursor: pointer;
  }

  p.error {
    color: red;
  }

  img {
    width: 250px;
    margin-bottom: 10px;
  }

  /* Material UI Embedded Classes */
  .MuiFormControl-root {
    width: 225px;
  }

  .MuiButtonBase-root {
    background-color: #abab25;
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #3c2109;
    }
  }

  a {
    text-decoration: none;
  }

  button {
    white-space: nowrap;
    color: #000;

    &:hover {
      color: #fff;
      a {
        color: #fff;
      }
      background-color: #415463;
    }
  }

  @media screen and (min-width: 960px) {
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 400px;
    //align-self: center;

    /* Material UI Embedded Classes */
    .MuiFormControl-root {
      width: 225px;
    }
  }
`;

const CertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  border-top: 2px solid grey;
  padding: 24px 0 0 0;
  justify-self: stretch;
  align-self: stretch;
`;
const CertGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  padding: 0 40px;
`;

const DigiCertSeal = styled.div``;
const DigiCertImage = styled.img`
  cursor: pointer;
  height: 60px;
`;
const PoweredBy = styled.p`
  font-size: 9px;
  margin: 0;
`;
const GottliebGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const GottliebLogo = styled.img`
  margin: 0;
  max-width: 80px;
  max-height: 21.6px;
`;

export {
  LoadingDiv,
  LoginPanel,
  CertContainer,
  CertGroup,
  DigiCertSeal,
  DigiCertImage,
  PoweredBy,
  GottliebGroup,
  GottliebLogo
};
