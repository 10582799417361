import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { FormTextField, FormSelect, useLanguage } from '../../../common';
import AuthService from 'lib/AuthService';
import { requiredValidator, zipValidator } from 'sd-formik-validators';
import { formatZip, API_BASE } from 'utilities';
import states from 'states.json';
import conditions from 'conditions.json';
import { AccountCard } from '../AccountCard/AccountCard';
import axios from 'axios';
import toastr from 'toastr';

import { Prompt } from 'react-router-dom';
import {
  Typography as Label,
  Button,
  CircularProgress
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { CardLabel } from '../AccountCard/CardLabel';
import { AccountCardRow3 } from './PatientDetailsCardStyles';
const Axios = axios.create({
  timeout: 30000,
  baseURL: API_BASE
});
const FieldSetButtonContainer = styled.div`
  position: absolute;
  top: 1px;
  right: 4px;

  button {
    border-top-left-radius: 0px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 0px;

  }

  p {
    font-size: 14px;
    text-transform: none;
  }
`;
const DualFieldSetButton = styled.div`
  button:nth-child(1) {
    
    border-top: none;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    margin-right: 8px;
  }
  button:nth-child(2) {
    border-top: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
export const PatientDetailsCard = ({ style, User = {}, ...props }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { getLanguageText } = useLanguage();

  useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props.isEditing]);

  const handleSubmit = (values, actions) => {
    const auth = new AuthService();
    const decodedJWT = auth.getProfile();
    const token = auth.getToken();
    const patientDetails = {};
    const newAccountInfo = {
      ...User,
      ...values
    };
    patientDetails.ID = newAccountInfo.id;
    patientDetails.SystemName = newAccountInfo.systemName;
    patientDetails.EmpID = decodedJWT.EmpID;
    patientDetails.LastName = newAccountInfo.lastName;
    patientDetails.FirstName = newAccountInfo.firstName;
    patientDetails.Guarantor = newAccountInfo.guarantor;
    patientDetails.HomePhone = newAccountInfo.homePhone;
    patientDetails.DateOfBirth = newAccountInfo.dateOfBirth;
    patientDetails.Sex = newAccountInfo.sex;
    patientDetails.EmploymentStatus = newAccountInfo.employmentStatus;
    patientDetails.AutoAccidentState = newAccountInfo.autoAccidentState;
    patientDetails.MaritalStatus = newAccountInfo.maritalStatus;
    patientDetails.patientCondition = newAccountInfo.patientCondition;
    patientDetails.Address = newAccountInfo.address;
    patientDetails.City = newAccountInfo.city;
    patientDetails.State = newAccountInfo.state;
    patientDetails.ZipCode = newAccountInfo.zipCode;
    patientDetails.DateOfService = newAccountInfo.dateOfService;
    patientDetails.Charge = newAccountInfo.charge;
    patientDetails.PmtPat = newAccountInfo.pmtPat;
    patientDetails.PmtInsco = newAccountInfo.pmtInsco;
    patientDetails.Adj = newAccountInfo.adj;
    patientDetails.Balance = newAccountInfo.balance;
    patientDetails.Pins = newAccountInfo.pins;
    patientDetails.Sins = newAccountInfo.sins;
    const payload = {
      ...patientDetails,
      ID: decodedJWT.ID,
      SystemName: decodedJWT.SystemName,
      EmpID: decodedJWT.EmpID,
      ReqAction: 3
    };
    Axios.post(
      `/DoctorBill/Account`,
      {
        ...payload
      },
      {
        headers: {
          user_token: token,
          Pragma: 'no-cache'
        }
      }
    )
      .then(response => {
        const { status } = response;
        if (status === 200) {
          alert(
            getLanguageText(
              'Saved successfully. It may take up to one billing cycle for changes to be reflected.'
            )
          );
          actions.resetForm({
            address: User.address || '',
            city: User.city || '',
            state: User.state || '',
            zipCode: User.zipCode || '',
            patientCondition: User.patientCondition || '',
            autoAccidentState: User.autoAccidentState || ''
          });
          setIsEditing(false);
        }
      })
      .catch(error => {
        toastr.error(getLanguageText('We were not able to save your updates.'));
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <AccountCard legend="Patient Details" style={style}>
      <div
        style={{
          marginTop: '20px'
        }}
      >
        <AccountCardRow3>
          <CardLabel
            labelSubject={getLanguageText('Patient Name')}
            labelID="patient-details-patient-name"
          >
            {User.firstName} {User.lastName}
          </CardLabel>
          <CardLabel
            labelSubject={getLanguageText('Guarantor Name')}
            labelID="patient-details-guarantor"
          >
            {User.guarantor}
          </CardLabel>
        </AccountCardRow3>
        <Formik
          id="patient-details-formik"
          onSubmit={handleSubmit}
          enableReinitialize={true}
          initialValues={{
            address: User.address || '',
            city: User.city || '',
            state: User.state || '',
            zipCode: User.zipCode || '',
            patientCondition: User.patientCondition || '',
            autoAccidentState: User.autoAccidentState || ''
          }}
          render={({
            values,
            isSubmitting,
            isValidating,
            dirty,
            resetForm
          }) => {
            return (
              <Form id="patient-details-form">
                <Prompt
                  when={dirty}
                  message={getLanguageText(
                    'You are about to navigate away from this page that has unsaved changes. Click OK to continue.'
                  )}
                  id="form-leaving-prompt"
                />
                <AccountCardRow3>
                  {isEditing ? (
                    <Field name="address">
                      {({ field, form }) => (
                        <FormTextField
                          field={field}
                          form={form}
                          label={getLanguageText('Address')}
                          id="account-txt-address"
                        />
                      )}
                    </Field>
                  ) : (
                    <CardLabel
                      labelSubject={getLanguageText('Address')}
                      labelID="account-txt-address-label"
                    >
                      {values.address}
                    </CardLabel>
                  )}
                  {isEditing ? (
                    <Field name="city">
                      {({ field, form }) => (
                        <FormTextField
                          field={field}
                          form={form}
                          label={getLanguageText('City')}
                          id="account-txt-city"
                        />
                      )}
                    </Field>
                  ) : (
                    <CardLabel
                      labelSubject={getLanguageText('City')}
                      labelID="account-txt-city-label"
                    >
                      {values.city}
                    </CardLabel>
                  )}
                  {isEditing ? (
                    <Field name="state">
                      {({ field, form }) => (
                        <FormSelect
                          field={field}
                          form={form}
                          label={getLanguageText('State')}
                          data={states}
                          id="select-state"
                        />
                      )}
                    </Field>
                  ) : (
                    <CardLabel
                      labelSubject={getLanguageText('State')}
                      labelID="account-txt-state-label"
                    >
                      {values.state
                        ? states.find(obj => obj.code === values.state) &&
                          states.find(obj => obj.code === values.state).name
                        : ''}
                    </CardLabel>
                  )}
                  {isEditing ? (
                    <Field name="zipCode" validate={zipValidator}>
                      {({ field, form }) => (
                        <FormTextField
                          field={field}
                          form={form}
                          label={getLanguageText('Zip Code')}
                          onChange={e =>
                            form.setFieldValue(
                              field.name,
                              formatZip(e.target.value),
                              false
                            )
                          }
                          id="account-txt-zip-code"
                        />
                      )}
                    </Field>
                  ) : (
                    <CardLabel
                      labelSubject={getLanguageText('Zip Code')}
                      labelID="account-txt-zip-code-label"
                    >
                      {values.zipCode}
                    </CardLabel>
                  )}
                </AccountCardRow3>
                {User.systemArea !== 'Centricity' && (
                  <AccountCardRow3>
                    {isEditing ? (
                      <Field
                        name="patientCondition"
                        validate={requiredValidator}
                      >
                        {({ field, form }) => (
                          <FormSelect
                            field={field}
                            form={form}
                            label={`${getLanguageText('Type of Visit')}*`}
                            data={conditions}
                            style={{ minWidth: '225px' }}
                          />
                        )}
                      </Field>
                    ) : (
                      <CardLabel
                        labelSubject={`${getLanguageText('Type of Visit')}*`}
                        labelID="account-select-patient-condition"
                        style={{ minWidth: '225px' }}
                      >
                        {values.patientCondition}
                      </CardLabel>
                    )}
                    {isEditing && values.patientCondition === 'AA' ? (
                      <Field name="autoAccidentState">
                        {({ field, form }) => (
                          <FormSelect
                            field={field}
                            form={form}
                            data={states}
                            label={`${getLanguageText(
                              'If Auto Accident / State'
                            )}`}
                          />
                        )}
                      </Field>
                    ) : values.patientCondition === 'AA' ? (
                      <CardLabel
                        labelSubject={getLanguageText(
                          'If Auto Accident / State'
                        )}
                        labelID="account-select-patient-condition"
                      >
                        {values.autoAccidentState}
                      </CardLabel>
                    ) : null}
                  </AccountCardRow3>
                )}
                <FieldSetButtonContainer>
                  {isEditing ? (
                    <DualFieldSetButton>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          margin: 0
                        }}
                        onClick={() => {
                          setIsEditing(false);
                          resetForm({
                            address: User.address || '',
                            city: User.city || '',
                            state: User.state || '',
                            zipCode: User.zipCode || '',
                            patientCondition: User.patientCondition || '',
                            autoAccidentState: User.autoAccidentState || ''
                          });
                        }}
                        disabled={isSubmitting && isValidating}
                        id={'account-btn-edit-patient-details-cancel'}
                      >
                        <FontAwesomeIcon
                          icon={'times'}
                          style={{ marginRight: '8px' }}
                        />
                        <Label>
                          <strong>{getLanguageText('Cancel')}</strong>
                        </Label>
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: 0
                        }}

                        type="submit"
                        disabled={isSubmitting || isValidating || !dirty}
                        id={'account-btn-edit-patient-details-save'}
                      >
                        {!(isSubmitting || isValidating) && (
                          <FontAwesomeIcon
                            icon={'save'}
                            style={{ marginRight: '8px' }}
                          />
                        )}
                        {(isSubmitting || isValidating) && (
                          <CircularProgress size={20} />
                        )}
                        <Label>
                          <strong>{getLanguageText('Save')}</strong>
                        </Label>
                      </Button>
                    </DualFieldSetButton>
                  ) : (
                    <Button
                    variant="contained"
                    color="primary"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                      id={'account-btn-edit-patient-details-update'}
                    >
                      <FontAwesomeIcon
                        icon={'pencil-alt'}
                        style={{ marginRight: '8px' }}
                      />
                      <Label>
                        <strong>{getLanguageText('Update')}</strong>
                      </Label>
                    </Button>
                  )}
                </FieldSetButtonContainer>
              </Form>
            );
          }}
        />
      </div>
    </AccountCard>
  );
};
