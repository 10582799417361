import { BrandColor } from 'constants/brandColor';
import { theme } from 'lib/theme';
import styled from 'styled-components';

const SideNavContainer = styled.div`
  height: auto;
  min-width: ${props => (props.showMobileNav === true ? '95%' : '180px')};
  display: ${props => (props.showMobileNav === true ? 'flex' : 'none')};
  flex-direction: column;
  z-index: ${props => (props.showMobileNav === true ? '1000' : '0')};
  ${props => props.showMobileNav === true && 'animation: expand .25s'};
  .my-account-text {
    padding: 16px 10px;
  }
  ul {
    list-style-type: none;
    li {
      cursor: pointer;
    }
  }

  @media screen and (min-width: 960px) {
    display: flex;
  }

  @keyframes expand {
    0% {
      min-width: 0px;
    }

    100% {
      min-width: 95%;
    }
  }
  @keyframes contract {
    0% {
      min-width: 95%;
    }

    100% {
      min-width: 180px;
      display: ${props => (props.showMobileNav === true ? 'flex' : 'none')};
    }
  }

  a {
    text-decoration: none;
    color: #fff !important;
  }

  background-color: ${theme.palette.primary.dark};
`;

const ListContainer = styled.div`
  white-space: normal;
  ul {
    color: #fff;
    font-size: 16px;
    padding: 0;

    li {
      display: flex;
      padding: 10px 10px;
      align-items: center;

      &.is-active {
        border-left: 4px solid ${BrandColor.ACCENT};
      }

      svg {
        margin-right: 10px;
      }

      ul {
        padding: 5px 15px;

        li {
          padding-top: 5px;

          &.fadeIn {
            animation: fadeIn 1s;
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
            }

            100% {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  p {
    color: #fff;
    font-weight: 600;
    padding: 10px;
    display: block;
    border-bottom: 2px solid ${theme.palette.divider};
  }
`;

export { SideNavContainer, ListContainer };
