import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Typography as Label } from '@material-ui/core';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLanguage } from '../common';
import {
  faHistory,
  faRecycle,
  faPhoneAlt,
  faPaperclip,
  faSignOutAlt,
  faFileMedicalAlt,
  faQuestionCircle,
  faUserShield
} from '@fortawesome/free-solid-svg-icons';

import { SideNavContainer, ListContainer } from './SideNavStyles';
import AuthService from '../../lib/AuthService';

function SideNav({
  clearData,
  showMobileNav,
  history,
  location: { pathname },
  User,
  ...props
}) {
  function logout() {
    const auth = new AuthService();
    auth.logout();
    clearData();
    history.push('/');
  }
  const { getLanguageText } = useLanguage();

  return (
    <SideNavContainer showMobileNav={showMobileNav}>
      <ListContainer>
        <Label className="my-account-text">
          {getLanguageText('My Account')}
        </Label>
        <ul>
          <Link to="/Account" id="sidenav-link-account-details">
            <li
              className={classnames({
                'is-active': pathname.endsWith('Account')
              })}
            >
              <Icon icon={faFileMedicalAlt} />
              {getLanguageText('Account Details')}
            </li>
          </Link>

          <Link to="/Account/History" id="sidenav-link-account-history">
            <li
              className={classnames({
                'is-active': pathname.includes('History')
              })}
            >
              <Icon icon={faHistory} />
              {getLanguageText('Account History')}
            </li>
          </Link>

          {User.systemArea !== 'Centricity' && (
            <Link to="/Paperless" id="sidenav-link-paperless">
              <li
                className={classnames({
                  'is-active': pathname.includes('Paperless')
                })}
              >
                <Icon icon={faRecycle} />
                {getLanguageText('Email Reminder')}
              </li>
            </Link>
          )}
        </ul>
      </ListContainer>

      <ListContainer>
        <Label>{getLanguageText('Patient Services')}</Label>
        <ul>
          <Link to="/FAQ" id="sidenav-link-faq">
            <li
              className={classnames({ 'is-active': pathname.includes('FAQ') })}
            >
              <Icon icon={faQuestionCircle} />
              FAQ
            </li>
          </Link>

          <Link to="/Contact" id="sidenav-link-contact">
            <li
              className={classnames({
                'is-active': pathname.includes('Contact')
              })}
            >
              <Icon icon={faPhoneAlt} />
              {getLanguageText('Contact Us')}
            </li>
          </Link>

          <Link to="/Forms" id="sidenav-link-forms">
            <li
              className={classnames({
                'is-active': pathname.includes('Forms')
              })}
            >
              <Icon icon={faPaperclip} />
              {getLanguageText('Patient Forms')}
            </li>
          </Link>
          <li id="sidenav-privacy-policy">
            <Icon icon={faUserShield} />
            <a
              href="https://ventrahealth.com/privacy-policy/"
              rel="noopener noreferrer"
              style={{ height: '100%', width: '100%' }}
              target="_blank"
              id="sidebar-privacy-policy-link"
            >
              {getLanguageText('Privacy Policy')}
            </a>
          </li>
          <li id="sidenav-link-logout" onClick={logout}>
            <Icon icon={faSignOutAlt} />
            {getLanguageText('Logout')}
          </li>
        </ul>
      </ListContainer>
    </SideNavContainer>
  );
}
const mapStateTopProps = state => ({
  User: state.User
});
const mapDispatchToProps = dispatch => ({
  clearData: () => dispatch({ type: 'CLEAR_DATA' })
});

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(SideNav));
