import styled from 'styled-components';

let borderColor = 'none';

const URL = document.location.href;

if (URL.includes('dev')) {
  borderColor = 'green';
}

if (URL.includes('qa')) {
  borderColor = 'orange';
}

const Layout = styled.div`
  background: white;
  height: 100%;
  border: 6px solid ${borderColor};

  position: relative;

  background: white;

  button {
    &.plain {
      svg {
        margin-left: 5px;
        margin-right: 5px;
      }

      background-color: transparent;
    }
  }
`;

const LogoutContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  margin-left: -175px;
  color: #fff;
  background-color: rgb(65, 84, 99);
  z-index: 15;
`;

const LogoutHeader = styled.div`
  border-bottom: 1px solid #fff;
  padding: 10px;
`;

const LogoutContent = styled.div`
  padding: 10px;
`;

const LogoutActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-top: 25px;

  button {
    white-space: nowrap;
    background-color: #abab25;
    margin-left: 10px;
    &:hover {
      color: #fff;
      a {
        color: #fff;
      }
      background-color: #415463;
    }
  }
`;

export { Layout, LogoutContainer, LogoutHeader, LogoutContent, LogoutActions };
