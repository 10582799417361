import toastr from 'toastr';
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { SideNav, Header } from '../..';
import { useState, useEffect } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import {
  formatMoney,
  API_BASE,
  formatZip,
  setTabTitle,
  isNonProductionEnv
} from '../../../utilities';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { TextValidator } from 'react-material-ui-form-validator';
import jwtDecode from 'jwt-decode';
import {
  faCcAmex,
  faCcVisa,
  faCcDiscover,
  faCcMastercard
} from '@fortawesome/free-brands-svg-icons';
import { Prompt, Link } from 'react-router-dom';
import AuthService from '../../../lib/AuthService';
import {
  Typography as Label,
  Button,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  CircularProgress,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import {
  ContentArea,
  PayBillContainer,
  PayBillFormContainer,
  CardIconContainer,
  ViewContainer,
  FlexDiv,
  DialogBody,
  Row
} from './PayBillStyles';
import Axios from 'axios';
import IncDialog from './IncDialog';
import { useLanguage } from '../../common';
import { Months, States, Countries } from './data';
import styled from 'styled-components';
import { PBPTable } from 'components/common';
import { theme } from 'lib/theme';
const Years = [];
const UserInformationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid ${theme.palette.divider};
  padding: 16px 15px;
  & > * {
    margin-bottom: '12px';
  }

  .MuiButtonBase-root {
    background-color: #abab25;
  }
  a {
    text-decoration: none;
  }
  p {
    font-weight: 500;
  }
  button {
    margin-top: 2px;
    margin-left: 10px;
    white-space: nowrap;
    color: black;
  }
  @media screen and (min-width: 890px) {
    padding: 16px 15px;
    button {
      margin-top: 2px;
      margin-left: 10px;
      white-space: nowrap;
      color: black;
      &:hover {
        color: white;
        background-color: #415463;
      }
    }
  }
`;
const TableFooterRow = styled.tr`
  & td {
    border-top: 1px solid black;
  }
`;
const GoBackButton = styled(Link)`
  width: 200px;
  height: 50px;
  & button {
    width: 100%;
    height: 100%;
  }
`;
for (let year = moment().year(); year < moment().year() + 10; year++) {
  Years.push(year);
}

const useStyles = makeStyles({
  errorFormHelperText: {
    marginBottom: '12px',
    color: 'red',
    fontWeight: 300
  }
});
function PayBill({
  User,
  PayInvoices,
  history,
  setTransaction,
  setUser,
  hasLogoutTimerStart,
  resetPayInvoices,
  ...props
}) {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [auth] = useState(new AuthService());
  const [decodedJWT] = useState(jwtDecode(auth.getToken()));
  const [showInquiryDialog, setShowInquiryDialog] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [values, setValues] = useState({
    cvv: '',
    zip: '',
    city: '',
    year: '',
    email: '',
    month: '',
    state: '',
    amount: '',
    country: '',
    pay_amount: '',
    card_number: '',
    name_on_card: '',
    email_receipt: false,
    cardholder_billing_address_line_1: '',
    cardholder_billing_address_line_2: ''
  });

  const { getLanguageText, language } = useLanguage();
  const [isBlocking, setIsBlocking] = useState(false);
  const [didPaymentSucceed, setDidPaymentSucceed] = useState(false);
  const [dropdownErrors, setDropdownErrors] = useState({
    hasMonthError: false,
    hasYearError: false,
    hasStateError: false,
    hasCountryError: false
  });
  const [required, setRequired] = useState(true);
  const [isPaymentHappening, setIsPaymentHappening] = useState(false);

  const paidClientId = PayInvoices.paidClientId;

  const correctBalance = PayInvoices?.divisions[paidClientId]?.invoicesSum;
  useEffect(() => {
    setTabTitle('Pay Bill');
  }, []);

  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    if (
      PayInvoices &&
      PayInvoices?.divisions[paidClientId] &&
      PayInvoices?.divisions[paidClientId]?.invoices
    ) {
      setInvoices(
        Object.keys(PayInvoices?.divisions[paidClientId]?.invoices).reduce(
          (invoicesAccumulator, currInvoiceKey, i) => {
            const invoice =
              PayInvoices?.divisions[paidClientId]?.invoices[currInvoiceKey];
            if (invoice.PaymentAmount <= 0) {
              return invoicesAccumulator;
            }
            return invoicesAccumulator.concat({
              InvoiceNumber: invoice.InvoiceNumber,
              PaymentAmount: Number(Number(invoice.PaymentAmount).toFixed(2)),
              Sequence: ++i,
              isCollections: invoice.isCollections
            });
          },
          []
        )
      );
    }
  }, [PayInvoices, paidClientId]);

  useEffect(() => {
    if (didPaymentSucceed === true) {
      history.push('/Account/Pay/Confirmation');
    }
  }, [didPaymentSucceed, history]);

  useEffect(() => {
    setValues(prevState => {
      return {
        ...prevState,
        email: User.email
      };
    });
  }, [User.email, setUser]);

  useEffect(() => {
    if (Number(decodedJWT.InquiryCommandID) === 2) {
      setRequired(false);
      setValues(prevValues => {
        return {
          ...prevValues,
          name_on_card: 'Insurance'
        };
      });
    }
  }, [setValues, decodedJWT]);

  useEffect(() => {
    const empID = Number(decodedJWT.EmpID);
    const incCmdID = Number(decodedJWT.InquiryCommandID);
    const isPatient = incCmdID === 1;
    const isEmployee = empID > 0;
    if (isPatient && isEmployee) {
      setShowInquiryDialog(true);
    }
  }, []);

  function emailReceipt() {
    setValues({ ...values, email_receipt: !values.email_receipt });
  }

  const getCardType = number => {
    // visa
    var re = new RegExp('^4');
    if (number.match(re) != null) return 'Visa';

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    )
      return 'Mastercard';

    // AMEX
    re = new RegExp('^3[47]');
    if (number.match(re) != null) return 'AMEX';

    // Discover
    re = new RegExp(
      '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
    );
    if (number.match(re) != null) return 'Discover';
  };

  function updateValues({ target }) {
    const { name, value } = target;
    setIsBlocking(true);
    if (name === 'month') {
      setDropdownErrors({
        ...dropdownErrors,
        hasMonthError: false
      });
    }
    if (name === 'year') {
      setDropdownErrors({
        ...dropdownErrors,
        hasYearError: false
      });
    }
    if (name === 'state') {
      setDropdownErrors({
        ...dropdownErrors,
        hasStateError: false
      });
    }
    if (name === 'country') {
      setDropdownErrors({
        ...dropdownErrors,
        hasCountryError: false
      });
    }
    // debugger;
    if (name === 'amount') {
      if (isNaN(value)) {
        values.amount = '';
      } else {
        values.amount = value;
      }
      setValues({ ...values, pay_amount: 'Other Amount' });
      return;
    }

    if (name === 'city') {
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      const lastKey = value[value.length - 1];

      if (lastKey) {
        if (numbers.includes(parseInt(lastKey))) {
          return;
        }
      } else {
        if (numbers.includes(value)) {
          return;
        }
      }

      if (value.length === 0) {
        setValues({ ...values, city: '' });
      }
    }

    if (name === 'pay_amount') {
      const newVals = { ...values };

      switch (value) {
        case 'Current Balance': {
          setValues({ ...values, pay_amount: 'Current Balance' });
          newVals.amount = formatMoney(correctBalance);
          break;
        }
        case 'Minimum Amount': {
          setValues({ ...values, pay_amount: 'Minimum Amount' });
          newVals.amount = formatMoney('25.00');
          break;
        }
        case 'Other Amount': {
          newVals.amount = '';
          break;
        }
        default:
          break;
      }

      setValues({ ...newVals, [name]: value });

      return;
    }

    if (name === 'card_number') {
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      if (value.length > 19) {
        // There are no credit cards longer than 19 digits
        return;
      }

      if (value.length > 0) {
        for (let idx = 0; idx < value.length; idx++) {
          if (!numbers.includes(parseInt(value[idx]))) {
            return;
          }
        }
      }
    }

    if (name === 'cvv') {
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      if (value.length > 4) {
        // The CVV is never longer than 4 digits, the average is 3
        return;
      }

      if (value.length > 0) {
        for (let idx = 0; idx < value.length; idx++) {
          if (!numbers.includes(parseInt(value[idx]))) {
            return;
          }
        }
      }
    }
    if (name === 'zip') {
      setValues({
        ...values,
        [name]: formatZip(value)
      });
      return;
    }

    setValues({ ...values, [name]: value });
  }

  function showConfirmDialog() {
    const dropdownErrors = {
      hasMonthError: false,
      hasYearError: false,
      hasStateError: false,
      hasCountryError: false
    };
    let hasError = false;
    if (month === '') {
      hasError = true;
      dropdownErrors.hasMonthError = true;
    }
    if (year === '') {
      hasError = true;
      dropdownErrors.hasYearError = true;
    }
    if (required) {
      if (state === '') {
        hasError = true;
        dropdownErrors.hasStateError = true;
      }
      if (country === '') {
        hasError = true;
        dropdownErrors.hasCountryError = true;
      }
    }
    if (hasError) {
      setDropdownErrors({
        ...dropdownErrors
      });
    } else {
      setShowDialog(true);
    }
  }

  function onConfirmPayment() {
    setShowDialog(false);
    const {
      card_number,
      cvv,
      name_on_card,
      month,
      year,
      city,
      state,
      zip,
      country,
      cardholder_billing_address_line_1
    } = values;
    const model = {
      id: `${decodedJWT.ID}`,
      Invoices: invoices,
      CentricityGroup: User.centricityGroup,
      CentricityGID: Number(User.centricityGID),
      CentricityDivisionClientID: PayInvoices?.paidClientId,
      hospAcctNbr: null,
      patientName: `${User.firstName} ${User.lastName}`,
      empId: decodedJWT.EmpID,
      InquiryCommandID: Number(decodedJWT.InquiryCommandID),
      patientEmail: values.email_receipt ? values.email : '',
      facPhone: User.facPhone,
      firstName: User.firstName,
      lastName: User.lastName,
      middleName: User.mInitial,
      dateOfBirth: User.dateOfBirth,
      balance: correctBalance,
      paymentAmount: PayInvoices.divisions[paidClientId].invoicesSum,
      phoneNumber: User.homePhone,
      ivrContactSessionID: '1219000211',
      sourceSystem: User.sourceSystem,
      ApplicationName: 'PBP',
      ApplicationID: 3,
      creditCard: {
        type: getCardType(card_number),
        cardholder_name: name_on_card,
        card_number: card_number,
        exp_date: `${month}${Number(year) % 1000}`,
        cvv: cvv,
        specialpayment: null
      },
      paymentType: decodedJWT.InquiryCommandID
        ? decodedJWT.InquiryCommandID
        : 1,
      recurringType: 0,
      SendCreditCardPaymentReceipt: values.email_receipt,
      billing_Address: {
        city: city,
        street: cardholder_billing_address_line_1,
        state_province: state,
        country: country,
        zip_postal_code: zip,
        email: User.email,
        phone: {
          type: 0,
          number: null
        }
      }
    };

    setIsPaymentHappening(true);
    console.log(model);
    Axios.post(
      `${API_BASE}/DoctorBill/Payment`,
      {
        ...model
      },
      {
        headers: {
          user_token: auth.getToken(),
          Pragma: 'no-cache'
        }
      }
    )
      .then(async response => {
        const { data } = response;
        if (data.status === true) {
          toastr.success(data.description);
          setTransaction({
            ...data,
            invoices
          });
          resetPayInvoices(invoices);
          setIsBlocking(false);
          setDidPaymentSucceed(true);
          try {
            const { data: dataUser } = await Axios({
              method: 'get',
              url: `${API_BASE}/DoctorBill/RefreshAccount`,
              headers: {
                user_token: auth.getToken(),
                Pragma: 'no-cache'
              }
            });

            setUser(dataUser);
          } catch (err) {
            console.log({ err });
            toastr.error(getLanguageText('Please refresh the page.'));
          }
        } else {
          console.log(data.description);
          toastr.error(data.description);
        }
      })
      .catch(error => {
        console.log({ error });
        toastr.error(getLanguageText(error.response && error.response.data));
      })
      .finally(() => {
        setIsPaymentHappening(false);
      });
  }

  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  const yearLabel = React.useRef(null);
  const monthLabel = React.useRef(null);
  const stateLabel = React.useRef(null);
  const countryLabel = React.useRef(null);

  const [yearWidth, setYearWidth] = React.useState(0);
  const [stateWidth, setStateWidth] = React.useState(0);
  const [monthWidth, setMonthWidth] = React.useState(0);
  const [countryWidth, setCountryWidth] = React.useState(0);

  React.useEffect(() => {
    setYearWidth(yearLabel.current.offsetWidth);
    setStateWidth(stateLabel.current.offsetWidth);
    setMonthWidth(monthLabel.current.offsetWidth);
    setCountryWidth(countryLabel.current.offsetWidth);
  }, []);

  const {
    cvv,
    zip,
    city,
    year,
    month,
    email,
    state,
    country,
    card_number,
    name_on_card,
    email_receipt,
    cardholder_billing_address_line_1,
    cardholder_billing_address_line_2
  } = values;

  return (
    <ContentArea>
      <Prompt
        when={isBlocking && hasLogoutTimerStart}
        message={getLanguageText(
          'You are about to navigate away from this page that has unsaved changes. Click OK to continue.'
        )}
      />
      <Header toggleMobileNav={toggleMobileNav} />
      <PayBillContainer>
        <SideNav showMobileNav={showMobileNav} />
        <ViewContainer showMobileNav={showMobileNav}>
          <UserInformationWrapper>
            <Label style={{ marginRight: '20px' }} id="user-account-info">
              {getLanguageText('Account Number')}:{' '}
              {
                <span
                  style={
                    isNonProductionEnv() ? { color: 'red' } : { color: 'black' }
                  }
                  id="user-display-account-number"
                >{` ${User ? User.displayAccountNumber : null}`}</span>
              }
            </Label>
          </UserInformationWrapper>
          <PayBillFormContainer
            id="pay-bill-form"
            onSubmit={() => {
              showConfirmDialog();
            }}
            onError={errors => {
              setShowDialog(false);
            }}
          >
            <Row>
              <PBPTable
                decodedJWT={decodedJWT}
                ComponentWhenDataEmpty={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <p style={{ fontSize: '20px' }}>
                      {getLanguageText('No Invoices Found to make Payment.')}
                    </p>
                    <GoBackButton to="/Account">
                      <Button variant="contained" color="primary">
                        {getLanguageText('Go Back')}
                      </Button>
                    </GoBackButton>
                  </div>
                }
                columns={React.useMemo(() => {
                  return [
                    {
                      Header: getLanguageText('Invoice'),
                      accessor: 'InvoiceNumber',
                      Cell: data => {
                        return (
                          <div id={`invoice-${data.cell.value}`}>
                            {data.cell.value}
                          </div>
                        );
                      }
                    },
                    {
                      Header: getLanguageText('Amount'),
                      accessor: 'PaymentAmount',
                      Cell: data => {
                        const { InvoiceNumber } = data.row.values;
                        return (
                          <div
                            style={{ width: '100%' }}
                            id={`invoice-${InvoiceNumber}-pay-amount`}
                          >
                            ${data.cell.value}
                          </div>
                        );
                      }
                    }
                  ];
                }, [getLanguageText])}
                data={invoices.map(invoice => {
                  return {
                    ...invoice,
                    PaymentAmount: formatMoney(invoice.PaymentAmount)
                  };
                })}
                hidePayButton
              >
                <TableFooterRow>
                  <td
                    style={{
                      border: '1px solid white',
                      borderTop: '1px solid black'
                    }}
                  />
                  <td
                    style={{
                      background: 'grey',
                      color: 'white',
                      fontWeight: 700,
                      border: '1px solid black'
                    }}
                  >
                    <span id="total-invoices-sum">
                      $
                      {formatMoney(
                        PayInvoices?.divisions[paidClientId]?.invoicesSum
                      )}
                    </span>{' '}
                    - {getLanguageText('Total')}
                  </td>
                </TableFooterRow>
              </PBPTable>
            </Row>
            <Row
              id="developer-controls"
              style={(() => {
                const URL = document.location.href;
                if (URL.includes('localhost:3000')) {
                  return {
                    display: 'flex'
                  };
                } else {
                  return {
                    display: 'none'
                  };
                }
              })()}
            >
              <button
                onClick={() => {
                  const URL = document.location.href;
                  if (URL.includes('localhost:3000')) {
                    setValues(prevState => {
                      return {
                        ...prevState,
                        cvv: '123',
                        zip: '12345',
                        city: 'a',
                        year: '2021',
                        email: '',
                        month: '01',
                        state: 'Georgia',
                        amount: '',
                        country: 'United States',
                        pay_amount: '',
                        card_number: '4111111111111111',
                        name_on_card: 'a',
                        email_receipt: false,
                        cardholder_billing_address_line_1: 'a',
                        cardholder_billing_address_line_2: ''
                      };
                    });
                  }
                }}
              >
                fill
              </button>
            </Row>
            <Row>
              <TextValidator
                name="name_on_card"
                label={`${getLanguageText('Name on Card')} *`}
                id="pay-bill-txt-name-on-card-desktop"
                variant="outlined"
                value={name_on_card}
                onChange={updateValues}
                validators={['required', 'matchRegexp:^.{0,100}$']}
                errorMessages={
                  language === 'en'
                    ? [
                        'Required field',
                        'Name can only be a 100 characters or less.'
                      ]
                    : [
                        'Campo requerido',
                        'El nombre solo puede tener 100 caracteres o menos'
                      ]
                }
              />
            </Row>
            <Row>
              <TextValidator
                name="card_number"
                label={`${getLanguageText('Card Number')} *`}
                variant="outlined"
                id="pay-bill-txt-number-on-card-desktop"
                value={card_number}
                onChange={updateValues}
                validators={['required']}
                errorMessages={
                  language === 'en' ? ['Required field'] : ['Campo requerido']
                }
              />
            </Row>
            <Row>
              <CardIconContainer>
                <Icon icon={faCcAmex} size="2x" />
                <Icon icon={faCcDiscover} size="2x" />
                <Icon icon={faCcMastercard} size="2x" />
                <Icon icon={faCcVisa} size="2x" />
              </CardIconContainer>
            </Row>
            <Row>
              <div>
                <FormControl variant="outlined">
                  <InputLabel ref={monthLabel}>
                    {`${getLanguageText('Month')} *`}
                  </InputLabel>
                  <Select
                    name="month"
                    value={month}
                    id="pay-bill-select-month-desktop"
                    error={dropdownErrors.hasMonthError}
                    onChange={updateValues}
                    input={<OutlinedInput labelWidth={monthWidth} />}
                  >
                    {Months &&
                      Months.map(month => (
                        <MenuItem
                          value={month.numCode}
                          key={month.numCode}
                          id={`select-item-month-${month.name}`}
                        >
                          {getLanguageText(month.name)}
                        </MenuItem>
                      ))}
                  </Select>
                  {dropdownErrors.hasMonthError && (
                    <FormHelperText
                      className={classes.errorFormHelperText}
                      id="month-error-text"
                    >
                      {getLanguageText('Please select a month.')}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div>
                <FormControl variant="outlined">
                  <InputLabel ref={yearLabel}>{`${getLanguageText(
                    'Year'
                  )} *`}</InputLabel>
                  <Select
                    name="year"
                    id="pay-bill-select-year-desktop"
                    error={dropdownErrors.hasYearError}
                    value={year}
                    onChange={updateValues}
                    input={<OutlinedInput labelWidth={yearWidth} />}
                  >
                    {Years &&
                      Years.map(year => (
                        <MenuItem
                          value={year}
                          key={year}
                          id={`select-year-item-${year}`}
                        >
                          {year}
                        </MenuItem>
                      ))}
                  </Select>
                  {dropdownErrors.hasYearError && (
                    <FormHelperText
                      className={classes.errorFormHelperText}
                      id="year-error-text"
                    >
                      {getLanguageText('Please select a year.')}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div>
                <TextValidator
                  name="cvv"
                  label="CVV *"
                  id="pay-bill-txt-cvv-desktop"
                  variant="outlined"
                  value={cvv}
                  validators={['required']}
                  errorMessages={
                    language === 'en' ? ['Required field'] : ['Campo requerido']
                  }
                  onChange={updateValues}
                />
              </div>
            </Row>
            <Row>
              <TextValidator
                validators={
                  required ? ['required', 'matchRegexp:^.{0,100}$'] : []
                }
                errorMessages={
                  language === 'en'
                    ? [
                        'Required field',
                        'Billing address must be 100 characters or less.'
                      ]
                    : 'Campo Requerido'
                }
                name="cardholder_billing_address_line_1"
                label={
                  required
                    ? `${getLanguageText('Cardholder Billing Address')} *`
                    : getLanguageText('Cardholder Billing Address')
                }
                id="pay-bill-txt-billing-address-line1-desktop"
                variant="outlined"
                value={cardholder_billing_address_line_1}
                onChange={updateValues}
              />
            </Row>
            <Row>
              <TextValidator
                validators={['matchRegexp:^.{0,100}$']}
                errorMessages={[
                  'Address Line 2 must be 100 characters or less.'
                ]}
                name="cardholder_billing_address_line_2"
                label={getLanguageText('Address Line 2')}
                variant="outlined"
                id="pay-bill-txt-billing-address-line2-desktop"
                value={cardholder_billing_address_line_2}
                onChange={updateValues}
              />
            </Row>
            <Row>
              <TextValidator
                validators={
                  required ? ['required', 'matchRegexp:^.{0,100}$'] : []
                }
                errorMessages={
                  language === 'en'
                    ? ['Required field', 'City must be 100 characters or less.']
                    : [
                        'Campo requerido',
                        'La ciudad solo puede tener 100 caracteres o menos'
                      ]
                }
                name="city"
                value={city}
                label={
                  required
                    ? `${getLanguageText('City')} *`
                    : getLanguageText('City')
                }
                variant="outlined"
                onChange={updateValues}
                id="pay-bill-txt-city-desktop"
                inputProps={{
                  maxLength: 50
                }}
              />
            </Row>
            <Row>
              <div>
                <TextValidator
                  errorMessages={
                    language === 'en'
                      ? ['Required field', 'Zip code should be 5 or 9 digits.']
                      : [
                          'Campo requerido',
                          'El código postal debe tener 5 o 9 dígitos.'
                        ]
                  }
                  name="zip"
                  id="pay-bill-txt-zip-desktop"
                  label={
                    required
                      ? `${getLanguageText('Zip Code')} *`
                      : getLanguageText('Zip Code')
                  }
                  variant="outlined"
                  value={zip}
                  onChange={updateValues}
                  validators={
                    required
                      ? ['required', 'matchRegexp:^([0-9]{5})(-[0-9]{4})?$']
                      : []
                  }
                />
              </div>
              <div className="inline">
                <FormControl variant="outlined">
                  <InputLabel ref={stateLabel} id="state-input-label">
                    {required
                      ? `${getLanguageText('State')} *`
                      : getLanguageText('State')}
                  </InputLabel>
                  <Select
                    name="state"
                    error={dropdownErrors.hasStateError}
                    id="pay-bill-select-state-desktop"
                    value={state}
                    onChange={updateValues}
                    input={<OutlinedInput labelWidth={stateWidth} />}
                  >
                    {States &&
                      States.map(state => (
                        <MenuItem
                          value={state}
                          key={state}
                          id={`select-item-state-${state}`}
                        >
                          {state}
                        </MenuItem>
                      ))}
                  </Select>
                  {dropdownErrors.hasStateError && (
                    <FormHelperText
                      className={classes.errorFormHelperText}
                      id="state-error-text"
                    >
                      {getLanguageText('Please select a state.')}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div>
                <FormControl variant="outlined">
                  <InputLabel ref={countryLabel} id="country-input-label">
                    {required
                      ? `${getLanguageText('Country')} *`
                      : getLanguageText('Country')}
                  </InputLabel>
                  <Select
                    name="country"
                    value={country}
                    onChange={updateValues}
                    error={dropdownErrors.hasCountryError}
                    id="pay-bill-select-country-desktop"
                    input={<OutlinedInput labelWidth={countryWidth} />}
                  >
                    {Countries &&
                      Countries.map(country => (
                        <MenuItem
                          value={country}
                          key={country}
                          id={`select-country-item-${country}`}
                        >
                          {country}
                        </MenuItem>
                      ))}
                  </Select>
                  {dropdownErrors.hasCountryError && (
                    <FormHelperText
                      className={classes.errorFormHelperText}
                      id="country-error-text"
                    >
                      {getLanguageText('Please select a country.')}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </Row>
            <div>
              <FlexDiv>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={email_receipt}
                      onChange={emailReceipt}
                      color="primary"
                      id="email-receipt-checkbox"
                    />
                  }
                  label={getLanguageText(
                    'Please email me a copy of my receipt.'
                  )}
                  labelPlacement="end"
                />
              </FlexDiv>
              <Row>
                {email_receipt && (
                  <FlexDiv>
                    <TextValidator
                      name="email"
                      id="pay-bill-txt-email-desktop"
                      label={getLanguageText('Email Address')}
                      variant="outlined"
                      value={email}
                      onChange={updateValues}
                      validators={['required', 'isEmail']}
                      errorMessages={
                        language === 'en'
                          ? [
                              'Required field',
                              'Please enter a valid email address.'
                            ]
                          : [
                              'Campo requerido',
                              'Por favor, introduce una dirección de correo electrónico válida.'
                            ]
                      }
                    />
                  </FlexDiv>
                )}
              </Row>
            </div>

            <div className="inline">
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: '15px' }}
                type="submit"
                id="pay-bill-btn-pay-now-desktop"
                disabled={
                  isPaymentHappening || (required ? correctBalance <= 0 : false)
                }
              >
                {getLanguageText('Pay Now')}
                {isPaymentHappening ? <CircularProgress size={20} /> : null}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                id="pay-bill-btn-cancel-desktop"
                onClick={() => history.goBack()}
                disabled={isPaymentHappening}
              >
                {getLanguageText('Cancel')}
              </Button>
            </div>
            <Dialog onClose={() => {}} open={showDialog}>
              <DialogTitle>
                {getLanguageText('Please Confirm Payment')}
              </DialogTitle>
              <DialogBody>
                <Label id="confirm-charge-label">
                  {`${getLanguageText(
                    'You are about to charge your credit card'
                  )} $${formatMoney(correctBalance)}
                  . ${getLanguageText(
                    'If this is correct please click proceed.'
                  )}`}
                </Label>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '15px' }}
                  id="pay-bill-btn-confirm"
                  disabled={
                    isPaymentHappening ||
                    (required ? correctBalance <= 0 : false)
                  }
                  onClick={onConfirmPayment}
                >
                  {getLanguageText('Proceed')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  id="pay-bill-btn-cancel"
                  onClick={() => setShowDialog(false)}
                >
                  {getLanguageText('Cancel')}
                </Button>
              </DialogBody>
            </Dialog>
            <Dialog
              maxWidth={'lg'}
              fullWidth={true}
              onClose={() => {}}
              open={showInquiryDialog}
            >
              <DialogTitle>
                What information would you like auto populated:
              </DialogTitle>
              <IncDialog
                setShowInquiryDialog={setShowInquiryDialog}
                values={values}
                setValues={setValues}
              />
            </Dialog>
          </PayBillFormContainer>
        </ViewContainer>
      </PayBillContainer>
    </ContentArea>
  );
}

const mapStateToProps = state => ({
  User: state.User,
  PayInvoices: state.PayInvoices
});
const mapDispatchToProps = dispatch => ({
  setTransaction: transaction =>
    dispatch({ type: 'SET_TRANSACTION', transaction }),
  setUser: user => dispatch({ type: 'SET_USER', user }),
  resetPayInvoices: invoices =>
    dispatch({ type: 'RESET_PAY_INVOICES_STATE', invoices })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayBill);
