import React from 'react';
import { TextField } from '@material-ui/core';
import { useLanguage } from '.';
export const FormTextField = ({ form, field, label, style = {}, ...props }) => {
  const { getLanguageText } = useLanguage();
  return (
    <div style={style}>
      <h4>{label}</h4>
      <TextField
        {...field}
        variant="standard"
        margin="normal"
        style={{ margin: 0 }}
        error={Boolean(form.touched[field.name] && form.errors[field.name])}
        helperText={
          form.touched[field.name] && form.errors[field.name]
            ? getLanguageText(form.errors[field.name])
            : ''
        }
        {...props}
      />
    </div>
  );
};
