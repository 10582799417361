import React from 'react';
import styled from 'styled-components';
import createPersistedState from 'use-persisted-state';
const useLanguagePreference = createPersistedState('language-preference');

const LanguageWrapper = styled.div`
  @media print {
    display: none;
  }
`;
const LanguageLabel = styled.label`
  display: none;
  @media screen and (min-width: 960px) {
    display: inline;
  }
`;
const LanguageDropdown = ({ ...props }) => {
  const [languagePreference, setLanguagePreference] = useLanguagePreference(
    'en'
  );
  return (
    <LanguageWrapper {...props}>
      <LanguageLabel
        htmlFor="language-selection-box"
        style={{ marginRight: '8px' }}
      >
        Site Language:
      </LanguageLabel>
      <select
        value={languagePreference}
        onChange={e => setLanguagePreference(e.target.value)}
        id="language-selection-box"
      >
        <option value={'en'} id="language-option-en">
          English
        </option>
        <option value={'es'} id="language-option-es">
          Spanish
        </option>
      </select>
    </LanguageWrapper>
  );
};

export { LanguageDropdown };
