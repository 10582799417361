import toastr from 'toastr';
import React from 'react';
import { Component } from 'react';
import { Typography as Label, Button } from '@material-ui/core';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import { API_BASE } from './utilities';
import IdleTimer from 'react-idle-timer';
import FAQ from './components/FAQ/FAQ';

import {
  Forms,
  Login,
  Contact,
  Account,
  PayBill,
  History,
  Paperless,
  Inquiry,
  EmailConfirmation
} from './components';

import {
  Layout,
  LogoutContainer,
  LogoutHeader,
  LogoutContent,
  LogoutActions
} from './AppStyle';
import { ConfirmPayment } from './components/Account/PayBill';
import AuthService from './lib/AuthService';
import { PrivateRoute } from './components/common';
import { ScrollToTop } from 'components/common/ScrollToTop/ScrollToTop';

function Logout({ cancel }) {
  return (
    <LogoutContainer>
      <LogoutHeader>
        <Label>Logout</Label>
      </LogoutHeader>
      <LogoutContent>
        <Label>Are you sure you want to Logout?</Label>
      </LogoutContent>
      <LogoutActions>
        <Button>Continue</Button>
        <Button onClick={cancel}>Cancel</Button>
      </LogoutActions>
    </LogoutContainer>
  );
}

/*
AppBar will show no matter the route, therefore it is outside the route switch
Side Nav will show no matter the route, therefore it is outside the route switch
*/

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogout: false,
      hasLogoutTimerStart: false,
      logoutOnIdleHappened: false
    };
    this.auth = new AuthService();
  }

  async componentDidMount() {
    console.log(
      `

      ------------------------------------------------------------------------------
      ((((((((((((((((((((((((((((VERSION DATE: 11/18/19))))))))))))))))))))))))))))
      ------------------------------------------------------------------------------

      `
    );
    toastr.options = {
      positionClass: 'toast-bottom-right'
    };
    const token = this.auth.getToken();
    if (token) {
      this.setTimer('hasLogoutTimerStart', true);
      try {
        const { data: dataUser } = await Axios({
          method: 'get',
          url: `${API_BASE}/DoctorBill/RefreshAccount`,
          headers: {
            user_token: token,
            Pragma: 'no-cache'
          }
        });

        this.props.setUser({ ...dataUser });
      } catch (err) {
        console.log(err);
      }

      if (!window.Modernizr.flexbox) {
        const browserSupportMsg = `
            The browser you are currently using is not supported.
            Please download a browser from the list below:
            Chrome (ver 59+)
            Safari (ver 12+)
          `;
        alert(browserSupportMsg);
      }
    }
  } //componentDidMount end
  setTimer = (timerKey, timerValue) => {
    this.setState({ [timerKey]: timerValue });
  };
  cancelLogout = () => this.setState({ showLogout: false });

  logoutOnIdle = () => {
    this.setState(
      {
        hasLogoutTimerStart: false
      },
      () => {
        if (this.auth.getToken()) {
          this.auth.logout();
          this.props.clearData();
          this.props.history.push('/');
          alert(
            'You have been logged out due to inactivity, please log back in to continue.'
          );
        }
      }
    );
  };
  render() {
    const { showLogout, hasLogoutTimerStart } = this.state;
    return (
      <Layout>
        <ScrollToTop />
        {showLogout && <Logout cancel={this.cancelLogout} />}
        {hasLogoutTimerStart && this.auth.getToken() && (
          <IdleTimer
            element={document}
            onIdle={this.logoutOnIdle}
            // onAction={e => {
            //   console.log('user did something', e);
            // }}
            debounce={250}
            //Converting minutes to milliseconds
            timeout={
              this.auth.getProfile().InactivityTimeoutInMinutes
                ? 1000 * 60 * this.auth.getProfile().InactivityTimeoutInMinutes
                : 1000 * 60 * 30
            }
            //6 seconds
            // timeout={6000}
          />
        )}
        <Switch>
          <Route path="/Inquiry/:guid" exact component={Inquiry} />
          <PrivateRoute
            exact
            path="/Account/Pay"
            componentProps={{
              hasLogoutTimerStart
            }}
            component={PayBill}
          />
          <PrivateRoute path="/FAQ" component={FAQ} />
          <PrivateRoute path="/Forms" component={Forms} />
          <PrivateRoute path="/Contact" component={Contact} />
          {this.props.User.systemArea !== 'Centricity' && (
            <PrivateRoute path="/Paperless" component={Paperless} />
          )}
          <PrivateRoute
            path="/Account"
            componentProps={{
              hasLogoutTimerStart
            }}
            exact
            component={Account}
          />
          <PrivateRoute path="/Account/History" component={History} />
          <PrivateRoute
            path="/Account/Pay/Confirmation"
            component={ConfirmPayment}
          />
          <PrivateRoute
            path="/Email/Confirmation"
            component={EmailConfirmation}
          />
          <Route
            path="/:accountNumber?"
            exact
            render={props => <Login {...props} setTimer={this.setTimer} />}
          />
          <Redirect to={'/'} />
        </Switch>
      </Layout>
    );
  }
}

const mapStateTopProps = state => ({
  User: state.User
});
const mapDispatchToProps = dispatch => ({
  clearData: () => dispatch({ type: 'CLEAR_DATA' }),
  setUser: user => dispatch({ type: 'SET_USER', user })
});

const AppWithRouter = withRouter(App);
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(AppWithRouter);
