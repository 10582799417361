import { compose } from 'redux';
import thunk from 'redux-thunk';
import { createStore } from 'redux';
import rootReducer from './reducers';
import { applyMiddleware } from 'redux';

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : noop => noop
  )
);

export default store;
