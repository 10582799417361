import styled from 'styled-components';
const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  fieldset {
    padding: 10px 15px;
    border-radius: 5px;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .MuiButtonBase-root {
    background-color: #abab25;
  }

  button {
    white-space: nowrap;

    &:hover {
      color: #fff;
      a {
        color: #fff;
      }
      background-color: #415463;
    }
  }
`;
const ConfirmationView = styled.div`
  width: 100%;
  height: 100%;
  margin: 24px auto 0 auto;
  @media screen and (min-width: 960px) {
    width: 60%;
  }
`;
const ConfirmationContainer = styled.div`
  position: ${props => (props.showMobileNav === true ? 'absolute' : 'static')};
  height: 100%;
  width: 100%;
  h1 {
    margin: 0 auto;
  }

  hr {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  }

  .action {
    display: block;
    text-align: center;
    margin: 16px 0;

    &:hover {
      cursor: pointer;
    }

    path {
      color: #000;
    }
  }
  @media print and (color) {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
`;
const ConfirmationHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ConfirmationBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DetailsContainer = styled.div`
  max-width: 600px;
  width: 100%;
`;
const Detail = styled.div`
  display: flex;
  align-items: space-between;
  align-content: space-between;
  width: 100%;
  & > * {
    width: 100%;
  }
`;

//#region Pay Bill Content
const PayBillContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @media print {
    position: fixed;
    top: 100px;
    ${ConfirmationContainer} {
      width: 100%;
    }
    .user-information {
      display: none;
    }
    .action {
      display: none;
    }
  }

  @media screen and (min-width: 960px) {
    display: flex;
  }
`;
const PrintContainer = styled.div`
  display: block;
`;
export {
  ContentArea,
  ConfirmationContainer,
  ConfirmationHeader,
  ConfirmationBody,
  DetailsContainer,
  Detail,
  PayBillContent,
  PrintContainer,
  ConfirmationView
};
